const state = {
  recommended_aggregates_to_events: [],
  recommended_aggregates_to_event: {
    names: {},
    descriptions: {},
  },
  recommended_aggregates_to_events_loading: false,
  deleting_recommended_aggregates_to_event_dialog: false,
  creating_recommended_aggregates_to_event_dialog: false,
  editing_recommended_aggregates_to_event_dialog: false,
  recommended_aggregates_to_event_permissions: [],
  recommended_aggregates_to_event_errors: null,
  recommended_aggregates_to_event_error_messages: {},
};
const getters = {
  recommended_aggregates_to_events: (state) =>
    state.recommended_aggregates_to_events,
  recommended_aggregates_to_event: (state) =>
    state.recommended_aggregates_to_event,
  recommended_aggregates_to_events_loading: (state) =>
    state.recommended_aggregates_to_events_loading,
  deleting_recommended_aggregates_to_event_dialog: (state) =>
    state.deleting_recommended_aggregates_to_event_dialog,
  creating_recommended_aggregates_to_event_dialog: (state) =>
    state.creating_recommended_aggregates_to_event_dialog,
  editing_recommended_aggregates_to_event_dialog: (state) =>
    state.editing_recommended_aggregates_to_event_dialog,
  permission_item_recommended_aggregates_to_event_dialog: (state) =>
    state.permission_item_recommended_aggregates_to_event_dialog,
  recommended_aggregates_to_event_errors: (state) =>
    state.recommended_aggregates_to_event_errors,
  recommended_aggregates_to_event_error_messages: (state) =>
    state.recommended_aggregates_to_event_error_messages,
};
const actions = {
  getRecommendedAggregatesToEvents({ commit }, params) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    axios
      .get("/api/admin/recommended_aggregates_to_events", { params })
      .then((response) => {
        commit("setRecommendedAggregatesToEvents", response.data);
        commit("setRecommendedAggregatesToEventsLoading", false);
      });
  },
  getAllRecommendedAggregatesToEvents({ commit }, params) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    axios
      .get("/api/recommended_aggregates_to_events/all", { params })
      .then((response) => {
        commit("setRecommendedAggregatesToEvents", response.data);
        commit("setRecommendedAggregatesToEventsLoading", false);
      });
  },
  setRecommendedAggregatesToEventPermissions({ commit }, params) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    axios
      .post(
        "/api/recommended_aggregates_to_events/" + params.id + "/permissions",
        params
      )
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemRecommendedAggregatesToEventDialog", false);
        } else {
          commit("setRecommendedAggregatesToEventErrors", [
            response.data.message,
          ]);
        }
        commit("setRecommendedAggregatesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedAggregatesToEventsLoading", false);
        commit(
          "setRecommendedAggregatesToEventErrorMessages",
          error.response.data.errors
        );
      });
  },
  getRecommendedAggregatesToEvent({ commit }, id) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    axios
      .get("/api/admin/recommended_aggregates_to_events/" + id)
      .then((response) => {
        commit("setRecommendedAggregatesToEvent", response.data);
        commit("setRecommendedAggregatesToEventsLoading", false);
      });
  },

  storeRecommendedAggregatesToEvent(
    { commit, dispatch },
    recommended_aggregates_to_event
  ) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    commit("setRecommendedAggregatesToEventErrorMessages", {});
    commit("setRecommendedAggregatesToEventErrors", null);

    axios
      .post(
        "/api/admin/recommended_aggregates_to_events",
        recommended_aggregates_to_event
      )
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingRecommendedAggregatesToEventDialog", false);
        // commit('setRecommendedAggregatesToEvent', {})
        dispatch("getRecommendedAggregatesToEvents");

        // } else {
        //     commit('setRecommendedAggregatesToEventErrors', res.data.message)
        // }
        commit("setRecommendedAggregatesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedAggregatesToEventsLoading", false);
        commit(
          "setRecommendedAggregatesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedAggregatesToEventErrors",
          error.response.data.message
        );
      });
  },
  deleteRecommendedAggregatesToEvent({ commit, dispatch }, id) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    commit("setRecommendedAggregatesToEventErrors", null);
    commit("setRecommendedAggregatesToEventErrorMessages", {});

    axios
      .delete("/api/admin/recommended_aggregates_to_events/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingRecommendedAggregatesToEventDialog", false);
        dispatch("getRecommendedAggregatesToEvents");
        // } else {
        // commit('setRecommendedAggregatesToEventErrors', [res.data.message])
        // }
        commit("setRecommendedAggregatesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedAggregatesToEventsLoading", false);
        commit(
          "setRecommendedAggregatesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedAggregatesToEventErrors",
          error.response.data.message
        );
      });
  },
  editRecommendedAggregatesToEvent({ commit, dispatch }, { id, item }) {
    commit("setRecommendedAggregatesToEventsLoading", true);
    commit("setRecommendedAggregatesToEventErrorMessages", {});
    commit("setRecommendedAggregatesToEventErrors", null);
    axios
      .put("/api/admin/recommended_aggregates_to_events/" + id, item)
      .then((res) => {
        // if (res.data.ok) {
        commit("setRecommendedAggregatesToEventsLoading", false);
        dispatch("getRecommendedAggregatesToEvents");

        // } else {
        //     commit('setRecommendedAggregatesToEventErrors', res.data.message)
        // }
        commit("setEditingRecommendedAggregatesToEventDialog", false);
      })
      .catch((error) => {
        commit("setRecommendedAggregatesToEventsLoading", false);
        commit(
          "setRecommendedAggregatesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedAggregatesToEventErrors",
          error.response.data.message
        );
      });
  },
};

const mutations = {
  setRecommendedAggregatesToEvents(state, recommended_aggregates_to_events) {
    state.recommended_aggregates_to_events = recommended_aggregates_to_events;
  },
  setRecommendedAggregatesToEventsLoading(state, loading) {
    state.recommended_aggregates_to_events_loading = loading;
  },
  setDeletingRecommendedAggregatesToEventDialog(
    state,
    deleting_recommended_aggregates_to_event_dialog
  ) {
    state.deleting_recommended_aggregates_to_event_dialog =
      deleting_recommended_aggregates_to_event_dialog;
  },
  setCreatingRecommendedAggregatesToEventDialog(
    state,
    creating_recommended_aggregates_to_event_dialog
  ) {
    state.creating_recommended_aggregates_to_event_dialog =
      creating_recommended_aggregates_to_event_dialog;
  },
  setEditingRecommendedAggregatesToEventDialog(
    state,
    editing_recommended_aggregates_to_event_dialog
  ) {
    state.editing_recommended_aggregates_to_event_dialog =
      editing_recommended_aggregates_to_event_dialog;
  },
  setRecommendedAggregatesToEventErrors(
    state,
    recommended_aggregates_to_event_errors
  ) {
    state.recommended_aggregates_to_event_errors =
      recommended_aggregates_to_event_errors;
  },
  setRecommendedAggregatesToEvent(state, recommended_aggregates_to_event) {
    state.recommended_aggregates_to_event = recommended_aggregates_to_event;
  },
  setRecommendedAggregatesToEventErrorMessages(
    state,
    recommended_aggregates_to_event_error_messages
  ) {
    state.recommended_aggregates_to_event_error_messages =
      recommended_aggregates_to_event_error_messages;
  },
  setPermissionItemRecommendedAggregatesToEventDialog(
    state,
    permission_item_recommended_aggregates_to_event_dialog
  ) {
    state.permission_item_recommended_aggregates_to_event_dialog =
      permission_item_recommended_aggregates_to_event_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
