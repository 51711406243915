<template>
  <v-app-bar
    class="px-15 top-0 z-3 header"
    height="80"
    fixed
    style="top: 0; z-index: 9999"
  >
    <v-app-bar-title class="pa-0">
      <a href="/">
        <img src="/img/logo.svg" class="d-block" alt="Vuetify" height="40"
      /></a>
    </v-app-bar-title>
    <div class="app-bar-links">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-content
            v-on="!selected_contour ? on : ''"
            v-bind="attrs"
          >
            <v-list-item-title
              class="text"
              @click="
                () => {
                  if (selected_contour) {
                    switch_page(6);
                  }
                }
              "
              >Mening ekinlarim
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <div>Kontur tanlang</div>
      </v-tooltip>

      <button @click="switch_page(8)" class="text">Mening texnikalarim</button>
      <div v-if="false">
        <v-menu transition="scroll-y-transition" style="z-index: 1000">
          <template v-slot:activator="{ on, attrs }">
            <a class="ma-2" v-bind="attrs" v-on="on">
              UZ
              <v-icon>mdi-chevron-down</v-icon>
            </a>
          </template>
          <v-list class="pa-2">
            <v-btn text block>O'zbekcha</v-btn>
            <v-btn text block>Русский</v-btn>
          </v-list>
        </v-menu>
      </div>
      <div>
        <v-menu transition="scroll-y-transition" style="z-index: 1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              depressed
              style="border-radius: 8px"
              class="ma-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar size="14" class="mr-2">
                <v-icon>mdi-account</v-icon>
              </v-avatar>
              {{ user_name }} <span class="mx-2">|</span>
              <v-icon size="14">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-2" rounded style="margin-top: 70px">
            <v-btn text block @click="logout">Chiqish</v-btn>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!--        <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>-->

    <!--    <v-btn-->
    <!--        @click="logout"-->
    <!--        color="secondary"-->
    <!--        depressed-->
    <!--        class="ml-auto"-->
    <!--    >-->
    <!--      Chiqish-->
    <!--    </v-btn>-->
    <!--        <v-btn-->
    <!--            @click="onClick"-->
    <!--            fab-->
    <!--            small-->
    <!--            icon-->
    <!--            class="ml-auto"-->
    <!--        >-->
    <!--            <v-icon>{{ theme === 'light' ?  'mdi-weather-sunny' : 'mdi-weather-night'}}</v-icon>-->
    <!--        </v-btn>-->
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBarForUser",
  data() {
    return {};
  },

  methods: {
    switch_tab(tab) {
      this.$store.commit("technique_tab", tab);
    },
    switch_page(page) {
      this.$store.commit("current_page", page);
    },
    onClick() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeDrawer() {
      var val = this.$parent.$parent.$refs.sideBar.$data.temporary;
      this.$parent.$parent.$refs.sideBar.$data.temporary = !val;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "user.login" });
      });
    },
  },
  computed: {
    selected_land() {
      return this.$store.getters.selected_land;
    },
    contours() {
      return this.$store.getters.contours;
    },
    selected_contour() {
      return this.$store.getters.selected_contour;
    },
    role_tab() {
      return this.$store.getters.role_tab;
    },
    current_page() {
      return this.$store.getters.current_page;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    user_name() {
      return this.$store.getters.user_name;
    },
  },
};
</script>

<style scoped>
.v-toolbar {
  background-image: url("@/assets/img/back.png") !important;
  background-size: cover !important;
  background-position: center !important;
}

.app-bar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 32px;
}

.app-bar-links a,
.app-bar-links .text {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
}
</style>

<style lang="scss">
.v-toolbar.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}
</style>
