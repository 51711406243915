export default [
  {
    path: "/admin/soil-table",
    name: "soil_table.index",
    component: () => import("../../pages/admin/SoilTable/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-table/create",
    name: "soil_table.create",
    component: () => import("../../pages/admin/SoilTable/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-table/:id/edit",
    name: "soil_table.edit",
    component: () => import("../../pages/admin/SoilTable/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-table-excel",
    name: "soil_table_excel.index",
    component: () => import("../../pages/admin/SoilTableExcel/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-table-excel/create",
    name: "soil_table_excel.create",
    component: () => import("../../pages/admin/SoilTableExcel/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-table-excel/:id/edit",
    name: "soil_table_excel.edit",
    component: () => import("../../pages/admin/SoilTableExcel/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
