export default [
    {
        path: '/admin/scheduled-event-templates',
        name: 'scheduled_event_templates.index',
        component: () => import('../../pages/admin/ScheduledEventTemplate/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/scheduled-event-templates/create',
        name: 'scheduled_event_templates.create',
        component: () => import('../../pages/admin/ScheduledEventTemplate/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/scheduled-event-templates/:id/edit',
        name: 'scheduled_event_templates.edit',
        component: () => import('../../pages/admin/ScheduledEventTemplate/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
