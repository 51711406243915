const state = {
  soil_zones: [],
  soil_zone_loading: false,
  deleting_soil_zone_dialog: false,
  creating_soil_zone_dialog: false,
  soil_zone_errors: [],
  soil_zone_error_messages: {},
};
const getters = {
  soil_zones: (state) => state.soil_zones,
  soil_zone_loading: (state) => state.soil_zone_loading,
  deleting_soil_zone_dialog: (state) => state.deleting_soil_zone_dialog,
  creating_soil_zone_dialog: (state) => state.creating_soil_zone_dialog,
  soil_zone_errors: (state) => state.soil_zone_errors,
  soil_zone_error_messages: (state) => state.soil_zone_error_messages,
};
const actions = {
  getSoilZone({ commit }) {
    commit("soil_zone_loading", true);
    axios.get("/api/admin/soil_zones").then((response) => {
      commit("soil_zones", response.data);
      commit("soil_zone_loading", false);
    });
  },
  deleteSoilZone({ commit, dispatch }, id) {
    commit("soil_zone_loading", true);
    commit("soil_zone_errors", []);
    commit("soil_zone_error_messages", {});

    axios
      .delete("/api/admin/soil_zones/" + id)
      .then(() => {
        commit("deleting_soil_zone_dialog", false);
        dispatch("getSoilZone");
        commit("soil_zone_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("soil_zone_loading", false);
        commit("soil_zone_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  soil_zones(state, soil_zones) {
    state.soil_zones = soil_zones;
  },
  soil_zone_loading(state, loading) {
    state.soil_zone_loading = loading;
  },
  deleting_soil_zone_dialog(state, deleting_soil_zone_dialog) {
    state.deleting_soil_zone_dialog = deleting_soil_zone_dialog;
  },
  creating_soil_zone_dialog(state, creating_soil_zone_dialog) {
    state.creating_soil_zone_dialog = creating_soil_zone_dialog;
  },
  soil_zone_errors(state, soil_zone_errors) {
    state.soil_zone_errors = soil_zone_errors;
  },
  soil_zone_error_messages(state, soil_zone_error_messages) {
    state.soil_zone_error_messages = soil_zone_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
