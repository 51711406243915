const state = {
    area_range_aggregates: [],
    area_range_aggregate: {
        names: {},
        descriptions: {},
    },
    area_range_aggregates_loading: false,
    deleting_area_range_aggregate_dialog: false,
    creating_area_range_aggregate_dialog: false,
    editing_area_range_aggregate_dialog: false,
    area_range_aggregate_permissions: [],
    area_range_aggregate_errors: [],
    area_range_aggregate_error_messages: {}
}
const getters = {
    area_range_aggregates: state => state.area_range_aggregates,
    area_range_aggregate: state => state.area_range_aggregate,
    area_range_aggregates_loading: state => state.area_range_aggregates_loading,
    deleting_area_range_aggregate_dialog: state => state.deleting_area_range_aggregate_dialog,
    creating_area_range_aggregate_dialog: state => state.creating_area_range_aggregate_dialog,
    editing_area_range_aggregate_dialog: state => state.editing_area_range_aggregate_dialog,
    permission_item_area_range_aggregate_dialog: state => state.permission_item_area_range_aggregate_dialog,
    area_range_aggregate_errors: state => state.area_range_aggregate_errors,
    area_range_aggregate_error_messages: state => state.area_range_aggregate_error_messages,
}
const actions = {

    getAreaRangeAggregates({commit},) {
        commit('setAreaRangeAggregatesLoading', true)
        axios.get('/api/admin/area_range_aggregates', ).then(response => {
            commit('setAreaRangeAggregates', response.data)
            commit('setAreaRangeAggregatesLoading', false)
        })
    },
    getAllAreaRangeAggregates({commit}, params) {
        commit('setAreaRangeAggregatesLoading', true)
        axios.get('/api/area_range_aggregates/all', {params}).then(response => {
            commit('setAreaRangeAggregates', response.data)
            commit('setAreaRangeAggregatesLoading', false)
        })
    },
    setAreaRangeAggregatePermissions({commit}, params) {
        commit('setAreaRangeAggregatesLoading', true)
        axios.post('/api/area_range_aggregates/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemAreaRangeAggregateDialog', false)
            } else {
                commit('setAreaRangeAggregateErrors', [response.data.message])
            }
            commit('setAreaRangeAggregatesLoading', false)
        }).catch(error => {
            commit('setAreaRangeAggregatesLoading', false)
            commit('setAreaRangeAggregateErrorMessages', error.response.data.errors)
        })
    },
    getAreaRangeAggregate({commit}, id) {
        commit('setAreaRangeAggregatesLoading', true)
        axios.get('/api/admin/area_range_aggregates/' + id).then(response => {
            commit('setAreaRangeAggregate', response.data)
            commit('setAreaRangeAggregatesLoading', false)
        })
    },

    storeAreaRangeAggregate({commit, dispatch}, area_range_aggregate) {
        commit('setAreaRangeAggregatesLoading', true)
        commit('setAreaRangeAggregateErrorMessages', {})
        commit('setAreaRangeAggregateErrors', [])

        axios.post('/api/admin/area_range_aggregates', area_range_aggregate).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingAreaRangeAggregateDialog', false)
                commit('setAreaRangeAggregate', {})
                dispatch('getAreaRangeAggregates')

            // } else {
            //     commit('setAreaRangeAggregateErrors', res.data.message)
            // }
            commit('setAreaRangeAggregatesLoading', false)
        }).catch(error => {
            commit('setAreaRangeAggregatesLoading', false)
            commit('setAreaRangeAggregateErrorMessages', error.response.data.errors)
        })
    },
    deleteAreaRangeAggregate({commit, dispatch}, id) {
        commit('setAreaRangeAggregatesLoading', true)
        commit('setAreaRangeAggregateErrors', [])
        commit('setAreaRangeAggregateErrorMessages', {})

        axios.delete('/api/admin/area_range_aggregates/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingAreaRangeAggregateDialog', false)
                dispatch('getAreaRangeAggregates')
            // } else {
                // commit('setAreaRangeAggregateErrors', [res.data.message])
            // }
            commit('setAreaRangeAggregatesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setAreaRangeAggregatesLoading', false)
            commit('setAreaRangeAggregateErrorMessages', error.response.data.errors)
        })
    },
    editAreaRangeAggregate({commit, dispatch}, {id, area_range_aggregate}) {
        console.log(area_range_aggregate);
        commit('setAreaRangeAggregatesLoading', true)
        commit('setAreaRangeAggregateErrorMessages', {})
        commit('setAreaRangeAggregateErrors', [])
        axios.put('/api/admin/area_range_aggregates/' + id, area_range_aggregate).then((res) => {
            // if (res.data.ok) {
                commit('setAreaRangeAggregatesLoading', false)
                dispatch('getAreaRangeAggregates')

            // } else {
            //     commit('setAreaRangeAggregateErrors', res.data.message)
            // }
            commit('setEditingAreaRangeAggregateDialog', false)
        }).catch(error => {
            commit('setAreaRangeAggregatesLoading', false)
            commit('setAreaRangeAggregateErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setAreaRangeAggregates(state, area_range_aggregates) {
        state.area_range_aggregates = area_range_aggregates
    },
    setAreaRangeAggregatesLoading(state, loading) {
        state.area_range_aggregates_loading = loading
    },
    setDeletingAreaRangeAggregateDialog(state, deleting_area_range_aggregate_dialog) {
        state.deleting_area_range_aggregate_dialog = deleting_area_range_aggregate_dialog
    },
    setCreatingAreaRangeAggregateDialog(state, creating_area_range_aggregate_dialog) {
        state.creating_area_range_aggregate_dialog = creating_area_range_aggregate_dialog
    },
    setEditingAreaRangeAggregateDialog(state, editing_area_range_aggregate_dialog) {
        state.editing_area_range_aggregate_dialog = editing_area_range_aggregate_dialog
    },
    setAreaRangeAggregateErrors(state, area_range_aggregate_errors) {
        state.area_range_aggregate_errors = area_range_aggregate_errors
    },
    setAreaRangeAggregate(state, area_range_aggregate) {
        state.area_range_aggregate = area_range_aggregate
    },
    setAreaRangeAggregateErrorMessages(state, area_range_aggregate_error_messages) {
        state.area_range_aggregate_error_messages = area_range_aggregate_error_messages
    },
    setPermissionItemAreaRangeAggregateDialog(state, permission_item_area_range_aggregate_dialog) {
        state.permission_item_area_range_aggregate_dialog = permission_item_area_range_aggregate_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
