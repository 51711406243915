const state = {
    crop_types: [],
    crop_type: {
        names: {},
        descriptions: {},
    },
    crop_types_loading: false,
    deleting_crop_type_dialog: false,
    creating_crop_type_dialog: false,
    editing_crop_type_dialog: false,
    crop_type_permissions: [],
    crop_type_errors: [],
    crop_type_error_messages: {}
}
const getters = {
    crop_types: state => state.crop_types,
    crop_type: state => state.crop_type,
    crop_types_loading: state => state.crop_types_loading,
    deleting_crop_type_dialog: state => state.deleting_crop_type_dialog,
    creating_crop_type_dialog: state => state.creating_crop_type_dialog,
    editing_crop_type_dialog: state => state.editing_crop_type_dialog,
    permission_item_crop_type_dialog: state => state.permission_item_crop_type_dialog,
    crop_type_errors: state => state.crop_type_errors,
    crop_type_error_messages: state => state.crop_type_error_messages,
}
const actions = {

    getCropTypes({commit},) {
        commit('setCropTypesLoading', true)
        axios.get('/api/admin/crop_types', ).then(response => {
            commit('setCropTypes', response.data)
            commit('setCropTypesLoading', false)
        })
    },
    getAllCropTypes({commit}, params) {
        commit('setCropTypesLoading', true)
        axios.get('/api/crop_types/all', {params}).then(response => {
            commit('setCropTypes', response.data)
            commit('setCropTypesLoading', false)
        })
    },
    setCropTypePermissions({commit}, params) {
        commit('setCropTypesLoading', true)
        axios.post('/api/crop_types/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemCropTypeDialog', false)
            } else {
                commit('setCropTypeErrors', [response.data.message])
            }
            commit('setCropTypesLoading', false)
        }).catch(error => {
            commit('setCropTypesLoading', false)
            commit('setCropTypeErrorMessages', error.response.data.errors)
        })
    },
    getCropType({commit}, id) {
        commit('setCropTypesLoading', true)
        axios.get('/api/admin/crop_types/' + id).then(response => {
            commit('setCropType', response.data)
            commit('setCropTypesLoading', false)
        })
    },

    storeCropType({commit, dispatch}, crop_type) {
        commit('setCropTypesLoading', true)
        commit('setCropTypeErrorMessages', {})
        commit('setCropTypeErrors', [])

        axios.post('/api/admin/crop_types', crop_type).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingCropTypeDialog', false)
                commit('setCropType', {})
                dispatch('getCropTypes')

            // } else {
            //     commit('setCropTypeErrors', res.data.message)
            // }
            commit('setCropTypesLoading', false)
        }).catch(error => {
            commit('setCropTypesLoading', false)
            commit('setCropTypeErrorMessages', error.response.data.errors)
        })
    },
    deleteCropType({commit, dispatch}, id) {
        commit('setCropTypesLoading', true)
        commit('setCropTypeErrors', [])
        commit('setCropTypeErrorMessages', {})

        axios.delete('/api/admin/crop_types/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingCropTypeDialog', false)
                dispatch('getCropTypes')
            // } else {
                // commit('setCropTypeErrors', [res.data.message])
            // }
            commit('setCropTypesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setCropTypesLoading', false)
            commit('setCropTypeErrorMessages', error.response.data.errors)
        })
    },
    editCropType({commit, dispatch}, {id, crop_type}) {
        commit('setCropTypesLoading', true)
        commit('setCropTypeErrorMessages', {})
        commit('setCropTypeErrors', [])
        axios.put('/api/admin/crop_types/' + id, crop_type).then((res) => {
            // if (res.data.ok) {
                commit('setCropTypesLoading', false)
                dispatch('getCropTypes')

            // } else {
            //     commit('setCropTypeErrors', res.data.message)
            // }
            commit('setEditingCropTypeDialog', false)
        }).catch(error => {
            commit('setCropTypesLoading', false)
            commit('setCropTypeErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setCropTypes(state, crop_types) {
        state.crop_types = crop_types
    },
    setCropTypesLoading(state, loading) {
        state.crop_types_loading = loading
    },
    setDeletingCropTypeDialog(state, deleting_crop_type_dialog) {
        state.deleting_crop_type_dialog = deleting_crop_type_dialog
    },
    setCreatingCropTypeDialog(state, creating_crop_type_dialog) {
        state.creating_crop_type_dialog = creating_crop_type_dialog
    },
    setEditingCropTypeDialog(state, editing_crop_type_dialog) {
        state.editing_crop_type_dialog = editing_crop_type_dialog
    },
    setCropTypeErrors(state, crop_type_errors) {
        state.crop_type_errors = crop_type_errors
    },
    setCropType(state, crop_type) {
        state.crop_type = crop_type
    },
    setCropTypeErrorMessages(state, crop_type_error_messages) {
        state.crop_type_error_messages = crop_type_error_messages
    },
    setPermissionItemCropTypeDialog(state, permission_item_crop_type_dialog) {
        state.permission_item_crop_type_dialog = permission_item_crop_type_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
