export default [
    {
        path: '/admin/zones',
        name: 'zones.index',
        component: () => import('../../pages/admin/Zone/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/zones/create',
        name: 'zones.create',
        component: () => import('../../pages/admin/Zone/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/zones/:id/edit',
        name: 'zones.edit',
        component: () => import('../../pages/admin/Zone/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
