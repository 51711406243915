export default [
    {
        path: '/admin/area-range-aggregates',
        name: 'area_range_aggregates.index',
        component: () => import('../../pages/admin/AreaRangeAggregate/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/area-range-aggregates/create',
        name: 'area_range_aggregates.create',
        component: () => import('../../pages/admin/AreaRangeAggregate/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/area-range-aggregates/:id/edit',
        name: 'area_range_aggregates.edit',
        component: () => import('../../pages/admin/AreaRangeAggregate/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
