export default [
    {
        path: '/admin/clusters',
        name: 'clusters.index',
        component: () => import('../../pages/admin/Cluster/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/clusters/create',
        name: 'clusters.create',
        component: () => import('../../pages/admin/Cluster/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/clusters/:id/edit',
        name: 'clusters.edit',
        component: () => import('../../pages/admin/Cluster/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
