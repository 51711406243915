const state = {
  aggregates: [],
  aggregate: {
    names: {},
    descriptions: {},
  },
  aggregates_loading: false,
  deleting_aggregate_dialog: false,
  creating_aggregate_dialog: false,
  editing_aggregate_dialog: false,
  aggregate_permissions: [],
  aggregate_errors: [],
  aggregate_error_messages: {},
};
const getters = {
  aggregates: (state) => state.aggregates,
  aggregate: (state) => state.aggregate,
  aggregates_loading: (state) => state.aggregates_loading,
  deleting_aggregate_dialog: (state) => state.deleting_aggregate_dialog,
  creating_aggregate_dialog: (state) => state.creating_aggregate_dialog,
  editing_aggregate_dialog: (state) => state.editing_aggregate_dialog,
  permission_item_aggregate_dialog: (state) =>
    state.permission_item_aggregate_dialog,
  aggregate_errors: (state) => state.aggregate_errors,
  aggregate_error_messages: (state) => state.aggregate_error_messages,
};
const actions = {
  getAggregates({ commit }) {
    commit("setAggregatesLoading", true);
    axios.get("/api/admin/aggregates").then((response) => {
      commit("setAggregates", response.data);
      commit("setAggregatesLoading", false);
    });
  },
  getAllAggregates({ commit }, params) {
    commit("setAggregatesLoading", true);
    axios.get("/api/aggregates/all", { params }).then((response) => {
      commit("setAggregates", response.data);
      commit("setAggregatesLoading", false);
    });
  },
  setAggregatePermissions({ commit }, params) {
    commit("setAggregatesLoading", true);
    axios
      .post("/api/aggregates/" + params.id + "/permissions", params)
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemAggregateDialog", false);
        } else {
          commit("setAggregateErrors", [response.data.message]);
        }
        commit("setAggregatesLoading", false);
      })
      .catch((error) => {
        commit("setAggregatesLoading", false);
        commit("setAggregateErrorMessages", error.response.data.errors);
      });
  },
  getAggregate({ commit }, id) {
    commit("setAggregatesLoading", true);
    axios.get("/api/admin/aggregates/one/" + id).then((response) => {
      commit("setAggregate", response.data);
      commit("setAggregatesLoading", false);
    });
  },

  storeAggregate({ commit, dispatch }, aggregate) {
    commit("setAggregatesLoading", true);
    commit("setAggregateErrorMessages", {});
    commit("setAggregateErrors", []);

    axios
      .post("/api/admin/aggregates", aggregate)
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingAggregateDialog", false);
        commit("setAggregate", {});
        dispatch("getAggregates");

        // } else {
        //     commit('setAggregateErrors', res.data.message)
        // }
        commit("setAggregatesLoading", false);
      })
      .catch((error) => {
        commit("setAggregatesLoading", false);
        commit("setAggregateErrorMessages", error.response.data.errors);
      });
  },
  deleteAggregate({ commit, dispatch }, id) {
    commit("setAggregatesLoading", true);
    commit("setAggregateErrors", []);
    commit("setAggregateErrorMessages", {});

    axios
      .delete("/api/admin/aggregates/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingAggregateDialog", false);
        dispatch("getAggregates");
        // } else {
        // commit('setAggregateErrors', [res.data.message])
        // }
        commit("setAggregatesLoading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("setAggregatesLoading", false);
        commit("setAggregateErrorMessages", error.response.data.errors);
      });
  },
  editAggregate({ commit, dispatch }, { id, aggregate }) {
    commit("setAggregatesLoading", true);
    commit("setAggregateErrorMessages", {});
    commit("setAggregateErrors", []);
    axios
      .put("/api/admin/aggregates/" + id, aggregate)
      .then((res) => {
        // if (res.data.ok) {
        commit("setAggregatesLoading", false);
        dispatch("getAggregates");

        // } else {
        //     commit('setAggregateErrors', res.data.message)
        // }
        commit("setEditingAggregateDialog", false);
      })
      .catch((error) => {
        commit("setAggregatesLoading", false);
        commit("setAggregateErrorMessages", error.response.data.errors);
      });
  },
};

const mutations = {
  setAggregates(state, aggregates) {
    state.aggregates = aggregates;
  },
  setAggregatesLoading(state, loading) {
    state.aggregates_loading = loading;
  },
  setDeletingAggregateDialog(state, deleting_aggregate_dialog) {
    state.deleting_aggregate_dialog = deleting_aggregate_dialog;
  },
  setCreatingAggregateDialog(state, creating_aggregate_dialog) {
    state.creating_aggregate_dialog = creating_aggregate_dialog;
  },
  setEditingAggregateDialog(state, editing_aggregate_dialog) {
    state.editing_aggregate_dialog = editing_aggregate_dialog;
  },
  setAggregateErrors(state, aggregate_errors) {
    state.aggregate_errors = aggregate_errors;
  },
  setAggregate(state, aggregate) {
    state.aggregate = aggregate;
  },
  setAggregateErrorMessages(state, aggregate_error_messages) {
    state.aggregate_error_messages = aggregate_error_messages;
  },
  setPermissionItemAggregateDialog(state, permission_item_aggregate_dialog) {
    state.permission_item_aggregate_dialog = permission_item_aggregate_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
