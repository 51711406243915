export default [
  {
    path: "/admin/technique-brands",
    name: "technique_brands.index",
    component: () => import("../../pages/admin/TechniqueBrands/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/technique-brands/create",
    name: "technique_brands.create",
    component: () => import("../../pages/admin/TechniqueBrands/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/technique-brands/:id/edit",
    name: "technique_brands.edit",
    component: () => import("../../pages/admin/TechniqueBrands/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
