const state = {
    aggregate_aggregate_parameters: [],
    aggregate_aggregate_parameter: {
        names: {},
        descriptions: {},
    },
    aggregate_aggregate_parameters_loading: false,
    deleting_aggregate_aggregate_parameter_dialog: false,
    creating_aggregate_aggregate_parameter_dialog: false,
    editing_aggregate_aggregate_parameter_dialog: false,
    aggregate_aggregate_parameter_permissions: [],
    aggregate_aggregate_parameter_errors: [],
    aggregate_aggregate_parameter_error_messages: {}
}
const getters = {
    aggregate_aggregate_parameters: state => state.aggregate_aggregate_parameters,
    aggregate_aggregate_parameter: state => state.aggregate_aggregate_parameter,
    aggregate_aggregate_parameters_loading: state => state.aggregate_aggregate_parameters_loading,
    deleting_aggregate_aggregate_parameter_dialog: state => state.deleting_aggregate_aggregate_parameter_dialog,
    creating_aggregate_aggregate_parameter_dialog: state => state.creating_aggregate_aggregate_parameter_dialog,
    editing_aggregate_aggregate_parameter_dialog: state => state.editing_aggregate_aggregate_parameter_dialog,
    permission_item_aggregate_aggregate_parameter_dialog: state => state.permission_item_aggregate_aggregate_parameter_dialog,
    aggregate_aggregate_parameter_errors: state => state.aggregate_aggregate_parameter_errors,
    aggregate_aggregate_parameter_error_messages: state => state.aggregate_aggregate_parameter_error_messages,
}
const actions = {

    getAggregateAggregateParameters({commit},) {
        commit('setAggregateAggregateParametersLoading', true)
        axios.get('/api/admin/aggregate_aggregate_parameters', ).then(response => {
            commit('setAggregateAggregateParameters', response.data)
            commit('setAggregateAggregateParametersLoading', false)
        })
    },
    getAllAggregateAggregateParameters({commit}, params) {
        commit('setAggregateAggregateParametersLoading', true)
        axios.get('/api/aggregate_aggregate_parameters/all', {params}).then(response => {
            commit('setAggregateAggregateParameters', response.data)
            commit('setAggregateAggregateParametersLoading', false)
        })
    },
    setAggregateAggregateParameterPermissions({commit}, params) {
        commit('setAggregateAggregateParametersLoading', true)
        axios.post('/api/aggregate_aggregate_parameters/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemAggregateAggregateParameterDialog', false)
            } else {
                commit('setAggregateAggregateParameterErrors', [response.data.message])
            }
            commit('setAggregateAggregateParametersLoading', false)
        }).catch(error => {
            commit('setAggregateAggregateParametersLoading', false)
            commit('setAggregateAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    getAggregateAggregateParameter({commit}, id) {
        commit('setAggregateAggregateParametersLoading', true)
        axios.get('/api/admin/aggregate_aggregate_parameters/' + id).then(response => {
            commit('setAggregateAggregateParameter', response.data)
            commit('setAggregateAggregateParametersLoading', false)
        })
    },

    storeAggregateAggregateParameter({commit, dispatch}, aggregate_aggregate_parameter) {
        commit('setAggregateAggregateParametersLoading', true)
        commit('setAggregateAggregateParameterErrorMessages', {})
        commit('setAggregateAggregateParameterErrors', [])

        axios.post('/api/admin/aggregate_aggregate_parameters', aggregate_aggregate_parameter).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingAggregateAggregateParameterDialog', false)
                commit('setAggregateAggregateParameter', {})
                dispatch('getAggregateAggregateParameters')

            // } else {
            //     commit('setAggregateAggregateParameterErrors', res.data.message)
            // }
            commit('setAggregateAggregateParametersLoading', false)
        }).catch(error => {
            commit('setAggregateAggregateParametersLoading', false)
            commit('setAggregateAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    deleteAggregateAggregateParameter({commit, dispatch}, id) {
        commit('setAggregateAggregateParametersLoading', true)
        commit('setAggregateAggregateParameterErrors', [])
        commit('setAggregateAggregateParameterErrorMessages', {})

        axios.delete('/api/admin/aggregate_aggregate_parameters/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingAggregateAggregateParameterDialog', false)
                dispatch('getAggregateAggregateParameters')
            // } else {
                // commit('setAggregateAggregateParameterErrors', [res.data.message])
            // }
            commit('setAggregateAggregateParametersLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setAggregateAggregateParametersLoading', false)
            commit('setAggregateAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    editAggregateAggregateParameter({commit, dispatch}, {id, aggregate_aggregate_parameter}) {
        console.log(aggregate_aggregate_parameter);
        commit('setAggregateAggregateParametersLoading', true)
        commit('setAggregateAggregateParameterErrorMessages', {})
        commit('setAggregateAggregateParameterErrors', [])
        axios.put('/api/admin/aggregate_aggregate_parameters/' + id, aggregate_aggregate_parameter).then((res) => {
            // if (res.data.ok) {
                commit('setAggregateAggregateParametersLoading', false)
                dispatch('getAggregateAggregateParameters')

            // } else {
            //     commit('setAggregateAggregateParameterErrors', res.data.message)
            // }
            commit('setEditingAggregateAggregateParameterDialog', false)
        }).catch(error => {
            commit('setAggregateAggregateParametersLoading', false)
            commit('setAggregateAggregateParameterErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setAggregateAggregateParameters(state, aggregate_aggregate_parameters) {
        state.aggregate_aggregate_parameters = aggregate_aggregate_parameters
    },
    setAggregateAggregateParametersLoading(state, loading) {
        state.aggregate_aggregate_parameters_loading = loading
    },
    setDeletingAggregateAggregateParameterDialog(state, deleting_aggregate_aggregate_parameter_dialog) {
        state.deleting_aggregate_aggregate_parameter_dialog = deleting_aggregate_aggregate_parameter_dialog
    },
    setCreatingAggregateAggregateParameterDialog(state, creating_aggregate_aggregate_parameter_dialog) {
        state.creating_aggregate_aggregate_parameter_dialog = creating_aggregate_aggregate_parameter_dialog
    },
    setEditingAggregateAggregateParameterDialog(state, editing_aggregate_aggregate_parameter_dialog) {
        state.editing_aggregate_aggregate_parameter_dialog = editing_aggregate_aggregate_parameter_dialog
    },
    setAggregateAggregateParameterErrors(state, aggregate_aggregate_parameter_errors) {
        state.aggregate_aggregate_parameter_errors = aggregate_aggregate_parameter_errors
    },
    setAggregateAggregateParameter(state, aggregate_aggregate_parameter) {
        state.aggregate_aggregate_parameter = aggregate_aggregate_parameter
    },
    setAggregateAggregateParameterErrorMessages(state, aggregate_aggregate_parameter_error_messages) {
        state.aggregate_aggregate_parameter_error_messages = aggregate_aggregate_parameter_error_messages
    },
    setPermissionItemAggregateAggregateParameterDialog(state, permission_item_aggregate_aggregate_parameter_dialog) {
        state.permission_item_aggregate_aggregate_parameter_dialog = permission_item_aggregate_aggregate_parameter_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
