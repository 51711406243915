export default [
    {
        path: '/admin/secondary-periods',
        name: 'secondary_periods.index',
        component: () => import('../../pages/admin/SecondaryPeriod/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/secondary-periods/create',
        name: 'secondary_periods.create',
        component: () => import('../../pages/admin/SecondaryPeriod/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/secondary-periods/:id/edit',
        name: 'secondary_periods.edit',
        component: () => import('../../pages/admin/SecondaryPeriod/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
