<template>
    <v-navigation-drawer app :value="temporary">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    {{ title }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
            dense
            nav
        >
            <v-list-item-group
                color="primary"
            >
                <template v-for="item in items">

                    <v-list-item
                        :key="item.route"
                        link
                        :to="item.route"
                        :item="item"
                        v-if="!item.children"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                        v-if="item.children"
                        :value="item.children.some(child => child.route === $route.path)"
                        :prepend-icon="item.icon"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-for="(child, i) in item.children">
                            <v-list-item
                                :key="i"
                                link
                                :to="child.route "
                                :item="child">
                                <v-list-item-icon>
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list-group>
                </template>

            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import navigators_for_user from "../../router/navigators_for_user";

export default {
    name: 'SideBarForUser',

    data() {
        return {
            items: [],
            right: null,
            temporary: true,
        }
    },
    props: {},
    computed: {
        title() {
            return this.$store.getters.title
        },
    }
    ,
    mounted() {
        this.items = navigators_for_user
    }
}
</script>
