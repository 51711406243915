import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

Vue.use(VueRouter)

const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',

    scrollBehavior() {
        return {x: 0, y: 0}
    },
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active" // active class for *exact* links.
})

router.beforeEach((to, _, next) => {

  const isLoggedIn = localStorage.getItem('accessToken') || localStorage.getItem('token')
  //   console.log(canNavigate(to))
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
  //
  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  //
  //   // return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.requiresAuth && !isLoggedIn) {
    next('/login')
  }

  return next()
})

export default router
