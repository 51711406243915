export default [
    {
        path: '/admin/event-templates',
        name: 'event_templates.index',
        component: () => import('../../pages/admin/EventTemplate/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/event-templates/create',
        name: 'event_templates.create',
        component: () => import('../../pages/admin/EventTemplate/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/event-templates/:id/edit',
        name: 'event_templates.edit',
        component: () => import('../../pages/admin/EventTemplate/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
