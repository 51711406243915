export default [
  {
    path: "/admin/district",
    name: "district.index",
    component: () => import("../../pages/admin/District/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/district/create",
    name: "district.create",
    component: () => import("../../pages/admin/District/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/district/:id/edit",
    name: "district.edit",
    component: () => import("../../pages/admin/District/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
