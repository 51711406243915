const state = {
  soil_tables: [],
  soil_table_loading: false,
  deleting_soil_table_dialog: false,
  creating_soil_table_dialog: false,
  soil_table_errors: [],
  soil_table_error_messages: {},
};
const getters = {
  soil_tables: (state) => state.soil_tables,
  soil_table_loading: (state) => state.soil_table_loading,
  deleting_soil_table_dialog: (state) => state.deleting_soil_table_dialog,
  creating_soil_table_dialog: (state) => state.creating_soil_table_dialog,
  soil_table_errors: (state) => state.soil_table_errors,
  soil_table_error_messages: (state) => state.soil_table_error_messages,
};
const actions = {
  getSoilTable({ commit }) {
    commit("soil_table_loading", true);
    axios.get("/api/admin/soil_productivity_range_norms").then((response) => {
      commit("soil_tables", response.data);
      commit("soil_table_loading", false);
    });
  },

  deleteSoilTable({ commit, dispatch }, id) {
    commit("soil_table_loading", true);
    commit("soil_table_errors", []);
    commit("soil_table_error_messages", {});

    axios
      .delete("/api/admin/fertilizers_tables/" + id)
      .then(() => {
        commit("deleting_soil_table_dialog", false);
        dispatch("getSoilTable");
        commit("soil_table_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("soil_table_loading", false);
        commit("soil_table_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  soil_tables(state, soil_tables) {
    state.soil_tables = soil_tables;
  },
  soil_table_loading(state, loading) {
    state.soil_table_loading = loading;
  },
  deleting_soil_table_dialog(state, deleting_soil_table_dialog) {
    state.deleting_soil_table_dialog = deleting_soil_table_dialog;
  },
  creating_soil_table_dialog(state, creating_soil_table_dialog) {
    state.creating_soil_table_dialog = creating_soil_table_dialog;
  },
  soil_table_errors(state, soil_table_errors) {
    state.soil_table_errors = soil_table_errors;
  },
  soil_table_error_messages(state, soil_table_error_messages) {
    state.soil_table_error_messages = soil_table_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
