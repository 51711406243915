import axios from "axios";
import { useRouter } from "vue-router/composables";

const state = {
  user_name: "",
  gis_bridge_lands: [],
  cluster_gis_bridge_lands: [],
  gis_bridge_lands_loading: false,
  stirs: [],
  tech_card: {
    parameters: [],
    phases: [],
  },
  tech_card_shablon: {
    parameters: [],
    phases: [],
  },
  tech_card_list: [],
  timeline: {},
  tech_card_loading: false,
  land: 0,
  plant_type: {},
  is_changed_map: false,
  delete_tech_card_dialog: false,
  add_tech_card_dialog: false,
  edit_tech_card_dialog: false,
  delete_group_dialog: false,
  tech_card_arrangements: [],
  phase: 0,
  index: 0,
  edit_item: {},
  row_space: 0,
  group_index: 0,
  tech_card_exists: false,
  land_dialog: false,
  passport_values: {},
  tech_card_errors: "",
  cad: "",
  selected_land: "",
  selected_contour: null,
  cluster_selected_contour: null,
  selected_merged_contour: null,
  contours: [],
  cluster_contours: [],
  contour_names: {},
  land_passport_data: {},
  selected_events: [],
  removed_events: {},
  technique_tab: "settings",
  user_locations: [],
  edit_geometry: false,
  edited_geometry: {},
  enable_to_save: true,
  snackbar_self_intersect: false,
  selected_cluster_farmer: {},
  merge_mode: false,
  merged_lands: [],
  stir: null,
  selected_lands: [],
  cluster_selected_lands: [],
  contour_groups: [],
  undo: false,
  redo: false,
  current_page: 1,
  role_tab: "farmer",
  ndbi_loading: true,
  plant: null,
  oferta_read: false,
  oferta: false,
};

const getters = {
  user_name: (state) => state.user_name,
  gis_bridge_lands: (state) => state.gis_bridge_lands,
  cluster_gis_bridge_lands: (state) => state.cluster_gis_bridge_lands,
  stirs: (state) => state.stirs,
  tech_card: (state) => state.tech_card,
  land: (state) => state.land,
  index: (state) => state.index,
  passport_values: (state) => state.passport_values,
  selected_land: (state) => state.selected_land,
  selected_contour: (state) => state.selected_contour,
  cluster_selected_contour: (state) => state.cluster_selected_contour,
  contours: (state) => state.contours,
  cluster_contours: (state) => state.cluster_contours,
  contour_names: (state) => state.contour_names,
  land_passport_data: (state) => state.land_passport_data,
  selected_events: (state) => state.selected_events,
  removed_events: (state) => state.removed_events,
  technique_tab: (state) => state.technique_tab,
  user_locations: (state) => state.user_locations,
  edit_geometry: (state) => state.edit_geometry,
  edited_geometry: (state) => state.edited_geometry,
  enable_to_save: (state) => state.enable_to_save,
  snackbar_self_intersect: (state) => state.snackbar_self_intersect,
  selected_cluster_farmer: (state) => state.selected_cluster_farmer,
  merge_mode: (state) => state.merge_mode,
  merged_lands: (state) => state.merged_lands,
  stir: (state) => state.stir,
  selected_lands: (state) => state.selected_lands,
  cluster_selected_lands: (state) => state.cluster_selected_lands,
  selected_merged_contour: (state) => state.selected_merged_contour,
  contour_groups: (state) => state.contour_groups,
  undo: (state) => state.undo,
  redo: (state) => state.redo,
  current_page: (state) => state.current_page,
  plant: (state) => state.plant,
  role_tab: (state) => state.role_tab,
  ndbi_loading: (state) => state.ndbi_loading,
  oferta_read: (state) => state.oferta_read,
  oferta: (state) => state.oferta,
};

const actions = {
  async getStirs({ commit, getters }) {
    const router = useRouter();
    commit("gis_bridge_lands_loading", true);
    axios
      .get("/api/user_api/data", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("stirs", res.data ?? "");
        if (getters.stirs?.at(1)) {
          commit("stir", res.data?.at(1)?.pin);
        } else {
          commit("stir", res.data?.at(0)?.pin);
        }

        let text = res.data?.at(0)?.full_name;
        text = text?.split(" ");
        commit("user_name", `${text[0]} ${text[1][0]}`);

        commit("gis_bridge_lands_loading", false);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          router.push("/login");
        }
      });
  },
  getUserLocations({ commit }) {
    commit("gis_bridge_lands_loading", true);
    axios
      .get("/api/user_locations", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        commit("user_locations", response.data);
        commit("gis_bridge_lands_loading", false);
      });
  },
  getClusterLocations({ commit }, id) {
    commit("gis_bridge_lands_loading", true);
    axios
      .get(`/api/cluster_api/user_locations?user_land_id=${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        commit("user_locations", response.data);
        commit("gis_bridge_lands_loading", false);
      });
  },
  async getLands({ commit }, id) {
    commit("gis_bridge_lands_loading", true);
    let response = await axios.get("/api/user_api/lands/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    commit("gis_bridge_lands", response.data);
  },
  async getClusterLands({ commit }, id) {
    commit("gis_bridge_lands_loading", true);
    let response = await axios.get("/api/cluster_api/lands/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    commit("cluster_gis_bridge_lands", response.data);
  },
  async getContours({ commit }, land_cadastral_number) {
    commit("gis_bridge_lands_loading", true);
    try {
      const response = await axios.get("/api/user_api/user_land_contours/", {
        params: { land_cadastral_number },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async getContour({ commit }, id) {
    commit("gis_bridge_lands_loading", true);
    try {
      const response = await axios.get("/api/user_land_contours/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  get_passport_values({ commit }) {
    commit("gis_bridge_lands_loading", true);
    var params = {
      gis_area: state.land.properties.crop_area,
      cadastor: state.land.properties.cadastral_number,
      contour_number: state.land.properties.kontur_raqami + "/" + state.land.id,
    };
    axios
      .get(`/passport_values`, { params })
      .then((response) => {
        commit("passport_values", response.data);
        commit("gis_bridge_lands_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("gis_bridge_lands_loading", false);
      });
  },
  save_land({ commit }, params) {
    commit("gis_bridge_lands_loading", true);
    var data = new FormData();
    data.append("bir", params.bir);
    data.append("ayrisimon", params.ayrisimon);
    data.append("turt", params.turt);
    data.append("asosi", params.asosi);
    data.append("suv_taminoti", params.suv_taminoti);
    data.append("shorlanganligi", params.shorlanganligi);
    data.append("ekish_sharoiti", params.ekish_sharoiti);
    data.append("tuprokning_name", params.tuprokning_name);
    data.append("yosh_yoki_kichik", params.yosh_yoki_kichik);
    data.append("yirik_daraht", params.yirik_daraht);
    data.append("mintaqa", params.mintaqa);
    data.append("gis_area", state.land.properties.crop_area);
    data.append("kiyaligi", params.kiyaligi);
    data.append("toshlok_daraja", params.toshlok_daraja);
    data.append("guruh", params.guruh);
    data.append("cadastor_num", state.land.properties.cadastral_number);
    data.append("length", state.land.properties.shape_length);
    data.append("district_code", state.land.properties.district_code);
    data.append(
      "contour_number",
      state.land.properties.kontur_raqami + "/" + state.land.id
    );

    axios
      .post(`/create_land_passport`, data)
      .then(() => {
        commit("gis_bridge_lands_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("gis_bridge_lands_loading", false);
      });
  },
};

const mutations = {
  user_name: (state, user_name) => (state.user_name = user_name),
  clearLands: (state) => (state.gis_bridge_lands = []),
  clearCounter: (state) => (state.contours = []),
  setSelectedLand: (state, selected_land) =>
    (state.selected_land = selected_land),
  setSelectedContour: (state, selected_contour) =>
    (state.selected_contour = selected_contour),
  cluster_selected_contour: (state, cluster_selected_contour) =>
    (state.cluster_selected_contour = cluster_selected_contour),
  setContours: (state, contours) => (state.contours = contours),
  cluster_contours: (state, cluster_contours) =>
    (state.cluster_contours = cluster_contours),
  gis_bridge_lands: (state, gis_bridge_lands) =>
    (state.gis_bridge_lands = gis_bridge_lands),
  cluster_gis_bridge_lands: (state, cluster_gis_bridge_lands) =>
    (state.cluster_gis_bridge_lands = cluster_gis_bridge_lands),
  stirs: (state, stirs) => (state.stirs = stirs),
  gis_bridge_lands_loading: (state, loading) =>
    (state.gis_bridge_lands_loading = loading),
  tech_card: (state, tech_card) => (state.tech_card = tech_card),
  land: (state, land) => (state.land = land),
  plant_type: (state, plant_type) => (state.plant_type = plant_type),
  index: (state, index) => (state.index = index),
  tech_card_shablon: (state, tech_card_shablon) =>
    (state.tech_card_shablon = tech_card_shablon),
  passport_values: (state, passport_values) =>
    (state.passport_values = passport_values),
  contour_names: (state, contour_names) =>
    (state.contour_names = contour_names),
  land_passport_data: (state, land_passport_data) =>
    (state.land_passport_data = land_passport_data),
  selected_events: (state, selected_events) =>
    (state.selected_events = selected_events),
  removed_events: (state, removed_events) =>
    (state.removed_events = removed_events),
  technique_tab: (state, technique_tab) =>
    (state.technique_tab = technique_tab),
  user_locations: (state, user_locations) =>
    (state.user_locations = user_locations),
  current_page: (state, current_page) => (state.current_page = current_page),
  undo: (state, undo) => (state.undo = undo),
  redo: (state, redo) => (state.redo = redo),
  edit_geometry: (state, edit_geometry) =>
    (state.edit_geometry = edit_geometry),
  edited_geometry: (state, edited_geometry) =>
    (state.edited_geometry = edited_geometry),
  enable_to_save: (state, enable_to_save) =>
    (state.enable_to_save = enable_to_save),
  snackbar_self_intersect: (state, snackbar_self_intersect) =>
    (state.snackbar_self_intersect = snackbar_self_intersect),
  selected_cluster_farmer: (state, selected_cluster_farmer) =>
    (state.selected_cluster_farmer = selected_cluster_farmer),
  merge_mode: (state, merge_mode) => (state.merge_mode = merge_mode),
  merged_lands: (state, merged_lands) => (state.merged_lands = merged_lands),
  stir: (state, stir) => (state.stir = stir),
  selected_lands: (state, selected_lands) =>
    (state.selected_lands = selected_lands),
  cluster_selected_lands: (state, cluster_selected_lands) =>
    (state.cluster_selected_lands = cluster_selected_lands),
  selected_merged_contour: (state, selected_merged_contour) =>
    (state.selected_merged_contour = selected_merged_contour),
  contour_groups: (state, contour_groups) =>
    (state.contour_groups = contour_groups),
  role_tab: (state, role_tab) => (state.role_tab = role_tab),
  ndbi_loading: (state, ndbi_loading) => (state.ndbi_loading = ndbi_loading),
  plant: (state, plant) => (state.plant = plant),
  oferta: (state, oferta) => (state.oferta = oferta),
  oferta_read: (state, oferta_read) => (state.oferta_read = oferta_read),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
