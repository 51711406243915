export default [
    {
        path: '/admin/actions',
        name: 'actions.index',
        component: () => import('../../pages/admin/Action/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/actions/create',
        name: 'actions.create',
        component: () => import('../../pages/admin/Action/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/actions/:id/edit',
        name: 'actions.edit',
        component: () => import('../../pages/admin/Action/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
