const state = {
    aggregate_parameters: [],
    aggregate_parameter: {
        names: {},
        descriptions: {},
    },
    aggregate_parameters_loading: false,
    deleting_aggregate_parameter_dialog: false,
    creating_aggregate_parameter_dialog: false,
    editing_aggregate_parameter_dialog: false,
    aggregate_parameter_permissions: [],
    aggregate_parameter_errors: [],
    aggregate_parameter_error_messages: {}
}
const getters = {
    aggregate_parameters: state => state.aggregate_parameters,
    aggregate_parameter: state => state.aggregate_parameter,
    aggregate_parameters_loading: state => state.aggregate_parameters_loading,
    deleting_aggregate_parameter_dialog: state => state.deleting_aggregate_parameter_dialog,
    creating_aggregate_parameter_dialog: state => state.creating_aggregate_parameter_dialog,
    editing_aggregate_parameter_dialog: state => state.editing_aggregate_parameter_dialog,
    permission_item_aggregate_parameter_dialog: state => state.permission_item_aggregate_parameter_dialog,
    aggregate_parameter_errors: state => state.aggregate_parameter_errors,
    aggregate_parameter_error_messages: state => state.aggregate_parameter_error_messages,
}
const actions = {

    getAggregateParameters({commit},) {
        commit('setAggregateParametersLoading', true)
        axios.get('/api/admin/aggregate_parameters', ).then(response => {
            commit('setAggregateParameters', response.data)
            commit('setAggregateParametersLoading', false)
        })
    },
    getAllAggregateParameters({commit}, params) {
        commit('setAggregateParametersLoading', true)
        axios.get('/api/aggregate_parameters/all', {params}).then(response => {
            commit('setAggregateParameters', response.data)
            commit('setAggregateParametersLoading', false)
        })
    },
    setAggregateParameterPermissions({commit}, params) {
        commit('setAggregateParametersLoading', true)
        axios.post('/api/aggregate_parameters/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemAggregateParameterDialog', false)
            } else {
                commit('setAggregateParameterErrors', [response.data.message])
            }
            commit('setAggregateParametersLoading', false)
        }).catch(error => {
            commit('setAggregateParametersLoading', false)
            commit('setAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    getAggregateParameter({commit}, id) {
        commit('setAggregateParametersLoading', true)
        axios.get('/api/admin/aggregate_parameters/' + id).then(response => {
            commit('setAggregateParameter', response.data)
            commit('setAggregateParametersLoading', false)
        })
    },

    storeAggregateParameter({commit, dispatch}, aggregate_parameter) {
        commit('setAggregateParametersLoading', true)
        commit('setAggregateParameterErrorMessages', {})
        commit('setAggregateParameterErrors', [])

        axios.post('/api/admin/aggregate_parameters', aggregate_parameter).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingAggregateParameterDialog', false)
                commit('setAggregateParameter', {})
                dispatch('getAggregateParameters')

            // } else {
            //     commit('setAggregateParameterErrors', res.data.message)
            // }
            commit('setAggregateParametersLoading', false)
        }).catch(error => {
            commit('setAggregateParametersLoading', false)
            commit('setAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    deleteAggregateParameter({commit, dispatch}, id) {
        commit('setAggregateParametersLoading', true)
        commit('setAggregateParameterErrors', [])
        commit('setAggregateParameterErrorMessages', {})

        axios.delete('/api/admin/aggregate_parameters/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingAggregateParameterDialog', false)
                dispatch('getAggregateParameters')
            // } else {
                // commit('setAggregateParameterErrors', [res.data.message])
            // }
            commit('setAggregateParametersLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setAggregateParametersLoading', false)
            commit('setAggregateParameterErrorMessages', error.response.data.errors)
        })
    },
    editAggregateParameter({commit, dispatch}, {id, aggregate_parameter}) {
        commit('setAggregateParametersLoading', true)
        commit('setAggregateParameterErrorMessages', {})
        commit('setAggregateParameterErrors', [])
        axios.put('/api/admin/aggregate_parameters/' + id, aggregate_parameter).then((res) => {
            // if (res.data.ok) {
                commit('setAggregateParametersLoading', false)
                dispatch('getAggregateParameters')

            // } else {
            //     commit('setAggregateParameterErrors', res.data.message)
            // }
            commit('setEditingAggregateParameterDialog', false)
        }).catch(error => {
            commit('setAggregateParametersLoading', false)
            commit('setAggregateParameterErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setAggregateParameters(state, aggregate_parameters) {
        state.aggregate_parameters = aggregate_parameters
    },
    setAggregateParametersLoading(state, loading) {
        state.aggregate_parameters_loading = loading
    },
    setDeletingAggregateParameterDialog(state, deleting_aggregate_parameter_dialog) {
        state.deleting_aggregate_parameter_dialog = deleting_aggregate_parameter_dialog
    },
    setCreatingAggregateParameterDialog(state, creating_aggregate_parameter_dialog) {
        state.creating_aggregate_parameter_dialog = creating_aggregate_parameter_dialog
    },
    setEditingAggregateParameterDialog(state, editing_aggregate_parameter_dialog) {
        state.editing_aggregate_parameter_dialog = editing_aggregate_parameter_dialog
    },
    setAggregateParameterErrors(state, aggregate_parameter_errors) {
        state.aggregate_parameter_errors = aggregate_parameter_errors
    },
    setAggregateParameter(state, aggregate_parameter) {
        state.aggregate_parameter = aggregate_parameter
    },
    setAggregateParameterErrorMessages(state, aggregate_parameter_error_messages) {
        state.aggregate_parameter_error_messages = aggregate_parameter_error_messages
    },
    setPermissionItemAggregateParameterDialog(state, permission_item_aggregate_parameter_dialog) {
        state.permission_item_aggregate_parameter_dialog = permission_item_aggregate_parameter_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
