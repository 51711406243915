export default [
    {
        path: '/admin/fertilizer',
        name: 'fertilizer.index',
        component: () => import('../../pages/admin/Fertilizer/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/fertilizer/create',
        name: 'fertilizer.create',
        component: () => import('../../pages/admin/Fertilizer/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/fertilizer/:id/edit',
        name: 'fertilizer.edit',
        component: () => import('../../pages/admin/Fertilizer/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
