const state = {
    periods: [],
    period: {
        names: {},
        descriptions: {},
    },
    periods_loading: false,
    deleting_period_dialog: false,
    creating_period_dialog: false,
    editing_period_dialog: false,
    period_permissions: [],
    period_errors: [],
    period_error_messages: {}
}
const getters = {
    periods: state => state.periods,
    period: state => state.period,
    periods_loading: state => state.periods_loading,
    deleting_period_dialog: state => state.deleting_period_dialog,
    creating_period_dialog: state => state.creating_period_dialog,
    editing_period_dialog: state => state.editing_period_dialog,
    permission_item_period_dialog: state => state.permission_item_period_dialog,
    period_errors: state => state.period_errors,
    period_error_messages: state => state.period_error_messages,
}
const actions = {

    getPeriods({commit},) {
        commit('setPeriodsLoading', true)
        axios.get('/api/admin/periods', ).then(response => {
            commit('setPeriods', response.data)
            commit('setPeriodsLoading', false)
        })
    },
    getAllPeriods({commit}, params) {
        commit('setPeriodsLoading', true)
        axios.get('/api/periods/all', {params}).then(response => {
            commit('setPeriods', response.data)
            commit('setPeriodsLoading', false)
        })
    },
    setPeriodPermissions({commit}, params) {
        commit('setPeriodsLoading', true)
        axios.post('/api/periods/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemPeriodDialog', false)
            } else {
                commit('setPeriodErrors', [response.data.message])
            }
            commit('setPeriodsLoading', false)
        }).catch(error => {
            commit('setPeriodsLoading', false)
            commit('setPeriodErrorMessages', error.response.data.errors)
        })
    },
    getPeriod({commit}, id) {
        commit('setPeriodsLoading', true)
        axios.get('/api/admin/periods/' + id).then(response => {
            commit('setPeriod', response.data)
            commit('setPeriodsLoading', false)
        })
    },

    storePeriod({commit, dispatch}, period) {
        commit('setPeriodsLoading', true)
        commit('setPeriodErrorMessages', {})
        commit('setPeriodErrors', [])

        axios.post('/api/admin/periods', period).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingPeriodDialog', false)
                // commit('setPeriod', {})
                dispatch('getPeriods')

            // } else {
            //     commit('setPeriodErrors', res.data.message)
            // }
            commit('setPeriodsLoading', false)
        }).catch(error => {
            commit('setPeriodsLoading', false)
            commit('setPeriodErrorMessages', error.response.data.errors)
        })
    },
    deletePeriod({commit, dispatch}, id) {
        commit('setPeriodsLoading', true)
        commit('setPeriodErrors', [])
        commit('setPeriodErrorMessages', {})

        axios.delete('/api/admin/periods/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingPeriodDialog', false)
                dispatch('getPeriods')
            // } else {
                // commit('setPeriodErrors', [res.data.message])
            // }
            commit('setPeriodsLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setPeriodsLoading', false)
            commit('setPeriodErrorMessages', error.response.data.errors)
        })
    },
    editPeriod({commit, dispatch}, {id, period}) {
        commit('setPeriodsLoading', true)
        commit('setPeriodErrorMessages', {})
        commit('setPeriodErrors', [])
        axios.put('/api/admin/periods/' + id, period).then((res) => {
            // if (res.data.ok) {
                commit('setPeriodsLoading', false)
                dispatch('getPeriods')

            // } else {
            //     commit('setPeriodErrors', res.data.message)
            // }
            commit('setEditingPeriodDialog', false)
        }).catch(error => {
            commit('setPeriodsLoading', false)
            commit('setPeriodErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setPeriods(state, periods) {
        state.periods = periods
    },
    setPeriodsLoading(state, loading) {
        state.periods_loading = loading
    },
    setDeletingPeriodDialog(state, deleting_period_dialog) {
        state.deleting_period_dialog = deleting_period_dialog
    },
    setCreatingPeriodDialog(state, creating_period_dialog) {
        state.creating_period_dialog = creating_period_dialog
    },
    setEditingPeriodDialog(state, editing_period_dialog) {
        state.editing_period_dialog = editing_period_dialog
    },
    setPeriodErrors(state, period_errors) {
        state.period_errors = period_errors
    },
    setPeriod(state, period) {
        state.period = period
    },
    setPeriodErrorMessages(state, period_error_messages) {
        state.period_error_messages = period_error_messages
    },
    setPermissionItemPeriodDialog(state, permission_item_period_dialog) {
        state.permission_item_period_dialog = permission_item_period_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
