export default [
    {
        path: '/admin/crop-types',
        name: 'crop_types.index',
        component: () => import('../../pages/admin/CropType/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/crop-types/create',
        name: 'crop_types.create',
        component: () => import('../../pages/admin/CropType/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/crop-types/:id/edit',
        name: 'crop_types.edit',
        component: () => import('../../pages/admin/CropType/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
