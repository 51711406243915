const state = {
  regions: [],
  region: {
    names: {},
  },
  regions_loading: false,
  deleting_region_dialog: false,
  creating_region_dialog: false,
  region_errors: [],
  region_error_messages: {},
};
const getters = {
  regions: (state) => state.regions,
  region: (state) => state.region,
  regions_loading: (state) => state.regions_loading,
  deleting_region_dialog: (state) => state.deleting_region_dialog,
  creating_region_dialog: (state) => state.creating_region_dialog,
  region_errors: (state) => state.region_errors,
  region_error_messages: (state) => state.region_error_messages,
};
const actions = {
  getRegions({ commit }) {
    commit("regions_loading", true);
    axios.get("/api/admin/regions").then((response) => {
      commit("regions", response.data);
      commit("regions_loading", false);
    });
  },
  getRegion({ commit }, id) {
    commit("regions_loading", true);
    axios.get("/api/admin/regions/" + id).then((response) => {
      commit("region", response.data);
      commit("regions_loading", false);
    });
  },
  deleteRegion({ commit }, id) {
    commit("regions_loading", true);
    commit("region_errors", []);
    commit("region_error_messages", {});

    axios
      .delete("/api/admin/regions/" + id)
      .then(() => {
        commit("deleting_region_dialog", false);
        commit("regions_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("regions_loading", false);
        commit("region_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  regions(state, regions) {
    state.regions = regions;
  },
  regions_loading(state, regions_loading) {
    state.regions_loading = regions_loading;
  },
  deleting_region_dialog(state, deleting_region_dialog) {
    state.deleting_region_dialog = deleting_region_dialog;
  },
  creating_region_dialog(state, creating_region_dialog) {
    state.creating_region_dialog = creating_region_dialog;
  },
  region_errors(state, region_errors) {
    state.region_errors = region_errors;
  },
  region(state, region) {
    state.region = region;
  },
  region_error_messages(state, region_error_messages) {
    state.region_error_messages = region_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
