export default [
    {
        path: '/admin/fertilizer-categories',
        name: 'fertilizer_categories.index',
        component: () => import('../../pages/admin/FertilizerCategory/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/fertilizer-categories/create',
        name: 'fertilizer_categories.create',
        component: () => import('../../pages/admin/FertilizerCategory/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/fertilizer-categories/:id/edit',
        name: 'fertilizer_categories.edit',
        component: () => import('../../pages/admin/FertilizerCategory/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
