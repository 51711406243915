const navigators = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    route: "/admin/dashboard",
  },
  {
    title: "Бошланғич маълумотлар",
    subheader: true,
  },
  {
    title: "Экин турлари",
    icon: "mdi-tree-outline",
    route: "/admin/crop-types",
  },
  {
    title: "Экиш схемалари",
    icon: "mdi-map-marker",
    route: "/admin/planting-distances",
  },
  {
    title: "Минтақалар",
    icon: "mdi-map-clock",
    route: "/admin/zones",
  },
  {
    title: "Манзиллар",
    icon: "mdi-map-marker",
    route: "/admin/locations",
  },
  {
    title: "Агротехник тадбир номлари",
    icon: "mdi-account-multiple",
    route: "/admin/actions",
  },
  {
    title: "Минерал ўғитлар",
    subheader: true,
  },
  {
    title: "Тупроқ пояслари ва типлари",
    icon: "mdi-tree-outline",
    route: "/admin/soil-type",
  },
  {
    title: "Зоналар",
    icon: "mdi-tree-outline",
    route: "/admin/soil-zone",
  },
  {
    title: "Даврлар",
    icon: "mdi-tree-outline",
    route: "/admin/soil-cycle",
  },
  {
    title: "Кутилаётган ҳосилдорлик",
    icon: "mdi-tree-outline",
    route: "/admin/soil-range",
  },
  {
    title: "Минерал ўғит меъёри",
    icon: "mdi-tree-outline",
    route: "/admin/soil-table",
  },
  {
    title: "Минерал ўғит меъёри excel",
    icon: "mdi-tree-outline",
    route: "/admin/soil-table-excel",
  },
  {
    title: "Ўғитлар",
    subheader: true,
  },
  {
    title: "Ўғит категориялари",
    icon: "mdi-tree-outline",
    route: "/admin/fertilizer-categories",
  },
  {
    title: "Ўғитлар",
    icon: "mdi-tree-outline",
    route: "/admin/fertilizer",
  },
  {
    title: "Даврлар",
    subheader: true,
  },
  {
    title: "Даврлар",
    icon: "mdi-clock-outline",
    route: "/admin/periods",
  },
  {
    title: "Даврлар ичидаги гурухлар",
    icon: "mdi-clock-outline",
    route: "/admin/secondary-periods",
  },
  {
    title: "Техникалар",
    subheader: true,
  },
  {
    title: "Техника маркаси",
    icon: "mdi-tractor",
    route: "/admin/technique-brands",
  },
  {
    title: "Техника категориялари",
    icon: "mdi-tractor",
    route: "/admin/technique-categories",
  },
  {
    title: "Техникалар",
    icon: "mdi-tractor",
    route: "/admin/techniques",
  },
  {
    title: "Қишлоқ хўжалиги машина категориялари",
    icon: "mdi-hammer-wrench",
    route: "/admin/aggregate-categories",
  },
  {
    title: "Қишлоқ хўжалиги машинаси",
    icon: "mdi-hammer-wrench",
    route: "/admin/aggregates",
  },
  {
    title: "Майдонлар",
    subheader: true,
  },
  {
    title: "Майдонлар",
    icon: "mdi-lan",
    route: "/admin/area-ranges",
  },
  {
    title: "Майдонлар қишлоқ хўжалиги машинаси",
    icon: "mdi-hammer-wrench",
    route: "/admin/area-range-aggregates",
  },
  {
    title: "Майдонлар техникалари",
    icon: "mdi-hammer-wrench",
    route: "/admin/area-range-techniques",
  },

  {
    title: "Асосий",
    subheader: true,
  },
  {
    title: "Тадбир учун дала параметрлари",
    icon: "mdi-calendar-check",
    route: "/admin/events",
  },
  {
    title: "Режалаштирилган тадбирлар шаблонлари",
    icon: "mdi-calendar-check",
    route: "/admin/scheduled-event-templates",
  },
  // {
  //   title: "Тавсия этилган Қишлоқ хўжалиги машиналари",
  //   icon: "mdi-calendar-check",
  //   route: "/admin/recommended-aggregates-to-events",
  // },
  // {
  //   title: "Агрегатлаш",
  //   icon: "mdi-calendar-check",
  //   route: "/admin/recommended-techniques-to-events",
  // },
  {
    title: "Кластерлар",
    icon: "mdi-group",
    route: "/admin/clusters",
  },
  {
    title: "Давлатлар",
    subheader: true,
  },
  {
    title: "Давлатлар",
    icon: "mdi-calendar-check",
    route: "/admin/country",
  },
  {
    title: "Вилоятлар",
    icon: "mdi-calendar-check",
    route: "/admin/region",
  },
  {
    title: "Туманлар",
    icon: "mdi-calendar-check",
    route: "/admin/district",
  },
];

export default navigators;
