const state = {
  fertilizer_categories: [],
  fertilizer_categories_loading: false,
  deleting_fertilizer_category_dialog: false,
  creating_fertilizer_category_dialog: false,
  fertilizer_category_errors: [],
  fertilizer_category_error_messages: {},
};
const getters = {
  fertilizer_categories: (state) => state.fertilizer_categories,
  fertilizer_categories_loading: (state) => state.fertilizer_categories_loading,
  deleting_fertilizer_category_dialog: (state) =>
    state.deleting_fertilizer_category_dialog,
  creating_fertilizer_category_dialog: (state) =>
    state.creating_fertilizer_category_dialog,
  fertilizer_category_errors: (state) => state.fertilizer_category_errors,
  fertilizer_category_error_messages: (state) =>
    state.fertilizer_category_error_messages,
};
const actions = {
  getFertilizerCategories({ commit }) {
    commit("setFertilizerCategoriesLoading", true);
    axios.get("/api/admin/fertilizers_categories").then((response) => {
      commit("setFertilizerCategories", response.data);
      commit("setFertilizerCategoriesLoading", false);
    });
  },
  deleteFertilizerCategory({ commit, dispatch }, id) {
    commit("setFertilizerCategoriesLoading", true);
    commit("setFertilizerCategoryErrors", []);
    commit("setFertilizerCategoryErrorMessages", {});

    axios
      .delete("/api/admin/fertilizers_categories/" + id)
      .then(() => {
        commit("setDeletingFertilizerCategoryDialog", false);
        dispatch("getFertilizerCategories");
        commit("setFertilizerCategoriesLoading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("setFertilizerCategoriesLoading", false);
        commit(
          "setFertilizerCategoryErrorMessages",
          error.response.data.errors
        );
      });
  },
};

const mutations = {
  setFertilizerCategories(state, fertilizer_categories) {
    state.fertilizer_categories = fertilizer_categories;
  },
  setFertilizerCategoriesLoading(state, loading) {
    state.fertilizer_categories_loading = loading;
  },
  setDeletingFertilizerCategoryDialog(
    state,
    deleting_fertilizer_category_dialog
  ) {
    state.deleting_fertilizer_category_dialog =
      deleting_fertilizer_category_dialog;
  },
  setCreatingFertilizerCategoryDialog(
    state,
    creating_fertilizer_category_dialog
  ) {
    state.creating_fertilizer_category_dialog =
      creating_fertilizer_category_dialog;
  },
  setFertilizerCategoryErrors(state, fertilizer_category_errors) {
    state.fertilizer_category_errors = fertilizer_category_errors;
  },
  setFertilizerCategoryErrorMessages(
    state,
    fertilizer_category_error_messages
  ) {
    state.fertilizer_category_error_messages =
      fertilizer_category_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
