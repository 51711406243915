export default [
  {
    path: "/admin/region",
    name: "region.index",
    component: () => import("../../pages/admin/Region/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/region/create",
    name: "region.create",
    component: () => import("../../pages/admin/Region/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/region/:id/edit",
    name: "region.edit",
    component: () => import("../../pages/admin/Region/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
