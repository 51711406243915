export default [
    {
        path: '/admin/recommended-techniques-to-events',
        name: 'recommended_techniques_to_events.index',
        component: () => import('../../pages/admin/RecommendedTechniquesToEvents/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/recommended-techniques-to-events/create',
        name: 'recommended_techniques_to_events.create',
        component: () => import('../../pages/admin/RecommendedTechniquesToEvents/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/recommended-techniques-to-events/:id/edit',
        name: 'recommended_techniques_to_events.edit',
        component: () => import('../../pages/admin/RecommendedTechniquesToEvents/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
