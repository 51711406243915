export default [
  {
    path: "/admin/events",
    name: "events.index",
    component: () => import("../../pages/admin/Event/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/events/create",
    name: "events.create",
    component: () => import("../../pages/admin/Event/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/events/copy",
    name: "events.copy",
    component: () => import("../../pages/admin/Event/Copy.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/events/:id/edit",
    name: "events.edit",
    component: () => import("../../pages/admin/Event/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
