const state = {
    zones: [],
    zone: {
        names: {},
        descriptions: {},
    },
    zones_loading: false,
    deleting_zone_dialog: false,
    creating_zone_dialog: false,
    editing_zone_dialog: false,
    zone_permissions: [],
    zone_errors: [],
    zone_error_messages: {}
}
const getters = {
    zones: state => state.zones,
    zone: state => state.zone,
    zones_loading: state => state.zones_loading,
    deleting_zone_dialog: state => state.deleting_zone_dialog,
    creating_zone_dialog: state => state.creating_zone_dialog,
    editing_zone_dialog: state => state.editing_zone_dialog,
    permission_item_zone_dialog: state => state.permission_item_zone_dialog,
    zone_errors: state => state.zone_errors,
    zone_error_messages: state => state.zone_error_messages,
}
const actions = {

    getZones({commit},) {
        commit('setZonesLoading', true)
        axios.get('/api/admin/zones', ).then(response => {
            commit('setZones', response.data)
            commit('setZonesLoading', false)
        })
    },
    getAllZones({commit}, params) {
        commit('setZonesLoading', true)
        axios.get('/api/zones/all', {params}).then(response => {
            commit('setZones', response.data)
            commit('setZonesLoading', false)
        })
    },
    setZonePermissions({commit}, params) {
        commit('setZonesLoading', true)
        axios.post('/api/zones/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemZoneDialog', false)
            } else {
                commit('setZoneErrors', [response.data.message])
            }
            commit('setZonesLoading', false)
        }).catch(error => {
            commit('setZonesLoading', false)
            commit('setZoneErrorMessages', error.response.data.errors)
        })
    },
    getZone({commit}, id) {
        commit('setZonesLoading', true)
        axios.get('/api/admin/zones/' + id).then(response => {
            commit('setZone', response.data)
            commit('setZonesLoading', false)
        })
    },

    storeZone({commit, dispatch}, zone) {
        commit('setZonesLoading', true)
        commit('setZoneErrorMessages', {})
        commit('setZoneErrors', [])

        axios.post('/api/admin/zones', zone).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingZoneDialog', false)
                commit('setZone', {})
                dispatch('getZones')

            // } else {
            //     commit('setZoneErrors', res.data.message)
            // }
            commit('setZonesLoading', false)
        }).catch(error => {
            commit('setZonesLoading', false)
            commit('setZoneErrorMessages', error.response.data.errors)
        })
    },
    deleteZone({commit, dispatch}, id) {
        commit('setZonesLoading', true)
        commit('setZoneErrors', [])
        commit('setZoneErrorMessages', {})

        axios.delete('/api/admin/zones/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingZoneDialog', false)
                dispatch('getZones')
            // } else {
                // commit('setZoneErrors', [res.data.message])
            // }
            commit('setZonesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setZonesLoading', false)
            commit('setZoneErrorMessages', error.response.data.errors)
        })
    },
    editZone({commit, dispatch}, {id, zone}) {
        commit('setZonesLoading', true)
        commit('setZoneErrorMessages', {})
        commit('setZoneErrors', [])
        axios.put('/api/admin/zones/' + id, zone).then((res) => {
            // if (res.data.ok) {
                commit('setZonesLoading', false)
                dispatch('getZones')

            // } else {
            //     commit('setZoneErrors', res.data.message)
            // }
            commit('setEditingZoneDialog', false)
        }).catch(error => {
            commit('setZonesLoading', false)
            commit('setZoneErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setZones(state, zones) {
        state.zones = zones
    },
    setZonesLoading(state, loading) {
        state.zones_loading = loading
    },
    setDeletingZoneDialog(state, deleting_zone_dialog) {
        state.deleting_zone_dialog = deleting_zone_dialog
    },
    setCreatingZoneDialog(state, creating_zone_dialog) {
        state.creating_zone_dialog = creating_zone_dialog
    },
    setEditingZoneDialog(state, editing_zone_dialog) {
        state.editing_zone_dialog = editing_zone_dialog
    },
    setZoneErrors(state, zone_errors) {
        state.zone_errors = zone_errors
    },
    setZone(state, zone) {
        state.zone = zone
    },
    setZoneErrorMessages(state, zone_error_messages) {
        state.zone_error_messages = zone_error_messages
    },
    setPermissionItemZoneDialog(state, permission_item_zone_dialog) {
        state.permission_item_zone_dialog = permission_item_zone_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
