export default [
    {
        path: '/admin/planting-distances',
        name: 'planting_distance.index',
        component: () => import('../../pages/admin/PlantingDistance/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/planting-distances/create',
        name: 'planting_distance.create',
        component: () => import('../../pages/admin/PlantingDistance/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/planting-distances/:id/edit',
        name: 'planting_distance.edit',
        component: () => import('../../pages/admin/PlantingDistance/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
