const state = {
  aggregate_categories: [],
  aggregate_category: {
    names: {},
    descriptions: {},
  },
  aggregate_categories_loading: false,
  deleting_aggregate_category_dialog: false,
  creating_aggregate_category_dialog: false,
  editing_aggregate_category_dialog: false,
  aggregate_category_permissions: [],
  aggregate_category_errors: [],
  aggregate_category_error_messages: {},
  aggregate_category_to_scheduled_event_templates: [],
};
const getters = {
  aggregate_categories: (state) => state.aggregate_categories,
  aggregate_category: (state) => state.aggregate_category,
  aggregate_categories_loading: (state) => state.aggregate_categories_loading,
  deleting_aggregate_category_dialog: (state) =>
    state.deleting_aggregate_category_dialog,
  creating_aggregate_category_dialog: (state) =>
    state.creating_aggregate_category_dialog,
  editing_aggregate_category_dialog: (state) =>
    state.editing_aggregate_category_dialog,
  permission_item_aggregate_category_dialog: (state) =>
    state.permission_item_aggregate_category_dialog,
  aggregate_category_errors: (state) => state.aggregate_category_errors,
  aggregate_category_error_messages: (state) =>
    state.aggregate_category_error_messages,
  aggregate_category_to_scheduled_event_templates: (state) =>
    state.aggregate_category_to_scheduled_event_templates,
};
const actions = {
  getAggregateCategories({ commit }) {
    commit("setAggregateCategoriesLoading", true);
    axios.get("/api/admin/aggregate_categories").then((response) => {
      commit("setAggregateCategories", response.data);
      commit("setAggregateCategoriesLoading", false);
    });
  },
  getAggregateCategoriesScheduledEventTemplate({ commit }, params) {
    axios
      .get("/api/admin/aggregate_category_to_scheduled_event_templates", {
        params,
      })
      .then((response) => {
        commit(
          "aggregate_category_to_scheduled_event_templates",
          response.data
        );
      });
  },
  getAllAggregateCategories({ commit }, params) {
    commit("setAggregateCategoriesLoading", true);
    axios.get("/api/aggregate_categories/all", { params }).then((response) => {
      commit("setAggregateCategories", response.data);
      commit("setAggregateCategoriesLoading", false);
    });
  },
  setAggregateCategoryPermissions({ commit }, params) {
    commit("setAggregateCategoriesLoading", true);
    axios
      .post("/api/aggregate_categories/" + params.id + "/permissions", params)
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemAggregateCategoryDialog", false);
        } else {
          commit("setAggregateCategoryErrors", [response.data.message]);
        }
        commit("setAggregateCategoriesLoading", false);
      })
      .catch((error) => {
        commit("setAggregateCategoriesLoading", false);
        commit("setAggregateCategoryErrorMessages", error.response.data.errors);
      });
  },
  getAggregateCategory({ commit }, id) {
    commit("setAggregateCategoriesLoading", true);
    axios.get("/api/admin/aggregate_categories/" + id).then((response) => {
      commit("setAggregateCategory", response.data);
      commit("setAggregateCategoriesLoading", false);
    });
  },

  storeAggregateCategory({ commit, dispatch }, aggregate_category) {
    commit("setAggregateCategoriesLoading", true);
    commit("setAggregateCategoryErrorMessages", {});
    commit("setAggregateCategoryErrors", []);

    axios
      .post("/api/admin/aggregate_categories", aggregate_category)
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingAggregateCategoryDialog", false);
        commit("setAggregateCategory", {});
        dispatch("getAggregateCategories");

        // } else {
        //     commit('setAggregateCategoryErrors', res.data.message)
        // }
        commit("setAggregateCategoriesLoading", false);
      })
      .catch((error) => {
        commit("setAggregateCategoriesLoading", false);
        commit("setAggregateCategoryErrorMessages", error.response.data.errors);
      });
  },
  deleteAggregateCategory({ commit, dispatch }, id) {
    commit("setAggregateCategoriesLoading", true);
    commit("setAggregateCategoryErrors", []);
    commit("setAggregateCategoryErrorMessages", {});

    axios
      .delete("/api/admin/aggregate_categories/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingAggregateCategoryDialog", false);
        dispatch("getAggregateCategories");
        // } else {
        // commit('setAggregateCategoryErrors', [res.data.message])
        // }
        commit("setAggregateCategoriesLoading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("setAggregateCategoriesLoading", false);
        commit("setAggregateCategoryErrorMessages", error.response.data.errors);
      });
  },
  editAggregateCategory({ commit, dispatch }, { id, aggregate_category }) {
    commit("setAggregateCategoriesLoading", true);
    commit("setAggregateCategoryErrorMessages", {});
    commit("setAggregateCategoryErrors", []);
    axios
      .put("/api/admin/aggregate_categories/" + id, aggregate_category)
      .then((res) => {
        // if (res.data.ok) {
        commit("setAggregateCategoriesLoading", false);
        dispatch("getAggregateCategories");

        // } else {
        //     commit('setAggregateCategoryErrors', res.data.message)
        // }
        commit("setEditingAggregateCategoryDialog", false);
      })
      .catch((error) => {
        commit("setAggregateCategoriesLoading", false);
        commit("setAggregateCategoryErrorMessages", error.response.data.errors);
      });
  },
};

const mutations = {
  setAggregateCategories(state, aggregate_categories) {
    state.aggregate_categories = aggregate_categories;
  },
  aggregate_category_to_scheduled_event_templates(
    state,
    aggregate_category_to_scheduled_event_templates
  ) {
    state.aggregate_category_to_scheduled_event_templates =
      aggregate_category_to_scheduled_event_templates;
  },
  setAggregateCategoriesLoading(state, loading) {
    state.aggregate_categories_loading = loading;
  },
  setDeletingAggregateCategoryDialog(
    state,
    deleting_aggregate_category_dialog
  ) {
    state.deleting_aggregate_category_dialog =
      deleting_aggregate_category_dialog;
  },
  setCreatingAggregateCategoryDialog(
    state,
    creating_aggregate_category_dialog
  ) {
    state.creating_aggregate_category_dialog =
      creating_aggregate_category_dialog;
  },
  setEditingAggregateCategoryDialog(state, editing_aggregate_category_dialog) {
    state.editing_aggregate_category_dialog = editing_aggregate_category_dialog;
  },
  setAggregateCategoryErrors(state, aggregate_category_errors) {
    state.aggregate_category_errors = aggregate_category_errors;
  },
  setAggregateCategory(state, aggregate_category) {
    state.aggregate_category = aggregate_category;
  },
  setAggregateCategoryErrorMessages(state, aggregate_category_error_messages) {
    state.aggregate_category_error_messages = aggregate_category_error_messages;
  },
  setPermissionItemAggregateCategoryDialog(
    state,
    permission_item_aggregate_category_dialog
  ) {
    state.permission_item_aggregate_category_dialog =
      permission_item_aggregate_category_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
