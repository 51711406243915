const state = {
    planting_distances: [],
    planting_distance: {
        names: {},
        descriptions: {},
    },
    planting_distances_loading: false,
    deleting_planting_distance_dialog: false,
    creating_planting_distance_dialog: false,
    editing_planting_distance_dialog: false,
    planting_distance_permissions: [],
    planting_distance_errors: [],
    planting_distance_error_messages: {}
}
const getters = {
    planting_distances: state => state.planting_distances,
    planting_distance: state => state.planting_distance,
    planting_distances_loading: state => state.planting_distances_loading,
    deleting_planting_distance_dialog: state => state.deleting_planting_distance_dialog,
    creating_planting_distance_dialog: state => state.creating_planting_distance_dialog,
    editing_planting_distance_dialog: state => state.editing_planting_distance_dialog,
    permission_item_planting_distance_dialog: state => state.permission_item_planting_distance_dialog,
    planting_distance_errors: state => state.planting_distance_errors,
    planting_distance_error_messages: state => state.planting_distance_error_messages,
}
const actions = {

    getPlantingDistances({commit},) {
        commit('setPlantingDistancesLoading', true)
        axios.get('/api/admin/planting_distances', ).then(response => {
            commit('setPlantingDistances', response.data)
            commit('setPlantingDistancesLoading', false)
        })
    },
    getAllPlantingDistances({commit}, params) {
        commit('setPlantingDistancesLoading', true)
        axios.get('/api/planting_distances/all', {params}).then(response => {
            commit('setPlantingDistances', response.data)
            commit('setPlantingDistancesLoading', false)
        })
    },
    setPlantingDistancePermissions({commit}, params) {
        commit('setPlantingDistancesLoading', true)
        axios.post('/api/planting_distances/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemPlantingDistanceDialog', false)
            } else {
                commit('setPlantingDistanceErrors', [response.data.message])
            }
            commit('setPlantingDistancesLoading', false)
        }).catch(error => {
            commit('setPlantingDistancesLoading', false)
            commit('setPlantingDistanceErrorMessages', error.response.data.errors)
        })
    },
    getPlantingDistance({commit}, id) {
        commit('setPlantingDistancesLoading', true)
        axios.get('/api/admin/planting_distances/' + id).then(response => {
            commit('setPlantingDistance', response.data)
            commit('setPlantingDistancesLoading', false)
        })
    },

    storePlantingDistance({commit, dispatch}, planting_distance) {
        commit('setPlantingDistancesLoading', true)
        commit('setPlantingDistanceErrorMessages', {})
        commit('setPlantingDistanceErrors', [])

        axios.post('/api/admin/planting_distances', planting_distance).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingPlantingDistanceDialog', false)
                commit('setPlantingDistance', {})
                dispatch('getPlantingDistances')

            // } else {
            //     commit('setPlantingDistanceErrors', res.data.message)
            // }
            commit('setPlantingDistancesLoading', false)
        }).catch(error => {
            commit('setPlantingDistancesLoading', false)
            commit('setPlantingDistanceErrorMessages', error.response.data.errors)
        })
    },
    deletePlantingDistance({commit, dispatch}, id) {
        commit('setPlantingDistancesLoading', true)
        commit('setPlantingDistanceErrors', [])
        commit('setPlantingDistanceErrorMessages', {})

        axios.delete('/api/admin/planting_distances/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingPlantingDistanceDialog', false)
                dispatch('getPlantingDistances')
            // } else {
                // commit('setPlantingDistanceErrors', [res.data.message])
            // }
            commit('setPlantingDistancesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setPlantingDistancesLoading', false)
            commit('setPlantingDistanceErrorMessages', error.response.data.errors)
        })
    },
    editPlantingDistance({commit, dispatch}, {id, planting_distance}) {
        commit('setPlantingDistancesLoading', true)
        commit('setPlantingDistanceErrorMessages', {})
        commit('setPlantingDistanceErrors', [])
        axios.put('/api/admin/planting_distances/' + id, planting_distance).then((res) => {
            // if (res.data.ok) {
                commit('setPlantingDistancesLoading', false)
                dispatch('getPlantingDistances')

            // } else {
            //     commit('setPlantingDistanceErrors', res.data.message)
            // }
            commit('setEditingPlantingDistanceDialog', false)
        }).catch(error => {
            commit('setPlantingDistancesLoading', false)
            commit('setPlantingDistanceErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setPlantingDistances(state, planting_distances) {
        state.planting_distances = planting_distances
    },
    setPlantingDistancesLoading(state, loading) {
        state.planting_distances_loading = loading
    },
    setDeletingPlantingDistanceDialog(state, deleting_planting_distance_dialog) {
        state.deleting_planting_distance_dialog = deleting_planting_distance_dialog
    },
    setCreatingPlantingDistanceDialog(state, creating_planting_distance_dialog) {
        state.creating_planting_distance_dialog = creating_planting_distance_dialog
    },
    setEditingPlantingDistanceDialog(state, editing_planting_distance_dialog) {
        state.editing_planting_distance_dialog = editing_planting_distance_dialog
    },
    setPlantingDistanceErrors(state, planting_distance_errors) {
        state.planting_distance_errors = planting_distance_errors
    },
    setPlantingDistance(state, planting_distance) {
        state.planting_distance = planting_distance
    },
    setPlantingDistanceErrorMessages(state, planting_distance_error_messages) {
        state.planting_distance_error_messages = planting_distance_error_messages
    },
    setPermissionItemPlantingDistanceDialog(state, permission_item_planting_distance_dialog) {
        state.permission_item_planting_distance_dialog = permission_item_planting_distance_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
