const state = {
  districts: [],
  district: {
    names: {},
  },
  districts_loading: false,
  deleting_district_dialog: false,
  creating_district_dialog: false,
  district_errors: [],
  district_error_messages: {},
};
const getters = {
  districts: (state) => state.districts,
  district: (state) => state.district,
  districts_loading: (state) => state.districts_loading,
  deleting_district_dialog: (state) => state.deleting_district_dialog,
  creating_district_dialog: (state) => state.creating_district_dialog,
  district_errors: (state) => state.district_errors,
  district_error_messages: (state) => state.district_error_messages,
};
const actions = {
  getDistricts({ commit }) {
    commit("districts_loading", true);
    axios.get("/api/admin/districts").then((response) => {
      commit("districts", response.data);
      commit("districts_loading", false);
    });
  },
  getDistrict({ commit }, id) {
    commit("districts_loading", true);
    axios.get("/api/admin/districts/" + id).then((response) => {
      commit("district", response.data);
      commit("districts_loading", false);
    });
  },
  deleteDistrict({ commit }, id) {
    commit("districts_loading", true);
    commit("district_errors", []);
    commit("district_error_messages", {});

    axios
      .delete("/api/admin/districts/" + id)
      .then(() => {
        commit("deleting_district_dialog", false);
        commit("districts_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("districts_loading", false);
        commit("district_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  districts(state, districts) {
    state.districts = districts;
  },
  districts_loading(state, districts_loading) {
    state.districts_loading = districts_loading;
  },
  deleting_district_dialog(state, deleting_district_dialog) {
    state.deleting_district_dialog = deleting_district_dialog;
  },
  creating_district_dialog(state, creating_district_dialog) {
    state.creating_district_dialog = creating_district_dialog;
  },
  district_errors(state, district_errors) {
    state.district_errors = district_errors;
  },
  district(state, district) {
    state.district = district;
  },
  district_error_messages(state, district_error_messages) {
    state.district_error_messages = district_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
