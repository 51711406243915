const state = {
  soil_ranges: [],
  soil_range_loading: false,
  deleting_soil_range_dialog: false,
  creating_soil_range_dialog: false,
  soil_range_errors: [],
  soil_range_error_messages: {},
};
const getters = {
  soil_ranges: (state) => state.soil_ranges,
  soil_range_loading: (state) => state.soil_range_loading,
  deleting_soil_range_dialog: (state) => state.deleting_soil_range_dialog,
  soil_range_errors: (state) => state.soil_range_errors,
  soil_range_error_messages: (state) => state.soil_range_error_messages,
};
const actions = {
  getSoilRange({ commit }) {
    commit("soil_range_loading", true);
    axios.get("/api/admin/soil_productivity_ranges").then((response) => {
      commit("soil_ranges", response.data);
      commit("soil_range_loading", false);
    });
  },

  deleteSoilRange({ commit, dispatch }, id) {
    commit("soil_range_loading", true);
    commit("soil_range_errors", []);
    commit("soil_range_error_messages", {});

    axios
      .delete("/api/admin/soil_productivity_ranges/" + id)
      .then(() => {
        commit("deleting_soil_range_dialog", false);
        dispatch("getSoilRange");
        commit("soil_range_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("soil_range_loading", false);
        commit("soil_range_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  soil_ranges(state, soil_ranges) {
    state.soil_ranges = soil_ranges;
  },
  soil_range_loading(state, loading) {
    state.soil_range_loading = loading;
  },
  deleting_soil_range_dialog(state, deleting_soil_range_dialog) {
    state.deleting_soil_range_dialog = deleting_soil_range_dialog;
  },
  soil_range_errors(state, soil_range_errors) {
    state.soil_range_errors = soil_range_errors;
  },
  soil_range_error_messages(state, soil_range_error_messages) {
    state.soil_range_error_messages = soil_range_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
