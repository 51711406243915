import Vue from "vue";
import App from "./components/App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import { VueMaskDirective, VueMaskFilter } from "v-mask";
import i18n from "./i18n";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability";
import VueTheMask from "vue-the-mask";
import "sweetalert2/dist/sweetalert2.min.css";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
window.axios = axios;

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueTheMask);
Vue.use(Vuetify);
Vue.use(VueSweetalert2);
Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
});
Vue.directive("mask", VueMaskDirective);
Vue.filter("VMask", VueMaskFilter);

window.axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("userType") == "admin"
    ? "Bearer " + localStorage.getItem("accessToken")
    : localStorage.getItem("token");
window.axios.defaults.headers.common["Accept"] = "application/json";
window.axios.defaults.headers.common["Content-Type"] = "application/json";
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;

const dayjs = require("dayjs/locale/uz-latn");

// var hours = 24;
// var now = new Date().getTime();
// var setupTime = localStorage.getItem('setupTime');
// if (setupTime == null) {
//     localStorage.setItem('setupTime', now)
// } else {
//     // if(now-setupTime > hours*60*60*1000) {
//     if(now-setupTime > 5*1000) {
//         localStorage.setItem('setupTime', now)
//         window.location.reload(true);
//     }
// }

window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

window.addEventListener("load", function () {
  const app = new Vue({
    router,
    store,
    i18n,
    vuetify: new Vuetify({
      theme: {
        themes: {
          light: {
            background: "#F5F5F5",
            primary: "#08705F",
            success: "#63D15A",
            selected: "#1AA3FF",
            error: "#FA5948",
            dark: "#565656",
            merge_save: "#3DA535",
          },
        },
      },
    }),
    render: (h) => h(App),
    el: "#app",
  });
});
//
// Vue.directive('click-outside', {
//     bind: function (el, binding, vnode) {
//         el.clickOutsideEvent = function (event) {
//             // here I check that click was outside the el and his children
//             if (!(el == event.target || el.contains(event.target))) {
//                 // and if it did, call method provided in attribute value
//                 vnode.context[binding.expression](event);
//             }
//         };
//         document.body.addEventListener('click', el.clickOutsideEvent)
//     },
//     unbind: function (el) {
//         document.body.removeEventListener('click', el.clickOutsideEvent)
//     },
// });
