import ability from '../services/ability';

const state = {
    user: null,
    token: null,
    errors: null,
}
const getters = {
    user: state => state.user,
    token: state => state.token,
    errors: state => state.errors,
}
const actions = {
    async login({commit, dispatch}, params) {
        try {
            // const response = await axios.post('/login', params)
            // commit('setToken', response.data.access_token)
            // commit('setUser', response.data.user)
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
            // localStorage.setItem('accessToken', response.data.access_token)
            localStorage.setItem('accessToken', "82d1d91e-5610-40bf-b1e7-3b76189b1ad8")
            localStorage.setItem('userData', JSON.stringify(response.data.user))
            return true
        } catch (error) {
            commit('setErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit, dispatch}, params) {
        try {
            // const response = await axios.post('/admin/logout', params)
            localStorage.clear()
            return true
        } catch (error) {
            commit('setErrors', error.response.data.errors)
            return false
        }
    },
    async getUserAbility({commit}) {
        try {
            const response = await axios.get('/api/ability')
            return {ok: true, data: response.data.data}
        } catch (error) {
            commit('setErrors', error.response.data.errors)
            return {ok: false, data: error.response.data.errors}
        }
    },
}
const mutations = {
    setToken(state, token) {
        state.token = token
    },
    setUser(state, user) {
        state.user = user
    },
    setErrors(state, errors) {
        state.errors = errors
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
