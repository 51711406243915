export default [
  {
    path: "/admin/country",
    name: "country.index",
    component: () => import("../../pages/admin/Country/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/country/create",
    name: "country.create",
    component: () => import("../../pages/admin/Country/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/country/:id/edit",
    name: "country.edit",
    component: () => import("../../pages/admin/Country/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
