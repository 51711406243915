<template>
    <v-app>
        <SideBar></SideBar>
        <AppBar></AppBar>
        <v-main>
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>

    </v-app>

</template>

<script>
import SideBar from '../components/navigation/SideBar'
import AppBar from '../components/navigation/AppBar'

export default {
    name: "LayoutContent",
    components: {
        SideBar,
        AppBar
    }

}
</script>

<style scoped>

</style>
