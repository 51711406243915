export default [
    {
        path: '/admin/aggregate-aggregate-parameters',
        name: 'aggregate_aggregate_parameters.index',
        component: () => import('../../pages/admin/AggregateAggregateParameter/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregate-aggregate-parameters/create',
        name: 'aggregate_aggregate_parameters.create',
        component: () => import('../../pages/admin/AggregateAggregateParameter/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregate-aggregate-parameters/:id/edit',
        name: 'aggregate_aggregate_parameters.edit',
        component: () => import('../../pages/admin/AggregateAggregateParameter/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
