const state = {
    area_range_techniques: [],
    area_range_technique: {
        names: {},
        descriptions: {},
    },
    area_range_techniques_loading: false,
    deleting_area_range_technique_dialog: false,
    creating_area_range_technique_dialog: false,
    editing_area_range_technique_dialog: false,
    area_range_technique_permissions: [],
    area_range_technique_errors: [],
    area_range_technique_error_messages: {}
}
const getters = {
    area_range_techniques: state => state.area_range_techniques,
    area_range_technique: state => state.area_range_technique,
    area_range_techniques_loading: state => state.area_range_techniques_loading,
    deleting_area_range_technique_dialog: state => state.deleting_area_range_technique_dialog,
    creating_area_range_technique_dialog: state => state.creating_area_range_technique_dialog,
    editing_area_range_technique_dialog: state => state.editing_area_range_technique_dialog,
    permission_item_area_range_technique_dialog: state => state.permission_item_area_range_technique_dialog,
    area_range_technique_errors: state => state.area_range_technique_errors,
    area_range_technique_error_messages: state => state.area_range_technique_error_messages,
}
const actions = {

    getAreaRangeTechniques({commit},) {
        commit('setAreaRangeTechniquesLoading', true)
        axios.get('/api/admin/area_range_techniques', ).then(response => {
            commit('setAreaRangeTechniques', response.data)
            commit('setAreaRangeTechniquesLoading', false)
        })
    },
    getAllAreaRangeTechniques({commit}, params) {
        commit('setAreaRangeTechniquesLoading', true)
        axios.get('/api/area_range_techniques/all', {params}).then(response => {
            commit('setAreaRangeTechniques', response.data)
            commit('setAreaRangeTechniquesLoading', false)
        })
    },
    setAreaRangeTechniquePermissions({commit}, params) {
        commit('setAreaRangeTechniquesLoading', true)
        axios.post('/api/area_range_techniques/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemAreaRangeTechniqueDialog', false)
            } else {
                commit('setAreaRangeTechniqueErrors', [response.data.message])
            }
            commit('setAreaRangeTechniquesLoading', false)
        }).catch(error => {
            commit('setAreaRangeTechniquesLoading', false)
            commit('setAreaRangeTechniqueErrorMessages', error.response.data.errors)
        })
    },
    getAreaRangeTechnique({commit}, id) {
        commit('setAreaRangeTechniquesLoading', true)
        axios.get('/api/admin/area_range_techniques/' + id).then(response => {
            commit('setAreaRangeTechnique', response.data)
            commit('setAreaRangeTechniquesLoading', false)
        })
    },

    storeAreaRangeTechnique({commit, dispatch}, area_range_technique) {
        commit('setAreaRangeTechniquesLoading', true)
        commit('setAreaRangeTechniqueErrorMessages', {})
        commit('setAreaRangeTechniqueErrors', [])

        axios.post('/api/admin/area_range_techniques', area_range_technique).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingAreaRangeTechniqueDialog', false)
                commit('setAreaRangeTechnique', {})
                dispatch('getAreaRangeTechniques')

            // } else {
            //     commit('setAreaRangeTechniqueErrors', res.data.message)
            // }
            commit('setAreaRangeTechniquesLoading', false)
        }).catch(error => {
            commit('setAreaRangeTechniquesLoading', false)
            commit('setAreaRangeTechniqueErrorMessages', error.response.data.errors)
        })
    },
    deleteAreaRangeTechnique({commit, dispatch}, id) {
        commit('setAreaRangeTechniquesLoading', true)
        commit('setAreaRangeTechniqueErrors', [])
        commit('setAreaRangeTechniqueErrorMessages', {})

        axios.delete('/api/admin/area_range_techniques/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingAreaRangeTechniqueDialog', false)
                dispatch('getAreaRangeTechniques')
            // } else {
                // commit('setAreaRangeTechniqueErrors', [res.data.message])
            // }
            commit('setAreaRangeTechniquesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setAreaRangeTechniquesLoading', false)
            commit('setAreaRangeTechniqueErrorMessages', error.response.data.errors)
        })
    },
    editAreaRangeTechnique({commit, dispatch}, {id, area_range_technique}) {
        console.log(area_range_technique);
        commit('setAreaRangeTechniquesLoading', true)
        commit('setAreaRangeTechniqueErrorMessages', {})
        commit('setAreaRangeTechniqueErrors', [])
        axios.put('/api/admin/area_range_techniques/' + id, area_range_technique).then((res) => {
            // if (res.data.ok) {
                commit('setAreaRangeTechniquesLoading', false)
                dispatch('getAreaRangeTechniques')

            // } else {
            //     commit('setAreaRangeTechniqueErrors', res.data.message)
            // }
            commit('setEditingAreaRangeTechniqueDialog', false)
        }).catch(error => {
            commit('setAreaRangeTechniquesLoading', false)
            commit('setAreaRangeTechniqueErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setAreaRangeTechniques(state, area_range_techniques) {
        state.area_range_techniques = area_range_techniques
    },
    setAreaRangeTechniquesLoading(state, loading) {
        state.area_range_techniques_loading = loading
    },
    setDeletingAreaRangeTechniqueDialog(state, deleting_area_range_technique_dialog) {
        state.deleting_area_range_technique_dialog = deleting_area_range_technique_dialog
    },
    setCreatingAreaRangeTechniqueDialog(state, creating_area_range_technique_dialog) {
        state.creating_area_range_technique_dialog = creating_area_range_technique_dialog
    },
    setEditingAreaRangeTechniqueDialog(state, editing_area_range_technique_dialog) {
        state.editing_area_range_technique_dialog = editing_area_range_technique_dialog
    },
    setAreaRangeTechniqueErrors(state, area_range_technique_errors) {
        state.area_range_technique_errors = area_range_technique_errors
    },
    setAreaRangeTechnique(state, area_range_technique) {
        state.area_range_technique = area_range_technique
    },
    setAreaRangeTechniqueErrorMessages(state, area_range_technique_error_messages) {
        state.area_range_technique_error_messages = area_range_technique_error_messages
    },
    setPermissionItemAreaRangeTechniqueDialog(state, permission_item_area_range_technique_dialog) {
        state.permission_item_area_range_technique_dialog = permission_item_area_range_technique_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
