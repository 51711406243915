export default [
    {
        path: '/admin/area-ranges',
        name: 'area_ranges.index',
        component: () => import('../../pages/admin/AreaRange/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/area-ranges/create',
        name: 'area_ranges.create',
        component: () => import('../../pages/admin/AreaRange/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/area-ranges/:id/edit',
        name: 'area_ranges.edit',
        component: () => import('../../pages/admin/AreaRange/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
