export default [
  {
    path: "/admin/soil-cycle",
    name: "soil_cycle.index",
    component: () => import("../../pages/admin/SoilCycle/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-cycle/create",
    name: "soil_cycle.create",
    component: () => import("../../pages/admin/SoilCycle/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-cycle/:id/edit",
    name: "soil_cycle.edit",
    component: () => import("../../pages/admin/SoilCycle/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
