<template>
  <v-navigation-drawer app :value="temporary" dark :width="width" src="/img/bg.jpg"   height="100%" >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          AgroPRO Admin
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn elevation="0" fab x-small outlined @click="pinned = !pinned">
          <v-icon>{{ pinned ? 'mdi-pin-outline' : 'mdi-pin-off-outline' }}</v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
        :width="width"
        dense
        shaped
    >
      <v-list-item-group
      >
        <template v-for="item in items">
          <template v-if="item.subheader">
            <v-divider></v-divider>
            <v-subheader>{{ item.title  }}</v-subheader>
          </template>
          <v-list-item
              :key="item.route"
              link
              :to="item.route"
              :item="item"
              @mouseenter="changeWidth( item.title )"
              v-if="!item.children && !item.subheader"
              active-class="list-selected"
          >

            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-if="item.children"
              :value="item.children.some(child => child.route === $route.path)"
              :prepend-icon="item.icon"
              color="white"
              active-class="open"
              class="active-list"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(child, i) in item.children">
              <v-list-item
                  :key="i"
                  link
                  :to="child.route "
                  :item="child">
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>

      </v-list-item-group>
      <div class="py-10">
        <div class="my-10"></div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import navigators from "../../router/navigators";

export default {
  name: 'SideBar',

  data() {
    return {
      items: [],
      right: null,
      width: 300,
      pinned: true,
    }
  },
  watch: {
    '$route.name'(newName, oldName){
      newName = newName.split(".")
      oldName = oldName.split(".")
      if (oldName[1] === "index" && newName[1] === "index" )
        localStorage.setItem("page", 1)
    }
  },
  props: {},
  created() {
    document.onkeydown = evt => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        let name = this.$route.name.split(".")
        if (["edit", "create"].includes(name[1]))
          this.$router.push({name: name[0] + ".index"})
      }
    };
  },
  methods: {
    changeWidth(name) {
      if (!this.pinned)
        if (name.length > 30)
          this.width = name.length * 10
        else
          this.width = 300
    }
  },
  computed: {
    title() {
      return this.$store.getters.title
    },
    temporary: {
      set(val) {
        this.$store.commit('temporary', val)
      },
      get(val) {
        return this.$store.getters.temporary
      }
    }
  }
  ,
  mounted() {
    this.items = navigators
  }
}
</script>

<style>
.theme--dark.v-navigation-drawer {

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(#08705F, #15933e);
    border-radius: 50rem;
  }
}

.list-selected{
  background: linear-gradient(90deg, #08705F, #15933e);
}

.open{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 8px;
}

.active-list{
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  padding: 8px;
}

</style>