<template>
    <component :is="resolveLayoutVariant">
        <!--        <transition name="transition" mode="out-in" appear>-->
<!--      <keep-alive>-->
        <router-view></router-view>
<!--      </keep-alive>-->
        <!--        </transition>-->
    </component>

</template>

<script>

import LayoutBlank from '../layouts/LayoutBlank.vue'
import LayoutContent from '../layouts/LayoutContent.vue'
import LayoutContentForUser from '../layouts/LayoutContentForUser.vue'

export default {
    name: 'App',

    components: {
        LayoutBlank,
        LayoutContent,
        LayoutContentForUser,
    },

    computed: {
        title() {
            return this.$store.getters.title
        },
        resolveLayoutVariant() {
            var route = this.$route
            var token = localStorage.getItem('accessToken')
            var token2 = localStorage.getItem('token')
            var user_type = localStorage.getItem('userType')
          // if(route.meta.layout === 'content_for_user' || token && user_type === 'user') {
          //   if(this.$route.name !== 'user.my_fields') {
          //     this.$router.push({name: 'user.my_fields'})
          //   }
          // }

        //   console.log(token2);
          if (route.meta.layout === 'content' && token && user_type === "admin") return `layout-content`
          if (route.meta.layout === 'content_for_user' && token2 && user_type === "user") return `layout-content-for-user`
          if (route.meta.layout === 'blank' || !(token || token2)) return 'layout-blank'

            return null
        }

    },
    watch: {

    }

};
</script>
