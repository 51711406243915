<template>
    <v-app-bar
        app
    >
        <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>
      <v-btn
          @click="logout"
          color="secondary"
          depressed
          small
          class="ml-auto"
      >
        Chiqish
      </v-btn>
<!--        <v-btn-->
<!--            @click="onClick"-->
<!--            fab-->
<!--            small-->
<!--            icon-->
<!--            class="ml-auto"-->
<!--        >-->
<!--            <v-icon>{{ theme === 'light' ?  'mdi-weather-sunny' : 'mdi-weather-night'}}</v-icon>-->
<!--        </v-btn>-->

    </v-app-bar>
</template>

<script>

export default {
    name: "AppBar",
    data(){
        return {
        }
    },

    methods: {
        onClick() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        },
        changeDrawer(){
            this.temporary = !this.temporary
        },
      logout(){
        this.$store.dispatch('logout').then(() => {
          this.$router.push({name: 'login'});
        });
      }
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
      temporary:{
        set(val){
          this.$store.commit('temporary', val)
        },
        get(val){
          return this.$store.getters.temporary
        }
      }
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
