const state = {
    users: [],
    users_loading: false,
    deleting_user_dialog: false,
    creating_user_dialog: false,
    editing_user_dialog: false,
    permission_item_user_dialog: false,
    user_permissions: [],
    user_errors: [],
    user_error_messages: {}
}
const getters = {
    users: state => state.users,
    users_loading: state => state.users_loading,
    deleting_user_dialog: state => state.deleting_user_dialog,
    creating_user_dialog: state => state.creating_user_dialog,
    editing_user_dialog: state => state.editing_user_dialog,
    permission_item_user_dialog: state => state.permission_item_user_dialog,
    user_errors: state => state.user_errors,
    user_error_messages: state => state.user_error_messages,
    user_permissions: state => state.user_permissions
}
const actions = {

    getUsers({commit},) {
        commit('setUsersLoading', true)
        // axios.get('/api/users', {params}).then(response => {
        //     commit('setUsers', response.data)
        //     commit('setUsersLoading', false)
        // })
    },
    getAllUsers({commit}, params) {
        commit('setUsersLoading', true)
        axios.get('/api/users/all', {params}).then(response => {
            commit('setUsers', response.data)
            commit('setUsersLoading', false)
        })
    },

    getUserPermissions({commit}, id) {
        commit('setUsersLoading', true)
        axios.get('/api/users/' + id + "/permissions").then(response => {
            commit('setUserPermissions', response.data.data)
            commit('setUsersLoading', false)
        })
    },
    setUserPermissions({commit}, params) {
        commit('setUsersLoading', true)
        axios.post('/api/users/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemUserDialog', false)
            } else {
                commit('setUserErrors', [response.data.message])
            }
            commit('setUsersLoading', false)
        }).catch(error => {
            commit('setUsersLoading', false)
            commit('setUserErrorMessages', error.response.data.errors)
        })
    },
    getUser({commit}, id) {
        commit('setUsersLoading', true)
        axios.get('/api/users/' + id + "/show").then(response => {
            commit('setUserPermissions', response.data.data)
            commit('setUsersLoading', false)
        })
    },

    storeUser({commit, dispatch}, user) {
        commit('setUsersLoading', true)
        commit('setUserErrorMessages', {})
        commit('setUserErrors', [])

        axios.post('/api/users/store', user).then((res) => {
            if (res.data.ok) {
                commit('setCreatingUserDialog', false)
                commit('setUser', {})
                dispatch('getUsers')

            } else {
                commit('setUserErrors', res.data.message)
            }
            commit('setUsersLoading', false)
        }).catch(error => {
            commit('setUsersLoading', false)
            commit('setUserErrorMessages', error.response.data.errors)
        })
    },
    deleteUser({commit, dispatch}, id) {
        commit('setUsersLoading', true)
        commit('setUserErrors', [])
        commit('setUserErrorMessages', {})

        axios.delete('/api/users/' + id + "/delete").then((res) => {
            if (res.data.ok) {
                commit('setDeletingUserDialog', false)
                dispatch('getUsers')
            } else {
                commit('setUserErrors', [res.data.message])
            }
            commit('setUsersLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setUsersLoading', false)
            commit('setUserErrorMessages', error.response.data.errors)
        })
    },
    editUser({commit, dispatch}, user) {
        commit('setUsersLoading', true)
        commit('setUserErrorMessages', {})
        commit('setUserErrors', [])
        axios.put('/api/users/' + user.id + "/update", user).then((res) => {
            if (res.data.ok) {
                commit('setUsersLoading', false)
                commit('setUser', {})
                dispatch('getUsers')

            } else {
                commit('setUserErrors', res.data.message)
            }
            commit('setEditingUserDialog', false)
        }).catch(error => {
            commit('setUsersLoading', false)
            commit('setUserErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setUsers(state, users) {
        state.users = users
    },
    setUsersLoading(state, loading) {
        state.users_loading = loading
    },
    setDeletingUserDialog(state, deleting_user_dialog) {
        state.deleting_user_dialog = deleting_user_dialog
    },
    setCreatingUserDialog(state, creating_user_dialog) {
        state.creating_user_dialog = creating_user_dialog
    },
    setEditingUserDialog(state, editing_user_dialog) {
        state.editing_user_dialog = editing_user_dialog
    },
    setUserErrors(state, user_errors) {
        state.user_errors = user_errors
    },
    setUser(state, user) {
        state.user = user
    },
    setUserErrorMessages(state, user_error_messages) {
        state.user_error_messages = user_error_messages
    },
    setPermissionItemUserDialog(state, permission_item_user_dialog) {
        state.permission_item_user_dialog = permission_item_user_dialog
    },
    setUserPermissions(state, user_permissions) {
        state.user_permissions = user_permissions
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
