const state = {
  fertilizers: [],
  fertilizer_loading: false,
  deleting_fertilizer_dialog: false,
  creating_fertilizer_dialog: false,
  editing_fertilizer_dialog: false,
  fertilizer_permissions: [],
  fertilizer_errors: [],
  fertilizer_error_messages: {},
};
const getters = {
  fertilizers: (state) => state.fertilizers,
  fertilizer_loading: (state) => state.fertilizer_loading,
  deleting_fertilizer_dialog: (state) => state.deleting_fertilizer_dialog,
  creating_fertilizer_dialog: (state) => state.creating_fertilizer_dialog,
  editing_fertilizer_dialog: (state) => state.editing_fertilizer_dialog,
  fertilizer_errors: (state) => state.fertilizer_errors,
  fertilizer_error_messages: (state) => state.fertilizer_error_messages,
};
const actions = {
  getFertilizers({ commit }, params) {
    commit("setFertilizerLoading", true);
    axios.get("/api/admin/fertilizers", { params }).then((response) => {
      commit("setFertilizers", response.data);
      commit("setFertilizerLoading", false);
    });
  },
  deleteFertilizer({ commit, dispatch }, id) {
    commit("setFertilizerLoading", true);
    commit("setFertilizerErrors", []);
    commit("setFertilizerErrorMessages", {});

    axios
      .delete("/api/admin/fertilizers/" + id)
      .then((res) => {
        commit("setDeletingFertilizerDialog", false);
        dispatch("getFertilizers");
        commit("setFertilizerLoading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("setFertilizerLoading", false);
        commit("setFertilizerErrorMessages", error.response.data.errors);
      });
  },
  editFertilizer({ commit, dispatch }, { id, Fertilizer }) {
    commit("setFertilizerLoading", true);
    commit("setFertilizerErrorMessages", {});
    commit("setFertilizerErrors", []);
    axios
      .put("/api/admin/fertilizers/" + id, Fertilizer)
      .then((res) => {
        commit("setFertilizerLoading", false);
        dispatch("getFertilizers");
        commit("setEditingFertilizerDialog", false);
      })
      .catch((error) => {
        commit("setFertilizerLoading", false);
        commit("setFertilizerErrorMessages", error.response.data.errors);
      });
  },
};

const mutations = {
  setFertilizers(state, fertilizers) {
    state.fertilizers = fertilizers;
  },
  setFertilizerLoading(state, loading) {
    state.fertilizer_loading = loading;
  },
  setDeletingFertilizerDialog(state, deleting_fertilizer_dialog) {
    state.deleting_fertilizer_dialog = deleting_fertilizer_dialog;
  },
  setCreatingFertilizerDialog(state, creating_fertilizer_dialog) {
    state.creating_fertilizer_dialog = creating_fertilizer_dialog;
  },
  setEditingFertilizerDialog(state, editing_fertilizer_dialog) {
    state.editing_fertilizer_dialog = editing_fertilizer_dialog;
  },
  setFertilizerErrors(state, fertilizer_errors) {
    state.fertilizer_errors = fertilizer_errors;
  },
  setFertilizer(state, fertilizers) {
    state.fertilizers = fertilizers;
  },
  setFertilizerErrorMessages(state, fertilizer_error_messages) {
    state.fertilizer_error_messages = fertilizer_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
