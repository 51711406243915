export default [
    {
        path: '/admin/aggregates',
        name: 'aggregates.index',
        component: () => import('../../pages/admin/Aggregate/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregates/create',
        name: 'aggregates.create',
        component: () => import('../../pages/admin/Aggregate/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregates/:id/edit',
        name: 'aggregates.edit',
        component: () => import('../../pages/admin/Aggregate/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
