const state = {
  countries: [],
  country: {},
  country_loading: false,
  deleting_country_dialog: false,
  creating_country_dialog: false,
  country_errors: [],
  country_error_messages: {},
};

const getters = {
  countries: (state) => state.countries,
  country: (state) => state.country,
  country_loading: (state) => state.country_loading,
  deleting_country_dialog: (state) => state.deleting_country_dialog,
  creating_country_dialog: (state) => state.creating_country_dialog,
  editing_country_dialog: (state) => state.editing_country_dialog,
  country_errors: (state) => state.country_errors,
  country_error_messages: (state) => state.country_error_messages,
};
const actions = {
  getCountries({ commit }) {
    commit("country_loading", true);
    axios.get("/api/admin/countries").then((response) => {
      commit("countries", response.data);
      commit("country_loading", false);
    });
  },
  getCountry({ commit }, id) {
    commit("country_loading", true);
    axios.get("/api/admin/countries/" + id).then((response) => {
      commit("country", response.data);
      commit("country_loading", false);
    });
  },

  deleteCountry({ commit, dispatch }, id) {
    commit("country_loading", true);
    commit("country_errors", []);
    commit("country_error_messages", {});

    axios
      .delete("/api/admin/countries/" + id)
      .then(() => {
        commit("deleting_country_dialog", false);
        dispatch("getCountries");
        commit("country_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("country_loading", false);
        commit("country_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  countries(state, countries) {
    state.countries = countries;
  },
  country_loading(state, country_loading) {
    state.country_loading = country_loading;
  },
  deleting_country_dialog(state, deleting_country_dialog) {
    state.deleting_country_dialog = deleting_country_dialog;
  },
  creating_country_dialog(state, creating_country_dialog) {
    state.creating_country_dialog = creating_country_dialog;
  },
  editing_country_dialog(state, editing_country_dialog) {
    state.editing_country_dialog = editing_country_dialog;
  },
  country_errors(state, country_errors) {
    state.country_errors = country_errors;
  },
  country(state, country) {
    state.country = country;
  },
  country_error_messages(state, country_error_messages) {
    state.country_error_messages = country_error_messages;
  },
  permission_item_country_dialog(state, permission_item_country_dialog) {
    state.permission_item_country_dialog = permission_item_country_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
