const state = {
  technique_brands: [],
  technique_brand: {
    names: {},
    descriptions: {},
  },
  technique_brands_loading: false,
  deleting_technique_brand_dialog: false,
  creating_technique_brand_dialog: false,
  editing_technique_brand_dialog: false,
  technique_brand_permissions: [],
  technique_brand_errors: [],
  technique_brand_error_messages: {},
};
const getters = {
  technique_brands: (state) => state.technique_brands,
  technique_brand: (state) => state.technique_brand,
  technique_brands_loading: (state) => state.technique_brands_loading,
  deleting_technique_brand_dialog: (state) =>
    state.deleting_technique_brand_dialog,
  creating_technique_brand_dialog: (state) =>
    state.creating_technique_brand_dialog,
  editing_technique_brand_dialog: (state) =>
    state.editing_technique_brand_dialog,
  permission_item_technique_brand_dialog: (state) =>
    state.permission_item_technique_brand_dialog,
  technique_brand_errors: (state) => state.technique_brand_errors,
  technique_brand_error_messages: (state) =>
    state.technique_brand_error_messages,
};
const actions = {
  getTechniqueBrands({ commit }) {
    commit("technique_brands_loading", true);
    axios.get("/api/admin/brands").then((response) => {
      commit("technique_brands", response.data);
      commit("technique_brands_loading", false);
    });
  },
  setTechniqueBrandPermissions({ commit }, params) {
    commit("technique_brands_loading", true);
    axios
      .post("/api/technique_brands/" + params.id + "/permissions", params)
      .then((response) => {
        if (response.data.ok) {
          commit("permission_item_technique_brand_dialog", false);
        } else {
          commit("technique_brand_errors", [response.data.message]);
        }
        commit("technique_brands_loading", false);
      })
      .catch((error) => {
        commit("technique_brands_loading", false);
        commit("technique_brand_error_messages", error.response.data.errors);
      });
  },
  getTechniqueBrand({ commit }, id) {
    commit("technique_brands_loading", true);
    axios.get("/api/admin/brands/" + id).then((response) => {
      commit("technique_brand", response.data);
      commit("technique_brands_loading", false);
    });
  },

  storeTechniqueBrand({ commit, dispatch }, technique_brand) {
    commit("technique_brands_loading", true);
    commit("technique_brand_error_messages", {});
    commit("technique_brand_errors", []);

    axios
      .post("/api/admin/brands", technique_brand)
      .then((res) => {
        // if (res.data.ok) {
        commit("creating_technique_brand_dialog", false);
        commit("technique_brand", {});
        dispatch("getTechniqueBrands");

        // } else {
        //     commit('technique_brand_errors', res.data.message)
        // }
        commit("technique_brands_loading", false);
      })
      .catch((error) => {
        commit("technique_brands_loading", false);
        commit("technique_brand_error_messages", error.response.data.errors);
      });
  },
  deleteTechniqueBrand({ commit, dispatch }, id) {
    commit("technique_brands_loading", true);
    commit("technique_brand_errors", []);
    commit("technique_brand_error_messages", {});

    axios
      .delete("/api/admin/brands/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("deleting_technique_brand_dialog", false);
        dispatch("getTechniqueBrands");
        // } else {
        // commit('technique_brand_errors', [res.data.message])
        // }
        commit("technique_brands_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("technique_brands_loading", false);
        commit("technique_brand_error_messages", error.response.data.errors);
      });
  },
  editTechniqueBrand({ commit, dispatch }, { id, technique_brand }) {
    commit("technique_brands_loading", true);
    commit("technique_brand_error_messages", {});
    commit("technique_brand_errors", []);
    axios
      .put("/api/admin/brands/" + id, technique_brand)
      .then((res) => {
        // if (res.data.ok) {
        commit("technique_brands_loading", false);
        dispatch("getTechniqueBrands");

        // } else {
        //     commit('technique_brand_errors', res.data.message)
        // }
        commit("editing_technique_brand_dialog", false);
      })
      .catch((error) => {
        commit("technique_brands_loading", false);
        commit("technique_brand_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  technique_brands(state, technique_brands) {
    state.technique_brands = technique_brands;
  },
  technique_brands_loading(state, technique_brands_loading) {
    state.technique_brands_loading = technique_brands_loading;
  },
  deleting_technique_brand_dialog(state, deleting_technique_brand_dialog) {
    state.deleting_technique_brand_dialog = deleting_technique_brand_dialog;
  },
  creating_technique_brand_dialog(state, creating_technique_brand_dialog) {
    state.creating_technique_brand_dialog = creating_technique_brand_dialog;
  },
  editing_technique_brand_dialog(state, editing_technique_brand_dialog) {
    state.editing_technique_brand_dialog = editing_technique_brand_dialog;
  },
  technique_brand_errors(state, technique_brand_errors) {
    state.technique_brand_errors = technique_brand_errors;
  },
  technique_brand(state, technique_brand) {
    state.technique_brand = technique_brand;
  },
  technique_brand_error_messages(state, technique_brand_error_messages) {
    state.technique_brand_error_messages = technique_brand_error_messages;
  },
  permission_item_technique_brand_dialog(
    state,
    permission_item_technique_brand_dialog
  ) {
    state.permission_item_technique_brand_dialog =
      permission_item_technique_brand_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
