
const state = {
    clusters: [],
    cluster: {
        names: {},
        descriptions: {},
    },
    clusters_loading: false,
    deleting_cluster_dialog: false,
    creating_cluster_dialog: false,
    editing_cluster_dialog: false,
    cluster_permissions: [],
    cluster_errors: [],
    cluster_error_messages: {}
}
const getters = {
    clusters: state => state.clusters,
    cluster: state => state.cluster,
    clusters_loading: state => state.clusters_loading,
    deleting_cluster_dialog: state => state.deleting_cluster_dialog,
    creating_cluster_dialog: state => state.creating_cluster_dialog,
    editing_cluster_dialog: state => state.editing_cluster_dialog,
    permission_item_cluster_dialog: state => state.permission_item_cluster_dialog,
    cluster_errors: state => state.cluster_errors,
    cluster_error_messages: state => state.cluster_error_messages,
}
const actions = {

    getClusters({commit},) {
        commit('setClustersLoading', true)
        axios.get('/api/admin/clusters', ).then(response => {
            commit('setClusters', response.data)
            commit('setClustersLoading', false)
        })
    },
    getAllClusters({commit}, params) {
        commit('setClustersLoading', true)
        axios.get('/api/clusters/all', {params}).then(response => {
            commit('setClusters', response.data)
            commit('setClustersLoading', false)
        })
    },
    setClusterPermissions({commit}, params) {
        commit('setClustersLoading', true)
        axios.post('/api/clusters/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemClusterDialog', false)
            } else {
                commit('setClusterErrors', [response.data.message])
            }
            commit('setClustersLoading', false)
        }).catch(error => {
            commit('setClustersLoading', false)
            commit('setClusterErrorMessages', error.response.data.errors)
        })
    },
    getCluster({commit}, id) {
        commit('setClustersLoading', true)
        axios.get('/api/admin/clusters/' + id).then(response => {
            commit('setCluster', response.data)
            commit('setClustersLoading', false)
        })
    },

    storeCluster({commit, dispatch}, cluster) {
        commit('setClustersLoading', true)
        commit('setClusterErrorMessages', {})
        commit('setClusterErrors', [])

        axios.post('/api/admin/clusters', cluster).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingClusterDialog', false)
                commit('setCluster', {})
                dispatch('getClusters')

            // } else {
            //     commit('setClusterErrors', res.data.message)
            // }
            commit('setClustersLoading', false)
        }).catch(error => {
            commit('setClustersLoading', false)
            commit('setClusterErrorMessages', error.response.data.errors)
        })
    },
    deleteCluster({commit, dispatch}, id) {
        commit('setClustersLoading', true)
        commit('setClusterErrors', [])
        commit('setClusterErrorMessages', {})

        axios.delete('/api/admin/clusters/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingClusterDialog', false)
                dispatch('getClusters')
            // } else {
                // commit('setClusterErrors', [res.data.message])
            // }
            commit('setClustersLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setClustersLoading', false)
            commit('setClusterErrorMessages', error.response.data.errors)
        })
    },
    editCluster({commit, dispatch}, {id, cluster}) {
        commit('setClustersLoading', true)
        commit('setClusterErrorMessages', {})
        commit('setClusterErrors', [])
        axios.put('/api/admin/clusters/' + id, cluster).then((res) => {
            // if (res.data.ok) {
                commit('setClustersLoading', false)
                dispatch('getClusters')

            // } else {
            //     commit('setClusterErrors', res.data.message)
            // }
            commit('setEditingClusterDialog', false)
        }).catch(error => {
            commit('setClustersLoading', false)
            commit('setClusterErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setClusters(state, clusters) {
        state.clusters = clusters
    },
    setClustersLoading(state, loading) {
        state.clusters_loading = loading
    },
    setDeletingClusterDialog(state, deleting_cluster_dialog) {
        state.deleting_cluster_dialog = deleting_cluster_dialog
    },
    setCreatingClusterDialog(state, creating_cluster_dialog) {
        state.creating_cluster_dialog = creating_cluster_dialog
    },
    setEditingClusterDialog(state, editing_cluster_dialog) {
        state.editing_cluster_dialog = editing_cluster_dialog
    },
    setClusterErrors(state, cluster_errors) {
        state.cluster_errors = cluster_errors
    },
    setCluster(state, cluster) {
        state.cluster = cluster
    },
    setClusterErrorMessages(state, cluster_error_messages) {
        state.cluster_error_messages = cluster_error_messages
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
