export default [
    {
        path: '/admin/users',
        name: 'users',
        component: () => import('../../pages/admin/User/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
