const state = {
  recommended_techniques_to_events: [],
  recommended_techniques_to_event: {
    names: {},
    descriptions: {},
  },
  recommended_techniques_to_events_loading: false,
  deleting_recommended_techniques_to_event_dialog: false,
  creating_recommended_techniques_to_event_dialog: false,
  editing_recommended_techniques_to_event_dialog: false,
  recommended_techniques_to_event_permissions: [],
  recommended_techniques_to_event_errors: null,
  recommended_techniques_to_event_error_messages: {},
};
const getters = {
  recommended_techniques_to_events: (state) =>
    state.recommended_techniques_to_events,
  recommended_techniques_to_event: (state) =>
    state.recommended_techniques_to_event,
  recommended_techniques_to_events_loading: (state) =>
    state.recommended_techniques_to_events_loading,
  deleting_recommended_techniques_to_event_dialog: (state) =>
    state.deleting_recommended_techniques_to_event_dialog,
  creating_recommended_techniques_to_event_dialog: (state) =>
    state.creating_recommended_techniques_to_event_dialog,
  editing_recommended_techniques_to_event_dialog: (state) =>
    state.editing_recommended_techniques_to_event_dialog,
  permission_item_recommended_techniques_to_event_dialog: (state) =>
    state.permission_item_recommended_techniques_to_event_dialog,
  recommended_techniques_to_event_errors: (state) =>
    state.recommended_techniques_to_event_errors,
  recommended_techniques_to_event_error_messages: (state) =>
    state.recommended_techniques_to_event_error_messages,
};
const actions = {
  getRecommendedTechniquesToEvents({ commit }, params) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    axios
      .get("/api/admin/recommended_techniques_to_events", { params })
      .then((response) => {
        commit("setRecommendedTechniquesToEvents", response.data);
        commit("setRecommendedTechniquesToEventsLoading", false);
      });
  },
  getAllRecommendedTechniquesToEvents({ commit }, params) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    axios
      .get("/api/recommended_techniques_to_events/all", { params })
      .then((response) => {
        commit("setRecommendedTechniquesToEvents", response.data);
        commit("setRecommendedTechniquesToEventsLoading", false);
      });
  },
  setRecommendedTechniquesToEventPermissions({ commit }, params) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    axios
      .post(
        "/api/recommended_techniques_to_events/" + params.id + "/permissions",
        params
      )
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemRecommendedTechniquesToEventDialog", false);
        } else {
          commit("setRecommendedTechniquesToEventErrors", [
            response.data.message,
          ]);
        }
        commit("setRecommendedTechniquesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedTechniquesToEventsLoading", false);
        commit(
          "setRecommendedTechniquesToEventErrorMessages",
          error.response.data.errors
        );
      });
  },
  getRecommendedTechniquesToEvent({ commit }, id) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    axios
      .get("/api/admin/recommended_techniques_to_events/" + id)
      .then((response) => {
        commit("setRecommendedTechniquesToEvent", response.data);
        commit("setRecommendedTechniquesToEventsLoading", false);
      });
  },

  storeRecommendedTechniquesToEvent(
    { commit, dispatch },
    recommended_techniques_to_event
  ) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    commit("setRecommendedTechniquesToEventErrorMessages", {});
    commit("setRecommendedTechniquesToEventErrors", null);

    axios
      .post(
        "/api/admin/recommended_techniques_to_events",
        recommended_techniques_to_event
      )
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingRecommendedTechniquesToEventDialog", false);
        // commit('setRecommendedTechniquesToEvent', {})
        dispatch("getRecommendedTechniquesToEvents");

        // } else {
        //     commit('setRecommendedTechniquesToEventErrors', res.data.message)
        // }
        commit("setRecommendedTechniquesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedTechniquesToEventsLoading", false);
        commit(
          "setRecommendedTechniquesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedTechniquesToEventErrors",
          error.response.data.message
        );
      });
  },
  deleteRecommendedTechniquesToEvent({ commit, dispatch }, id) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    commit("setRecommendedTechniquesToEventErrors", null);
    commit("setRecommendedTechniquesToEventErrorMessages", {});

    axios
      .delete("/api/admin/recommended_techniques_to_events/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingRecommendedTechniquesToEventDialog", false);
        dispatch("getRecommendedTechniquesToEvents");
        // } else {
        // commit('setRecommendedTechniquesToEventErrors', [res.data.message])
        // }
        commit("setRecommendedTechniquesToEventsLoading", false);
      })
      .catch((error) => {
        commit("setRecommendedTechniquesToEventsLoading", false);
        commit(
          "setRecommendedTechniquesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedTechniquesToEventErrors",
          error.response.data.message
        );
      });
  },
  editRecommendedTechniquesToEvent({ commit, dispatch }, { id, item }) {
    commit("setRecommendedTechniquesToEventsLoading", true);
    commit("setRecommendedTechniquesToEventErrorMessages", {});
    commit("setRecommendedTechniquesToEventErrors", null);
    axios
      .put("/api/admin/recommended_techniques_to_events/" + id, item)
      .then((res) => {
        // if (res.data.ok) {
        commit("setRecommendedTechniquesToEventsLoading", false);
        dispatch("getRecommendedTechniquesToEvents");

        // } else {
        //     commit('setRecommendedTechniquesToEventErrors', res.data.message)
        // }
        commit("setEditingRecommendedTechniquesToEventDialog", false);
      })
      .catch((error) => {
        commit("setRecommendedTechniquesToEventsLoading", false);
        commit(
          "setRecommendedTechniquesToEventErrorMessages",
          error.response.data.errors
        );
        commit(
          "setRecommendedTechniquesToEventErrors",
          error.response.data.message
        );
      });
  },
};

const mutations = {
  setRecommendedTechniquesToEvents(state, recommended_techniques_to_events) {
    state.recommended_techniques_to_events = recommended_techniques_to_events;
  },
  setRecommendedTechniquesToEventsLoading(state, loading) {
    state.recommended_techniques_to_events_loading = loading;
  },
  setDeletingRecommendedTechniquesToEventDialog(
    state,
    deleting_recommended_techniques_to_event_dialog
  ) {
    state.deleting_recommended_techniques_to_event_dialog =
      deleting_recommended_techniques_to_event_dialog;
  },
  setCreatingRecommendedTechniquesToEventDialog(
    state,
    creating_recommended_techniques_to_event_dialog
  ) {
    state.creating_recommended_techniques_to_event_dialog =
      creating_recommended_techniques_to_event_dialog;
  },
  setEditingRecommendedTechniquesToEventDialog(
    state,
    editing_recommended_techniques_to_event_dialog
  ) {
    state.editing_recommended_techniques_to_event_dialog =
      editing_recommended_techniques_to_event_dialog;
  },
  setRecommendedTechniquesToEventErrors(
    state,
    recommended_techniques_to_event_errors
  ) {
    state.recommended_techniques_to_event_errors =
      recommended_techniques_to_event_errors;
  },
  setRecommendedTechniquesToEvent(state, recommended_techniques_to_event) {
    state.recommended_techniques_to_event = recommended_techniques_to_event;
  },
  setRecommendedTechniquesToEventErrorMessages(
    state,
    recommended_techniques_to_event_error_messages
  ) {
    state.recommended_techniques_to_event_error_messages =
      recommended_techniques_to_event_error_messages;
  },
  setPermissionItemRecommendedTechniquesToEventDialog(
    state,
    permission_item_recommended_techniques_to_event_dialog
  ) {
    state.permission_item_recommended_techniques_to_event_dialog =
      permission_item_recommended_techniques_to_event_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
