export default [
    {
        path: '/admin/techniques',
        name: 'techniques.index',
        component: () => import('../../pages/admin/Technique/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/techniques/create',
        name: 'techniques.create',
        component: () => import('../../pages/admin/Technique/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/techniques/:id/edit',
        name: 'techniques.edit',
        component: () => import('../../pages/admin/Technique/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
