const state = {
    techniques: [],
    technique: {
        names: {},
        descriptions: {},
    },
    techniques_loading: false,
    deleting_technique_dialog: false,
    creating_technique_dialog: false,
    editing_technique_dialog: false,
    technique_permissions: [],
    technique_errors: [],
    technique_error_messages: {}
}
const getters = {
    techniques: state => state.techniques,
    technique: state => state.technique,
    techniques_loading: state => state.techniques_loading,
    deleting_technique_dialog: state => state.deleting_technique_dialog,
    creating_technique_dialog: state => state.creating_technique_dialog,
    editing_technique_dialog: state => state.editing_technique_dialog,
    permission_item_technique_dialog: state => state.permission_item_technique_dialog,
    technique_errors: state => state.technique_errors,
    technique_error_messages: state => state.technique_error_messages,
}
const actions = {

    getTechniques({commit}, params) {
        commit('setTechniquesLoading', true)
        axios.get('/api/admin/techniques', {params}).then(response => {
            commit('setTechniques', response.data)
            commit('setTechniquesLoading', false)
        })
    },
    getAllTechniques({commit}, params) {
        commit('setTechniquesLoading', true)
        axios.get('/api/techniques/all', {params}).then(response => {
            commit('setTechniques', response.data)
            commit('setTechniquesLoading', false)
        })
    },
    setTechniquePermissions({commit}, params) {
        commit('setTechniquesLoading', true)
        axios.post('/api/techniques/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemTechniqueDialog', false)
            } else {
                commit('setTechniqueErrors', [response.data.message])
            }
            commit('setTechniquesLoading', false)
        }).catch(error => {
            commit('setTechniquesLoading', false)
            commit('setTechniqueErrorMessages', error.response.data.errors)
        })
    },
    getTechnique({commit}, id) {
        commit('setTechniquesLoading', true)
        axios.get('/api/admin/techniques/one/' + id).then(response => {
            commit('setTechnique', response.data)
            commit('setTechniquesLoading', false)
        })
    },

    storeTechnique({commit, dispatch}, technique) {
        commit('setTechniquesLoading', true)
        commit('setTechniqueErrorMessages', {})
        commit('setTechniqueErrors', [])

        axios.post('/api/admin/techniques', technique).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingTechniqueDialog', false)
                commit('setTechnique', {})
                dispatch('getTechniques')

            // } else {
            //     commit('setTechniqueErrors', res.data.message)
            // }
            commit('setTechniquesLoading', false)
        }).catch(error => {
            commit('setTechniquesLoading', false)
            commit('setTechniqueErrorMessages', error.response.data.errors)
        })
    },
    deleteTechnique({commit, dispatch}, id) {
        commit('setTechniquesLoading', true)
        commit('setTechniqueErrors', [])
        commit('setTechniqueErrorMessages', {})

        axios.delete('/api/admin/techniques/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingTechniqueDialog', false)
                dispatch('getTechniques')
            // } else {
                // commit('setTechniqueErrors', [res.data.message])
            // }
            commit('setTechniquesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setTechniquesLoading', false)
            commit('setTechniqueErrorMessages', error.response.data.errors)
        })
    },
    editTechnique({commit, dispatch}, {id, technique}) {
        commit('setTechniquesLoading', true)
        commit('setTechniqueErrorMessages', {})
        commit('setTechniqueErrors', [])
        axios.put('/api/admin/techniques/' + id, technique).then((res) => {
            // if (res.data.ok) {
                commit('setTechniquesLoading', false)
                dispatch('getTechniques')

            // } else {
            //     commit('setTechniqueErrors', res.data.message)
            // }
            commit('setEditingTechniqueDialog', false)
        }).catch(error => {
            commit('setTechniquesLoading', false)
            commit('setTechniqueErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setTechniques(state, techniques) {
        state.techniques = techniques
    },
    setTechniquesLoading(state, loading) {
        state.techniques_loading = loading
    },
    setDeletingTechniqueDialog(state, deleting_technique_dialog) {
        state.deleting_technique_dialog = deleting_technique_dialog
    },
    setCreatingTechniqueDialog(state, creating_technique_dialog) {
        state.creating_technique_dialog = creating_technique_dialog
    },
    setEditingTechniqueDialog(state, editing_technique_dialog) {
        state.editing_technique_dialog = editing_technique_dialog
    },
    setTechniqueErrors(state, technique_errors) {
        state.technique_errors = technique_errors
    },
    setTechnique(state, technique) {
        state.technique = technique
    },
    setTechniqueErrorMessages(state, technique_error_messages) {
        state.technique_error_messages = technique_error_messages
    },
    setPermissionItemTechniqueDialog(state, permission_item_technique_dialog) {
        state.permission_item_technique_dialog = permission_item_technique_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
