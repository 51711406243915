const navigators = [
    {
        title: "Менинг далаларим",
        icon: "mdi-land-fields",
        route: "/",
    },
];


export default navigators
