export default [
    {
        path: '/admin/locations',
        name: 'locations.index',
        component: () => import('../../pages/admin/Location/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/locations/create',
        name: 'locations.create',
        component: () => import('../../pages/admin/Location/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/locations/:id/edit',
        name: 'locations.edit',
        component: () => import('../../pages/admin/Location/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
