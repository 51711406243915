const state = {
    calculation_categories: [],
    calculation_category: {
        names: {},
        descriptions: {},
    },
    calculation_categories_loading: false,
    deleting_calculation_category_dialog: false,
    creating_calculation_category_dialog: false,
    editing_calculation_category_dialog: false,
    calculation_category_permissions: [],
    calculation_category_errors: [],
    calculation_category_error_messages: {}
}
const getters = {
    calculation_categories: state => state.calculation_categories,
    calculation_category: state => state.calculation_category,
    calculation_categories_loading: state => state.calculation_categories_loading,
    deleting_calculation_category_dialog: state => state.deleting_calculation_category_dialog,
    creating_calculation_category_dialog: state => state.creating_calculation_category_dialog,
    editing_calculation_category_dialog: state => state.editing_calculation_category_dialog,
    permission_item_calculation_category_dialog: state => state.permission_item_calculation_category_dialog,
    calculation_category_errors: state => state.calculation_category_errors,
    calculation_category_error_messages: state => state.calculation_category_error_messages,
}
const actions = {

    getCalculationCategories({commit},) {
        commit('setCalculationCategoriesLoading', true)
        axios.get('/api/admin/calculation_categories', ).then(response => {
            commit('setCalculationCategories', response.data)
            commit('setCalculationCategoriesLoading', false)
        })
    },
    getAllCalculationCategories({commit}, params) {
        commit('setCalculationCategoriesLoading', true)
        axios.get('/api/calculation_categories/all', {params}).then(response => {
            commit('setCalculationCategories', response.data)
            commit('setCalculationCategoriesLoading', false)
        })
    },
    setCalculationCategoryPermissions({commit}, params) {
        commit('setCalculationCategoriesLoading', true)
        axios.post('/api/calculation_categories/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemCalculationCategoryDialog', false)
            } else {
                commit('setCalculationCategoryErrors', [response.data.message])
            }
            commit('setCalculationCategoriesLoading', false)
        }).catch(error => {
            commit('setCalculationCategoriesLoading', false)
            commit('setCalculationCategoryErrorMessages', error.response.data.errors)
        })
    },
    getCalculationCategory({commit}, id) {
        commit('setCalculationCategoriesLoading', true)
        axios.get('/api/admin/calculation_categories/' + id).then(response => {
            commit('setCalculationCategory', response.data)
            commit('setCalculationCategoriesLoading', false)
        })
    },

    storeCalculationCategory({commit, dispatch}, calculation_category) {
        commit('setCalculationCategoriesLoading', true)
        commit('setCalculationCategoryErrorMessages', {})
        commit('setCalculationCategoryErrors', [])

        axios.post('/api/admin/calculation_categories', calculation_category).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingCalculationCategoryDialog', false)
                commit('setCalculationCategory', {})
                dispatch('getCalculationCategories')

            // } else {
            //     commit('setCalculationCategoryErrors', res.data.message)
            // }
            commit('setCalculationCategoriesLoading', false)
        }).catch(error => {
            commit('setCalculationCategoriesLoading', false)
            commit('setCalculationCategoryErrorMessages', error.response.data.errors)
        })
    },
    deleteCalculationCategory({commit, dispatch}, id) {
        commit('setCalculationCategoriesLoading', true)
        commit('setCalculationCategoryErrors', [])
        commit('setCalculationCategoryErrorMessages', {})

        axios.delete('/api/admin/calculation_categories/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingCalculationCategoryDialog', false)
                dispatch('getCalculationCategories')
            // } else {
                // commit('setCalculationCategoryErrors', [res.data.message])
            // }
            commit('setCalculationCategoriesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setCalculationCategoriesLoading', false)
            commit('setCalculationCategoryErrorMessages', error.response.data.errors)
        })
    },
    editCalculationCategory({commit, dispatch}, {id, calculation_category}) {
        commit('setCalculationCategoriesLoading', true)
        commit('setCalculationCategoryErrorMessages', {})
        commit('setCalculationCategoryErrors', [])
        axios.put('/api/admin/calculation_categories/' + id, calculation_category).then((res) => {
            // if (res.data.ok) {
                commit('setCalculationCategoriesLoading', false)
                dispatch('getCalculationCategories')

            // } else {
            //     commit('setCalculationCategoryErrors', res.data.message)
            // }
            commit('setEditingCalculationCategoryDialog', false)
        }).catch(error => {
            commit('setCalculationCategoriesLoading', false)
            commit('setCalculationCategoryErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setCalculationCategories(state, calculation_categories) {
        state.calculation_categories = calculation_categories
    },
    setCalculationCategoriesLoading(state, loading) {
        state.calculation_categories_loading = loading
    },
    setDeletingCalculationCategoryDialog(state, deleting_calculation_category_dialog) {
        state.deleting_calculation_category_dialog = deleting_calculation_category_dialog
    },
    setCreatingCalculationCategoryDialog(state, creating_calculation_category_dialog) {
        state.creating_calculation_category_dialog = creating_calculation_category_dialog
    },
    setEditingCalculationCategoryDialog(state, editing_calculation_category_dialog) {
        state.editing_calculation_category_dialog = editing_calculation_category_dialog
    },
    setCalculationCategoryErrors(state, calculation_category_errors) {
        state.calculation_category_errors = calculation_category_errors
    },
    setCalculationCategory(state, calculation_category) {
        state.calculation_category = calculation_category
    },
    setCalculationCategoryErrorMessages(state, calculation_category_error_messages) {
        state.calculation_category_error_messages = calculation_category_error_messages
    },
    setPermissionItemCalculationCategoryDialog(state, permission_item_calculation_category_dialog) {
        state.permission_item_calculation_category_dialog = permission_item_calculation_category_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
