const state = {
    secondary_periods: [],
    secondary_period: {
        names: {},
        descriptions: {},
    },
    secondary_periods_loading: false,
    deleting_secondary_period_dialog: false,
    creating_secondary_period_dialog: false,
    editing_secondary_period_dialog: false,
    secondary_period_permissions: [],
    secondary_period_errors: [],
    secondary_period_error_messages: {}
}
const getters = {
    secondary_periods: state => state.secondary_periods,
    secondary_period: state => state.secondary_period,
    secondary_periods_loading: state => state.secondary_periods_loading,
    deleting_secondary_period_dialog: state => state.deleting_secondary_period_dialog,
    creating_secondary_period_dialog: state => state.creating_secondary_period_dialog,
    editing_secondary_period_dialog: state => state.editing_secondary_period_dialog,
    permission_item_secondary_period_dialog: state => state.permission_item_secondary_period_dialog,
    secondary_period_errors: state => state.secondary_period_errors,
    secondary_period_error_messages: state => state.secondary_period_error_messages,
}
const actions = {

    getSecondaryPeriods({commit},) {
        commit('setSecondaryPeriodsLoading', true)
        axios.get('/api/admin/secondary_periods', ).then(response => {
            commit('setSecondaryPeriods', response.data)
            commit('setSecondaryPeriodsLoading', false)
        })
    },
    getAllSecondaryPeriods({commit}, params) {
        commit('setSecondaryPeriodsLoading', true)
        axios.get('/api/secondary_periods/all', {params}).then(response => {
            commit('setSecondaryPeriods', response.data)
            commit('setSecondaryPeriodsLoading', false)
        })
    },
    setSecondaryPeriodPermissions({commit}, params) {
        commit('setSecondaryPeriodsLoading', true)
        axios.post('/api/secondary_periods/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemSecondaryPeriodDialog', false)
            } else {
                commit('setSecondaryPeriodErrors', [response.data.message])
            }
            commit('setSecondaryPeriodsLoading', false)
        }).catch(error => {
            commit('setSecondaryPeriodsLoading', false)
            commit('setSecondaryPeriodErrorMessages', error.response.data.errors)
        })
    },
    getSecondaryPeriod({commit}, id) {
        commit('setSecondaryPeriodsLoading', true)
        axios.get('/api/admin/secondary_periods/' + id).then(response => {
            commit('setSecondaryPeriod', response.data)
            commit('setSecondaryPeriodsLoading', false)
        })
    },

    storeSecondaryPeriod({commit, dispatch}, secondary_period) {
        commit('setSecondaryPeriodsLoading', true)
        commit('setSecondaryPeriodErrorMessages', {})
        commit('setSecondaryPeriodErrors', [])

        axios.post('/api/admin/secondary_periods', secondary_period).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingSecondaryPeriodDialog', false)
                // commit('setSecondaryPeriod', {})
                dispatch('getSecondaryPeriods')

            // } else {
            //     commit('setSecondaryPeriodErrors', res.data.message)
            // }
            commit('setSecondaryPeriodsLoading', false)
        }).catch(error => {
            commit('setSecondaryPeriodsLoading', false)
            commit('setSecondaryPeriodErrorMessages', error.response.data.errors)
        })
    },
    deleteSecondaryPeriod({commit, dispatch}, id) {
        commit('setSecondaryPeriodsLoading', true)
        commit('setSecondaryPeriodErrors', [])
        commit('setSecondaryPeriodErrorMessages', {})

        axios.delete('/api/admin/secondary_periods/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingSecondaryPeriodDialog', false)
                dispatch('getSecondaryPeriods')
            // } else {
                // commit('setSecondaryPeriodErrors', [res.data.message])
            // }
            commit('setSecondaryPeriodsLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setSecondaryPeriodsLoading', false)
            commit('setSecondaryPeriodErrorMessages', error.response.data.errors)
        })
    },
    editSecondaryPeriod({commit, dispatch}, {id, secondary_period}) {
        commit('setSecondaryPeriodsLoading', true)
        commit('setSecondaryPeriodErrorMessages', {})
        commit('setSecondaryPeriodErrors', [])
        axios.put('/api/admin/secondary_periods/' + id, secondary_period).then((res) => {
            // if (res.data.ok) {
                commit('setSecondaryPeriodsLoading', false)
                dispatch('getSecondaryPeriods')

            // } else {
            //     commit('setSecondaryPeriodErrors', res.data.message)
            // }
            commit('setEditingSecondaryPeriodDialog', false)
        }).catch(error => {
            commit('setSecondaryPeriodsLoading', false)
            commit('setSecondaryPeriodErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setSecondaryPeriods(state, secondary_periods) {
        state.secondary_periods = secondary_periods
    },
    setSecondaryPeriodsLoading(state, loading) {
        state.secondary_periods_loading = loading
    },
    setDeletingSecondaryPeriodDialog(state, deleting_secondary_period_dialog) {
        state.deleting_secondary_period_dialog = deleting_secondary_period_dialog
    },
    setCreatingSecondaryPeriodDialog(state, creating_secondary_period_dialog) {
        state.creating_secondary_period_dialog = creating_secondary_period_dialog
    },
    setEditingSecondaryPeriodDialog(state, editing_secondary_period_dialog) {
        state.editing_secondary_period_dialog = editing_secondary_period_dialog
    },
    setSecondaryPeriodErrors(state, secondary_period_errors) {
        state.secondary_period_errors = secondary_period_errors
    },
    setSecondaryPeriod(state, secondary_period) {
        state.secondary_period = secondary_period
    },
    setSecondaryPeriodErrorMessages(state, secondary_period_error_messages) {
        state.secondary_period_error_messages = secondary_period_error_messages
    },
    setPermissionItemSecondaryPeriodDialog(state, permission_item_secondary_period_dialog) {
        state.permission_item_secondary_period_dialog = permission_item_secondary_period_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
