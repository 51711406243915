const state = {
    area_ranges: [],
    area_range: {
        names: {},
        descriptions: {},
    },
    area_ranges_loading: false,
    deleting_area_range_dialog: false,
    creating_area_range_dialog: false,
    editing_area_range_dialog: false,
    area_range_permissions: [],
    area_range_errors: [],
    area_range_error_messages: {}
}
const getters = {
    area_ranges: state => state.area_ranges,
    area_range: state => state.area_range,
    area_ranges_loading: state => state.area_ranges_loading,
    deleting_area_range_dialog: state => state.deleting_area_range_dialog,
    creating_area_range_dialog: state => state.creating_area_range_dialog,
    editing_area_range_dialog: state => state.editing_area_range_dialog,
    permission_item_area_range_dialog: state => state.permission_item_area_range_dialog,
    area_range_errors: state => state.area_range_errors,
    area_range_error_messages: state => state.area_range_error_messages,
}
const actions = {

    getAreaRanges({commit},) {
        commit('setAreaRangesLoading', true)
        axios.get('/api/admin/area_ranges', ).then(response => {
            commit('setAreaRanges', response.data)
            commit('setAreaRangesLoading', false)
        })
    },
    getAllAreaRanges({commit}, params) {
        commit('setAreaRangesLoading', true)
        axios.get('/api/area_ranges/all', {params}).then(response => {
            commit('setAreaRanges', response.data)
            commit('setAreaRangesLoading', false)
        })
    },
    setAreaRangePermissions({commit}, params) {
        commit('setAreaRangesLoading', true)
        axios.post('/api/area_ranges/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemAreaRangeDialog', false)
            } else {
                commit('setAreaRangeErrors', [response.data.message])
            }
            commit('setAreaRangesLoading', false)
        }).catch(error => {
            commit('setAreaRangesLoading', false)
            commit('setAreaRangeErrorMessages', error.response.data.errors)
        })
    },
    getAreaRange({commit}, id) {
        commit('setAreaRangesLoading', true)
        axios.get('/api/admin/area_ranges/' + id).then(response => {
            commit('setAreaRange', response.data)
            commit('setAreaRangesLoading', false)
        })
    },

    storeAreaRange({commit, dispatch}, area_range) {
        commit('setAreaRangesLoading', true)
        commit('setAreaRangeErrorMessages', {})
        commit('setAreaRangeErrors', [])

        axios.post('/api/admin/area_ranges', area_range).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingAreaRangeDialog', false)
                commit('setAreaRange', {})
                dispatch('getAreaRanges')

            // } else {
            //     commit('setAreaRangeErrors', res.data.message)
            // }
            commit('setAreaRangesLoading', false)
        }).catch(error => {
            commit('setAreaRangesLoading', false)
            commit('setAreaRangeErrorMessages', error.response.data.errors)
        })
    },
    deleteAreaRange({commit, dispatch}, id) {
        commit('setAreaRangesLoading', true)
        commit('setAreaRangeErrors', [])
        commit('setAreaRangeErrorMessages', {})

        axios.delete('/api/admin/area_ranges/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingAreaRangeDialog', false)
                dispatch('getAreaRanges')
            // } else {
                // commit('setAreaRangeErrors', [res.data.message])
            // }
            commit('setAreaRangesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setAreaRangesLoading', false)
            commit('setAreaRangeErrorMessages', error.response.data.errors)
        })
    },
    editAreaRange({commit, dispatch}, {id, area_range}) {
        commit('setAreaRangesLoading', true)
        commit('setAreaRangeErrorMessages', {})
        commit('setAreaRangeErrors', [])
        axios.put('/api/admin/area_ranges/' + id, area_range).then((res) => {
            // if (res.data.ok) {
                commit('setAreaRangesLoading', false)
                dispatch('getAreaRanges')

            // } else {
            //     commit('setAreaRangeErrors', res.data.message)
            // }
            commit('setEditingAreaRangeDialog', false)
        }).catch(error => {
            commit('setAreaRangesLoading', false)
            commit('setAreaRangeErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setAreaRanges(state, area_ranges) {
        state.area_ranges = area_ranges
    },
    setAreaRangesLoading(state, loading) {
        state.area_ranges_loading = loading
    },
    setDeletingAreaRangeDialog(state, deleting_area_range_dialog) {
        state.deleting_area_range_dialog = deleting_area_range_dialog
    },
    setCreatingAreaRangeDialog(state, creating_area_range_dialog) {
        state.creating_area_range_dialog = creating_area_range_dialog
    },
    setEditingAreaRangeDialog(state, editing_area_range_dialog) {
        state.editing_area_range_dialog = editing_area_range_dialog
    },
    setAreaRangeErrors(state, area_range_errors) {
        state.area_range_errors = area_range_errors
    },
    setAreaRange(state, area_range) {
        state.area_range = area_range
    },
    setAreaRangeErrorMessages(state, area_range_error_messages) {
        state.area_range_error_messages = area_range_error_messages
    },
    setPermissionItemAreaRangeDialog(state, permission_item_area_range_dialog) {
        state.permission_item_area_range_dialog = permission_item_area_range_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
