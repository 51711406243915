const state = {
  soil_types: [],
  soil_type_loading: false,
  deleting_soil_type_dialog: false,
  creating_soil_type_dialog: false,
  soil_type_errors: [],
  soil_type_error_messages: {},
};
const getters = {
  soil_types: (state) => state.soil_types,
  soil_type_loading: (state) => state.soil_type_loading,
  deleting_soil_type_dialog: (state) => state.deleting_soil_type_dialog,
  creating_soil_type_dialog: (state) => state.creating_soil_type_dialog,
  soil_type_errors: (state) => state.soil_type_errors,
  soil_type_error_messages: (state) => state.soil_type_error_messages,
};
const actions = {
  getSoilType({ commit }) {
    commit("soil_type_loading", true);
    axios.get("/api/admin/soil_types").then((response) => {
      commit("soil_types", response.data);
      commit("soil_type_loading", false);
    });
  },
  deleteSoilType({ commit, dispatch }, id) {
    commit("soil_type_loading", true);
    commit("soil_type_errors", []);
    commit("soil_type_error_messages", {});

    axios
      .delete("/api/admin/soil_types/" + id)
      .then(() => {
        commit("deleting_soil_type_dialog", false);
        dispatch("getSoilType");
        commit("soil_type_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("soil_type_loading", false);
        commit("soil_type_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  soil_types(state, soil_types) {
    state.soil_types = soil_types;
  },
  soil_type_loading(state, loading) {
    state.soil_type_loading = loading;
  },
  deleting_soil_type_dialog(state, deleting_soil_type_dialog) {
    state.deleting_soil_type_dialog = deleting_soil_type_dialog;
  },
  creating_soil_type_dialog(state, creating_soil_type_dialog) {
    state.creating_soil_type_dialog = creating_soil_type_dialog;
  },
  soil_type_errors(state, soil_type_errors) {
    state.soil_type_errors = soil_type_errors;
  },
  soil_type_error_messages(state, soil_type_error_messages) {
    state.soil_type_error_messages = soil_type_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
