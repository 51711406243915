export default [
    {
        path: '/admin/technique-categories',
        name: 'technique_categories.index',
        component: () => import('../../pages/admin/TechniqueCategory/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/technique-categories/create',
        name: 'technique_categories.create',
        component: () => import('../../pages/admin/TechniqueCategory/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/technique-categories/:id/edit',
        name: 'technique_categories.edit',
        component: () => import('../../pages/admin/TechniqueCategory/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
