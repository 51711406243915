const state = {
  scheduled_event_templates: [],
  scheduled_event_template: {
    names: {},
    descriptions: {},
  },
  scheduled_event_templates_loading: false,
  deleting_scheduled_event_template_dialog: false,
  creating_scheduled_event_template_dialog: false,
  editing_scheduled_event_template_dialog: false,
  scheduled_event_template_permissions: [],
  scheduled_event_template_errors: null,
  scheduled_event_template_error_messages: {},
};
const getters = {
  scheduled_event_templates: (state) => state.scheduled_event_templates,
  scheduled_event_template: (state) => state.scheduled_event_template,
  scheduled_event_templates_loading: (state) =>
    state.scheduled_event_templates_loading,
  deleting_scheduled_event_template_dialog: (state) =>
    state.deleting_scheduled_event_template_dialog,
  creating_scheduled_event_template_dialog: (state) =>
    state.creating_scheduled_event_template_dialog,
  editing_scheduled_event_template_dialog: (state) =>
    state.editing_scheduled_event_template_dialog,
  permission_item_scheduled_event_template_dialog: (state) =>
    state.permission_item_scheduled_event_template_dialog,
  scheduled_event_template_errors: (state) =>
    state.scheduled_event_template_errors,
  scheduled_event_template_error_messages: (state) =>
    state.scheduled_event_template_error_messages,
};
const actions = {
  getScheduledEventTemplates({ commit }, event_id) {
    commit("setScheduledEventTemplatesLoading", true);
    axios
      .get("/api/admin/scheduled_event_templates/all/" + event_id)
      .then((response) => {
        commit("setScheduledEventTemplates", response.data);
        commit("setScheduledEventTemplatesLoading", false);
      });
  },
  getAllScheduledEventTemplates({ commit }, params) {
    commit("setScheduledEventTemplatesLoading", true);
    axios
      .get("/api/scheduled_event_templates/all", { params })
      .then((response) => {
        commit("setScheduledEventTemplates", response.data);
        commit("setScheduledEventTemplatesLoading", false);
      });
  },
  setScheduledEventTemplatePermissions({ commit }, params) {
    commit("setScheduledEventTemplatesLoading", true);
    axios
      .post(
        "/api/scheduled_event_templates/" + params.id + "/permissions",
        params
      )
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemScheduledEventTemplateDialog", false);
        } else {
          commit("setScheduledEventTemplateErrors", [response.data.message]);
        }
        commit("setScheduledEventTemplatesLoading", false);
      })
      .catch((error) => {
        commit("setScheduledEventTemplatesLoading", false);
        commit(
          "setScheduledEventTemplateErrorMessages",
          error.response.data.errors
        );
      });
  },
  getScheduledEventTemplate({ commit }, id) {
    commit("setScheduledEventTemplatesLoading", true);
    axios
      .get("/api/admin/scheduled_event_templates/one/" + id)
      .then((response) => {
        commit("setScheduledEventTemplate", response.data);
        commit("setScheduledEventTemplatesLoading", false);
      });
  },

  storeScheduledEventTemplate({ commit, dispatch }, scheduled_event_template) {
    commit("setScheduledEventTemplatesLoading", true);
    commit("setScheduledEventTemplateErrorMessages", {});
    commit("setScheduledEventTemplateErrors", null);

    axios
      .post("/api/admin/scheduled_event_templates", scheduled_event_template)
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingScheduledEventTemplateDialog", false);
        // commit('setScheduledEventTemplate', {})
        dispatch("getScheduledEventTemplates");

        // } else {
        //     commit('setScheduledEventTemplateErrors', res.data.message)
        // }
        commit("setScheduledEventTemplatesLoading", false);
      })
      .catch((error) => {
        commit("setScheduledEventTemplatesLoading", false);
        commit(
          "setScheduledEventTemplateErrorMessages",
          error.response.data.errors
        );
        commit("setScheduledEventTemplateErrors", error.response.data.message);
      });
  },
  deleteScheduledEventTemplate({ commit, dispatch }, id) {
    commit("setScheduledEventTemplatesLoading", true);
    commit("setScheduledEventTemplateErrors", null);
    commit("setScheduledEventTemplateErrorMessages", {});

    axios
      .delete("/api/admin/scheduled_event_templates/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingScheduledEventTemplateDialog", false);
        dispatch("getScheduledEventTemplates");
        // } else {
        // commit('setScheduledEventTemplateErrors', [res.data.message])
        // }
        commit("setScheduledEventTemplatesLoading", false);
      })
      .catch((error) => {
        commit("setScheduledEventTemplatesLoading", false);
        commit(
          "setScheduledEventTemplateErrorMessages",
          error.response.data.errors
        );
        commit("setScheduledEventTemplateErrors", error.response.data.message);
      });
  },
  editScheduledEventTemplate({ commit, dispatch }, { id, item }) {
    commit("setScheduledEventTemplatesLoading", true);
    commit("setScheduledEventTemplateErrorMessages", {});
    commit("setScheduledEventTemplateErrors", null);
    axios
      .put("/api/admin/scheduled_event_templates/" + id, item)
      .then((res) => {
        // if (res.data.ok) {
        commit("setScheduledEventTemplatesLoading", false);
        dispatch("getScheduledEventTemplates");

        // } else {
        //     commit('setScheduledEventTemplateErrors', res.data.message)
        // }
        commit("setEditingScheduledEventTemplateDialog", false);
      })
      .catch((error) => {
        commit("setScheduledEventTemplatesLoading", false);
        commit(
          "setScheduledEventTemplateErrorMessages",
          error.response.data.errors
        );
        commit("setScheduledEventTemplateErrors", error.response.data.message);
      });
  },
};

const mutations = {
  setScheduledEventTemplates(state, scheduled_event_templates) {
    state.scheduled_event_templates = scheduled_event_templates;
  },
  setScheduledEventTemplatesLoading(state, loading) {
    state.scheduled_event_templates_loading = loading;
  },
  setDeletingScheduledEventTemplateDialog(
    state,
    deleting_scheduled_event_template_dialog
  ) {
    state.deleting_scheduled_event_template_dialog =
      deleting_scheduled_event_template_dialog;
  },
  setCreatingScheduledEventTemplateDialog(
    state,
    creating_scheduled_event_template_dialog
  ) {
    state.creating_scheduled_event_template_dialog =
      creating_scheduled_event_template_dialog;
  },
  setEditingScheduledEventTemplateDialog(
    state,
    editing_scheduled_event_template_dialog
  ) {
    state.editing_scheduled_event_template_dialog =
      editing_scheduled_event_template_dialog;
  },
  setScheduledEventTemplateErrors(state, scheduled_event_template_errors) {
    state.scheduled_event_template_errors = scheduled_event_template_errors;
  },
  setScheduledEventTemplate(state, scheduled_event_template) {
    state.scheduled_event_template = scheduled_event_template;
  },
  setScheduledEventTemplateErrorMessages(
    state,
    scheduled_event_template_error_messages
  ) {
    state.scheduled_event_template_error_messages =
      scheduled_event_template_error_messages;
  },
  setPermissionItemScheduledEventTemplateDialog(
    state,
    permission_item_scheduled_event_template_dialog
  ) {
    state.permission_item_scheduled_event_template_dialog =
      permission_item_scheduled_event_template_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
