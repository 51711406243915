export default [
  {
    path: "/admin/soil-range",
    name: "soil_range.index",
    component: () => import("../../pages/admin/SoilRange/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-range/create",
    name: "soil_range.create",
    component: () => import("../../pages/admin/SoilRange/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-range/:id/edit",
    name: "soil_range.edit",
    component: () => import("../../pages/admin/SoilRange/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
