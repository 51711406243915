const state = {
  soil_cycles: [],
  soil_cycle_loading: false,
  deleting_soil_cycle_dialog: false,
  creating_soil_cycle_dialog: false,
  soil_cycle_errors: [],
  soil_cycle_error_messages: {},
};
const getters = {
  soil_cycles: (state) => state.soil_cycles,
  soil_cycle_loading: (state) => state.soil_cycle_loading,
  deleting_soil_cycle_dialog: (state) => state.deleting_soil_cycle_dialog,
  creating_soil_cycle_dialog: (state) => state.creating_soil_cycle_dialog,
  soil_cycle_errors: (state) => state.soil_cycle_errors,
  soil_cycle_error_messages: (state) => state.soil_cycle_error_messages,
};
const actions = {
  getSoilCycle({ commit }) {
    commit("soil_cycle_loading", true);
    axios.get("/api/admin/fertilizers_cycles").then((response) => {
      commit("soil_cycles", response.data);
      commit("soil_cycle_loading", false);
    });
  },
  deleteSoilCycle({ commit, dispatch }, id) {
    commit("soil_cycle_loading", true);
    commit("soil_cycle_errors", []);
    commit("soil_cycle_error_messages", {});

    axios
      .delete("/api/admin/fertilizers_cycles/" + id)
      .then(() => {
        commit("deleting_soil_cycle_dialog", false);
        dispatch("getSoilCycle");
        commit("soil_cycle_loading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("soil_cycle_loading", false);
        commit("soil_cycle_error_messages", error.response.data.errors);
      });
  },
};

const mutations = {
  soil_cycles(state, soil_cycles) {
    state.soil_cycles = soil_cycles;
  },
  soil_cycle_loading(state, loading) {
    state.soil_cycle_loading = loading;
  },
  deleting_soil_cycle_dialog(state, deleting_soil_cycle_dialog) {
    state.deleting_soil_cycle_dialog = deleting_soil_cycle_dialog;
  },
  creating_soil_cycle_dialog(state, creating_soil_cycle_dialog) {
    state.creating_soil_cycle_dialog = creating_soil_cycle_dialog;
  },
  soil_cycle_errors(state, soil_cycle_errors) {
    state.soil_cycle_errors = soil_cycle_errors;
  },
  soil_cycle_error_messages(state, soil_cycle_error_messages) {
    state.soil_cycle_error_messages = soil_cycle_error_messages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
