import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import auth from "./auth";
import action from "./action";
import crop_type from "./crop_type";
import aggregate from "./aggregate";
import location from "./location";
import calculation_category from "./calculation_category";
import technique from "./technique";
import period from "./period";
import secondary_period from "./secondary_period";
import event from "./event";
import event_template from "./event_template";
import scheduled_event_template from "./scheduled_event_template";
import recommended_techniques_to_events from "./recommended_techniques_to_events";
import recommended_aggregates_to_events from "./recommended_aggregates_to_events";
import gis_bridge from "./gis_bridge";
import planting_distance from "./planting_distance";
import aggregate_parameter from "./aggregate_parameter";
import zone from "./zone";
import technique_category from "./technique_category";
import technique_brands from "./technique_brands";
import aggregate_category from "./aggregate_category";
import area_range from "./area_range";
import area_range_aggregate from "./area_range_aggregate";
import area_range_technique from "./area_range_technique";
import aggregate_aggregate_parameter from "./aggregate_aggregate_parameter";
import cluster from "./cluster";
import countries from "./countries";
import regions from "./regions";
import districts from "./districts";
import fertilizer from "./fertilizer";
import fertilizer_category from "./fertilizer_category";
import soil_type from "./soil_type";
import soil_zone from "./soil_zone";
import soil_cycle from "./soil_cycle";
import soil_table from "./soil_table";
import soil_range from "./soil_range";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    cluster,
    aggregate_aggregate_parameter,
    area_range_technique,
    area_range_aggregate,
    area_range,
    aggregate_category,
    technique_category,
    zone,
    aggregate_parameter,
    planting_distance,
    gis_bridge,
    recommended_aggregates_to_events,
    recommended_techniques_to_events,
    scheduled_event_template,
    event_template,
    event,
    secondary_period,
    period,
    technique,
    calculation_category,
    location,
    aggregate,
    crop_type,
    action,
    auth,
    user,
    technique_brands,
    countries,
    regions,
    districts,
    fertilizer,
    fertilizer_category,
    soil_type,
    soil_zone,
    soil_cycle,
    soil_table,
    soil_range,
  },
  state: {
    title: "Dashboard",
    temporary: true,
  },
  getters: {
    title: (state) => state.title,
    temporary: (state) => state.temporary,
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    temporary(state, temporary) {
      state.temporary = temporary;
    },
  },
  actions: {
    setTitle({ commit }, title) {
      commit("setTitle", title);
    },
  },
});

export default store;
