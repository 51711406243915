const state = {
    actions: [],
    action: {
        names: {},
        descriptions: {},
    },
    actions_loading: false,
    deleting_action_dialog: false,
    creating_action_dialog: false,
    editing_action_dialog: false,
    action_permissions: [],
    action_errors: [],
    action_error_messages: {}
}
const getters = {
    actions: state => state.actions,
    action: state => state.action,
    actions_loading: state => state.actions_loading,
    deleting_action_dialog: state => state.deleting_action_dialog,
    creating_action_dialog: state => state.creating_action_dialog,
    editing_action_dialog: state => state.editing_action_dialog,
    permission_item_action_dialog: state => state.permission_item_action_dialog,
    action_errors: state => state.action_errors,
    action_error_messages: state => state.action_error_messages,
}
const actions = {

    getActions({commit},) {
        commit('setActionsLoading', true)
        axios.get('/api/admin/actions', ).then(response => {
            commit('setActions', response.data)
            commit('setActionsLoading', false)
        })
    },
    getAllActions({commit}, params) {
        commit('setActionsLoading', true)
        axios.get('/api/actions/all', {params}).then(response => {
            commit('setActions', response.data)
            commit('setActionsLoading', false)
        })
    },
    setActionPermissions({commit}, params) {
        commit('setActionsLoading', true)
        axios.post('/api/actions/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemActionDialog', false)
            } else {
                commit('setActionErrors', [response.data.message])
            }
            commit('setActionsLoading', false)
        }).catch(error => {
            commit('setActionsLoading', false)
            commit('setActionErrorMessages', error.response.data.errors)
        })
    },
    getAction({commit}, id) {
        commit('setActionsLoading', true)
        axios.get('/api/admin/actions/' + id).then(response => {
            commit('setAction', response.data)
            commit('setActionsLoading', false)
        })
    },

    async storeAction({commit, dispatch}, action) {
        commit('setActionsLoading', true)
        commit('setActionErrorMessages', {})
        commit('setActionErrors', [])
        var res = await axios.post('/api/admin/actions', action)
        commit('setCreatingActionDialog', false)
        commit('setAction', res)
        dispatch('getActions')
        return res
    },
    deleteAction({commit, dispatch}, id) {
        commit('setActionsLoading', true)
        commit('setActionErrors', [])
        commit('setActionErrorMessages', {})

        axios.delete('/api/admin/actions/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingActionDialog', false)
                dispatch('getActions')
            // } else {
            //     commit('setActionErrors', [res.data.message])
            // }
            commit('setActionsLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setActionsLoading', false)
            commit('setActionErrorMessages', error.response.data.errors)
        })
    },
    async editAction({commit, dispatch}, action) {
        commit('setActionsLoading', true)
        commit('setActionErrorMessages', {})
        commit('setActionErrors', [])
        axios.put('/api/admin/actions/' + action.id, action).then((res) => {
            // if (res.data.ok) {
                commit('setActionsLoading', false)
                dispatch('getActions')

            // } else {
            //     commit('setActionErrors', res.data.message)
            // }
            commit('setEditingActionDialog', false)
        }).catch(error => {
            commit('setActionsLoading', false)
            commit('setActionErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setActions(state, actions) {
        state.actions = actions
    },
    setActionsLoading(state, loading) {
        state.actions_loading = loading
    },
    setDeletingActionDialog(state, deleting_action_dialog) {
        state.deleting_action_dialog = deleting_action_dialog
    },
    setCreatingActionDialog(state, creating_action_dialog) {
        state.creating_action_dialog = creating_action_dialog
    },
    setEditingActionDialog(state, editing_action_dialog) {
        state.editing_action_dialog = editing_action_dialog
    },
    setActionErrors(state, action_errors) {
        state.action_errors = action_errors
    },
    setAction(state, action) {
        state.action = action
    },
    setActionErrorMessages(state, action_error_messages) {
        state.action_error_messages = action_error_messages
    },
    setPermissionItemActionDialog(state, permission_item_action_dialog) {
        state.permission_item_action_dialog = permission_item_action_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
