const state = {
  technique_categories: [],
  technique_category: {
    names: {},
    descriptions: {},
  },
  technique_categories_loading: false,
  deleting_technique_category_dialog: false,
  creating_technique_category_dialog: false,
  editing_technique_category_dialog: false,
  technique_category_permissions: [],
  technique_category_errors: [],
  technique_category_error_messages: {},
  technique_category_to_scheduled_event_templates: [],
};
const getters = {
  technique_categories: (state) => state.technique_categories,
  technique_category: (state) => state.technique_category,
  technique_categories_loading: (state) => state.technique_categories_loading,
  deleting_technique_category_dialog: (state) =>
    state.deleting_technique_category_dialog,
  creating_technique_category_dialog: (state) =>
    state.creating_technique_category_dialog,
  editing_technique_category_dialog: (state) =>
    state.editing_technique_category_dialog,
  permission_item_technique_category_dialog: (state) =>
    state.permission_item_technique_category_dialog,
  technique_category_errors: (state) => state.technique_category_errors,
  technique_category_error_messages: (state) =>
    state.technique_category_error_messages,
  technique_category_to_scheduled_event_templates: (state) =>
    state.technique_category_to_scheduled_event_templates,
};
const actions = {
  getTechniqueCategories({ commit }) {
    commit("setTechniqueCategoriesLoading", true);
    axios.get("/api/admin/technique_categories").then((response) => {
      commit("setTechniqueCategories", response.data);
      commit("setTechniqueCategoriesLoading", false);
    });
  },
  getTechniqueCategoriesScheduledEventTemplate({ commit }, params) {
    axios
      .get("/api/admin/technique_category_to_scheduled_event_templates", {
        params,
      })
      .then((res) => {
        commit("technique_category_to_scheduled_event_templates", res.data);
      });
  },
  getAllTechniqueCategories({ commit }, params) {
    commit("setTechniqueCategoriesLoading", true);
    axios.get("/api/technique_categories/all", { params }).then((response) => {
      commit("setTechniqueCategories", response.data);
      commit("setTechniqueCategoriesLoading", false);
    });
  },
  setTechniqueCategoryPermissions({ commit }, params) {
    commit("setTechniqueCategoriesLoading", true);
    axios
      .post("/api/technique_categories/" + params.id + "/permissions", params)
      .then((response) => {
        if (response.data.ok) {
          commit("setPermissionItemTechniqueCategoryDialog", false);
        } else {
          commit("setTechniqueCategoryErrors", [response.data.message]);
        }
        commit("setTechniqueCategoriesLoading", false);
      })
      .catch((error) => {
        commit("setTechniqueCategoriesLoading", false);
        commit("setTechniqueCategoryErrorMessages", error.response.data.errors);
      });
  },
  getTechniqueCategory({ commit }, search) {
    commit("setTechniqueCategoriesLoading", true);
    axios.get("/api/admin/technique_categories/" + search).then((response) => {
      commit("setTechniqueCategory", response.data);
      commit("setTechniqueCategoriesLoading", false);
    });
  },

  storeTechniqueCategory({ commit, dispatch }, technique_category) {
    commit("setTechniqueCategoriesLoading", true);
    commit("setTechniqueCategoryErrorMessages", {});
    commit("setTechniqueCategoryErrors", []);

    axios
      .post("/api/admin/technique_categories", technique_category)
      .then((res) => {
        // if (res.data.ok) {
        commit("setCreatingTechniqueCategoryDialog", false);
        commit("setTechniqueCategory", {});
        dispatch("getTechniqueCategories");

        // } else {
        //     commit('setTechniqueCategoryErrors', res.data.message)
        // }
        commit("setTechniqueCategoriesLoading", false);
      })
      .catch((error) => {
        commit("setTechniqueCategoriesLoading", false);
        commit("setTechniqueCategoryErrorMessages", error.response.data.errors);
      });
  },
  deleteTechniqueCategory({ commit, dispatch }, id) {
    commit("setTechniqueCategoriesLoading", true);
    commit("setTechniqueCategoryErrors", []);
    commit("setTechniqueCategoryErrorMessages", {});

    axios
      .delete("/api/admin/technique_categories/" + id)
      .then((res) => {
        // if (res.data.ok) {
        commit("setDeletingTechniqueCategoryDialog", false);
        dispatch("getTechniqueCategories");
        // } else {
        // commit('setTechniqueCategoryErrors', [res.data.message])
        // }
        commit("setTechniqueCategoriesLoading", false);
      })
      .catch((error) => {
        console.log(error);
        commit("setTechniqueCategoriesLoading", false);
        commit("setTechniqueCategoryErrorMessages", error.response.data.errors);
      });
  },
  editTechniqueCategory({ commit, dispatch }, { id, technique_category }) {
    commit("setTechniqueCategoriesLoading", true);
    commit("setTechniqueCategoryErrorMessages", {});
    commit("setTechniqueCategoryErrors", []);
    axios
      .put("/api/admin/technique_categories/" + id, technique_category)
      .then((res) => {
        // if (res.data.ok) {
        commit("setTechniqueCategoriesLoading", false);
        dispatch("getTechniqueCategories");

        // } else {
        //     commit('setTechniqueCategoryErrors', res.data.message)
        // }
        commit("setEditingTechniqueCategoryDialog", false);
      })
      .catch((error) => {
        commit("setTechniqueCategoriesLoading", false);
        commit("setTechniqueCategoryErrorMessages", error.response.data.errors);
      });
  },
};

const mutations = {
  setTechniqueCategories(state, technique_categories) {
    state.technique_categories = technique_categories;
  },
  technique_category_to_scheduled_event_templates(
    state,
    technique_category_to_scheduled_event_templates
  ) {
    state.technique_category_to_scheduled_event_templates =
      technique_category_to_scheduled_event_templates;
  },
  setTechniqueCategoriesLoading(state, loading) {
    state.technique_categories_loading = loading;
  },
  setDeletingTechniqueCategoryDialog(
    state,
    deleting_technique_category_dialog
  ) {
    state.deleting_technique_category_dialog =
      deleting_technique_category_dialog;
  },
  setCreatingTechniqueCategoryDialog(
    state,
    creating_technique_category_dialog
  ) {
    state.creating_technique_category_dialog =
      creating_technique_category_dialog;
  },
  setEditingTechniqueCategoryDialog(state, editing_technique_category_dialog) {
    state.editing_technique_category_dialog = editing_technique_category_dialog;
  },
  setTechniqueCategoryErrors(state, technique_category_errors) {
    state.technique_category_errors = technique_category_errors;
  },
  setTechniqueCategory(state, technique_category) {
    state.technique_category = technique_category;
  },
  setTechniqueCategoryErrorMessages(state, technique_category_error_messages) {
    state.technique_category_error_messages = technique_category_error_messages;
  },
  setPermissionItemTechniqueCategoryDialog(
    state,
    permission_item_technique_category_dialog
  ) {
    state.permission_item_technique_category_dialog =
      permission_item_technique_category_dialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
