import helpers from "./modules/helpers";
import user_management from "./modules/user_management";
import locations from "./modules/locations";
import zones from "./modules/zones";
import aggregate_parameters from "./modules/aggregate_parameters";
import planting_distances from "./modules/planting_distances";
import area_ranges from "./modules/area_ranges";
import area_range_aggregates from "./modules/area_range_aggregates";
import area_range_techniques from "./modules/area_range_techniques";
import aggregate_aggregate_parameters from "./modules/aggregate_aggregate_parameters";
import actions from "./modules/actions";
import crop_types from "./modules/crop_types";
import aggregates from "./modules/aggregates";
import techniques from "./modules/techniques";
import fertilizers from "./modules/fertilizers";
import periods from "./modules/periods";
import secondary_periods from "./modules/secondary_periods";
import events from "./modules/events";
import event_templates from "./modules/event_templates";
import scheduled_event_templates from "./modules/scheduled_event_templates";
import recommended_techniques_to_events from "./modules/recommended_techniques_to_events";
import recommended_aggregates_to_events from "./modules/recommended_aggregates_to_events";
import technique_categories from "./modules/technique_categories";
import fertilizer_categories from "./modules/fertilizer_categories";
import technique_brands from "./modules/technique_brands";
import aggregate_categories from "./modules/aggregate_categories";
import clusters from "./modules/clusters";
import countries from "@/router/modules/countries";
import regions from "@/router/modules/regions";
import districts from "@/router/modules/districts";
import soil_type from "@/router/modules/soil_type";
import soil_zone from "@/router/modules/soil_zone";
import soil_cycle from "@/router/modules/soil_cycle";
import soil_range from "@/router/modules/soil_range";
import soil_table from "@/router/modules/soil_table";

const routes = [
  {
    path: "/",
    redirect: () => {
      const userData = !!localStorage.getItem("token");
      if (userData) return { name: "user.my_fields" };
      else return { name: "user.login" };
    },
  },
  {
    path: "/admin/dashboard",
    name: "dashboard",
    component: () => import("../pages/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
    },
  },
  {
    path: "/",
    name: "user.my_fields",
    component: () => import("../pages/user/Index.vue"),
    meta: {
      layout: "content_for_user",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/tech-card",
    name: "user.tech_card",
    component: () => import("../pages/user/TechCard.vue"),
    meta: {
      layout: "content_for_user",
      resource: "Public",
    },
  },
  ...aggregate_categories,
  ...technique_categories,
  ...recommended_aggregates_to_events,
  ...recommended_techniques_to_events,
  ...scheduled_event_templates,
  ...event_templates,
  ...events,
  ...secondary_periods,
  ...periods,
  ...techniques,
  ...aggregates,
  ...clusters,
  ...helpers,
  ...crop_types,
  ...actions,
  ...aggregate_aggregate_parameters,
  ...area_range_aggregates,
  ...area_range_techniques,
  ...area_ranges,
  ...planting_distances,
  ...aggregate_parameters,
  ...zones,
  ...locations,
  ...user_management,
  ...technique_brands,
  ...countries,
  ...regions,
  ...districts,
  ...fertilizers,
  ...fertilizer_categories,
  ...soil_type,
  ...soil_zone,
  ...soil_cycle,
  ...soil_range,
  ...soil_table,
  {
    path: "/admin/login",
    name: "login",
    component: () => import("../pages/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/admin/callback",
    name: "callback",
    component: () => import("../pages/admin/Callback.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/login",
    name: "user.login",
    component: () => import("../pages/user/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/callback",
    name: "user.callback",
    component: () => import("../pages/user/Callback.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
];

export default routes;
