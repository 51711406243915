export default [
    {
        path: '/admin/aggregate-categories',
        name: 'aggregate_categories.index',
        component: () => import('../../pages/admin/AggregateCategory/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregate-categories/create',
        name: 'aggregate_categories.create',
        component: () => import('../../pages/admin/AggregateCategory/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/aggregate-categories/:id/edit',
        name: 'aggregate_categories.edit',
        component: () => import('../../pages/admin/AggregateCategory/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
