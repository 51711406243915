const state = {
    events: [],
    event: {
        names: {},
        descriptions: {},
    },
    events_loading: false,
    deleting_event_dialog: false,
    creating_event_dialog: false,
    editing_event_dialog: false,
    event_permissions: [],
    event_errors: [],
    event_error_messages: {}
}
const getters = {
    events: state => state.events,
    event: state => state.event,
    events_loading: state => state.events_loading,
    deleting_event_dialog: state => state.deleting_event_dialog,
    creating_event_dialog: state => state.creating_event_dialog,
    editing_event_dialog: state => state.editing_event_dialog,
    permission_item_event_dialog: state => state.permission_item_event_dialog,
    event_errors: state => state.event_errors,
    event_error_messages: state => state.event_error_messages,
}
const actions = {

    async getEvents({commit},) {
        commit('setEventsLoading', true)
        try {
            var response = await axios.get('/api/admin/events',)
            commit('setEvents', response.data)
            commit('setEventsLoading', false)
        } catch (e) {
            console.log(e);
        }
    },
    getAllEvents({commit}, params) {
        commit('setEventsLoading', true)
        axios.get('/api/events/all', {params}).then(response => {
            commit('setEvents', response.data)
            commit('setEventsLoading', false)
        })
    },
    setEventPermissions({commit}, params) {
        commit('setEventsLoading', true)
        axios.post('/api/events/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemEventDialog', false)
            } else {
                commit('setEventErrors', [response.data.message])
            }
            commit('setEventsLoading', false)
        }).catch(error => {
            commit('setEventsLoading', false)
            commit('setEventErrorMessages', error.response.data.errors)
        })
    },
    getEvent({commit}, id) {
        commit('setEventsLoading', true)
        axios.get('/api/admin/events/' + id).then(response => {
            commit('setEvent', response.data)
            commit('setEventsLoading', false)
        })
    },

    storeEvent({commit, dispatch}, event) {
        commit('setEventsLoading', true)
        commit('setEventErrorMessages', {})
        commit('setEventErrors', [])

        axios.post('/api/admin/events', event).then((res) => {
            // if (res.data.ok) {
            commit('setCreatingEventDialog', false)
            // commit('setEvent', {})
            dispatch('getEvents')

            // } else {
            //     commit('setEventErrors', res.data.message)
            // }
            commit('setEventsLoading', false)
        }).catch(error => {
            commit('setEventsLoading', false)
            commit('setEventErrorMessages', error.response.data.errors)
            commit('setEventErrors', error.response.data.message)
        })
    },
    deleteEvent({commit, dispatch}, id) {
        commit('setEventsLoading', true)
        commit('setEventErrors', [])
        commit('setEventErrorMessages', {})

        axios.delete('/api/admin/events/' + id).then((res) => {
            // if (res.data.ok) {
            commit('setDeletingEventDialog', false)
            dispatch('getEvents')
            // } else {
            // commit('setEventErrors', [res.data.message])
            // }
            commit('setEventsLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setEventsLoading', false)
            commit('setEventErrorMessages', error.response.data.errors)
        })
    },
    editEvent({commit, dispatch}, {id, event}) {
        commit('setEventsLoading', true)
        commit('setEventErrorMessages', {})
        commit('setEventErrors', [])
        axios.put('/api/admin/events/' + id, event).then((res) => {
            // if (res.data.ok) {
            commit('setEventsLoading', false)
            dispatch('getEvents')

            // } else {
            //     commit('setEventErrors', res.data.message)
            // }
            commit('setEditingEventDialog', false)
        }).catch(error => {
            commit('setEventsLoading', false)
            commit('setEventErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setEvents(state, events) {
        state.events = events
    },
    setEventsLoading(state, loading) {
        state.events_loading = loading
    },
    setDeletingEventDialog(state, deleting_event_dialog) {
        state.deleting_event_dialog = deleting_event_dialog
    },
    setCreatingEventDialog(state, creating_event_dialog) {
        state.creating_event_dialog = creating_event_dialog
    },
    setEditingEventDialog(state, editing_event_dialog) {
        state.editing_event_dialog = editing_event_dialog
    },
    setEventErrors(state, event_errors) {
        state.event_errors = event_errors
    },
    setEvent(state, event) {
        state.event = event
    },
    setEventErrorMessages(state, event_error_messages) {
        state.event_error_messages = event_error_messages
    },
    setPermissionItemEventDialog(state, permission_item_event_dialog) {
        state.permission_item_event_dialog = permission_item_event_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
