export default [
  {
    path: "/admin/soil-zone",
    name: "soil_zone.index",
    component: () => import("../../pages/admin/SoilZone/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-zone/create",
    name: "soil_zone.create",
    component: () => import("../../pages/admin/SoilZone/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-zone/:id/edit",
    name: "soil_zone.edit",
    component: () => import("../../pages/admin/SoilZone/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
