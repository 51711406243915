const state = {
    event_templates: [],
    event_template: {
        names: {},
        descriptions: {},
    },
    event_templates_loading: false,
    deleting_event_template_dialog: false,
    creating_event_template_dialog: false,
    editing_event_template_dialog: false,
    event_template_permissions: [],
    event_template_errors: null,
    event_template_error_messages: {}
}
const getters = {
    event_templates: state => state.event_templates,
    event_template: state => state.event_template,
    event_templates_loading: state => state.event_templates_loading,
    deleting_event_template_dialog: state => state.deleting_event_template_dialog,
    creating_event_template_dialog: state => state.creating_event_template_dialog,
    editing_event_template_dialog: state => state.editing_event_template_dialog,
    permission_item_event_template_dialog: state => state.permission_item_event_template_dialog,
    event_template_errors: state => state.event_template_errors,
    event_template_error_messages: state => state.event_template_error_messages,
}
const actions = {

    getEventTemplates({commit},) {
        commit('setEventTemplatesLoading', true)
        axios.get('/api/admin/event_templates', ).then(response => {
            commit('setEventTemplates', response.data)
            commit('setEventTemplatesLoading', false)
        })
    },
    getAllEventTemplates({commit}, params) {
        commit('setEventTemplatesLoading', true)
        axios.get('/api/event_templates/all', {params}).then(response => {
            commit('setEventTemplates', response.data)
            commit('setEventTemplatesLoading', false)
        })
    },
    setEventTemplatePermissions({commit}, params) {
        commit('setEventTemplatesLoading', true)
        axios.post('/api/event_templates/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemEventTemplateDialog', false)
            } else {
                commit('setEventTemplateErrors', [response.data.message])
            }
            commit('setEventTemplatesLoading', false)
        }).catch(error => {
            commit('setEventTemplatesLoading', false)
            commit('setEventTemplateErrorMessages', error.response.data.errors)
        })
    },
    getEventTemplate({commit}, id) {
        commit('setEventTemplatesLoading', true)
        axios.get('/api/admin/event_templates/' + id).then(response => {
            commit('setEventTemplate', response.data)
            commit('setEventTemplatesLoading', false)
        })
    },

    storeEventTemplate({commit, dispatch}, event_template) {
        commit('setEventTemplatesLoading', true)
        commit('setEventTemplateErrorMessages', {})
        commit('setEventTemplateErrors', null)

        axios.post('/api/admin/event_templates', event_template).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingEventTemplateDialog', false)
                // commit('setEventTemplate', {})
                dispatch('getEventTemplates')

            // } else {
            //     commit('setEventTemplateErrors', res.data.message)
            // }
            commit('setEventTemplatesLoading', false)
        }).catch(error => {
            commit('setEventTemplatesLoading', false)
            commit('setEventTemplateErrorMessages', error.response.data.errors)
            commit('setEventTemplateErrors', error.response.data.message)
        })
    },
    deleteEventTemplate({commit, dispatch}, id) {
        commit('setEventTemplatesLoading', true)
        commit('setEventTemplateErrors', null)
        commit('setEventTemplateErrorMessages', {})

        axios.delete('/api/admin/event_templates/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingEventTemplateDialog', false)
                dispatch('getEventTemplates')
            // } else {
                // commit('setEventTemplateErrors', [res.data.message])
            // }
            commit('setEventTemplatesLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setEventTemplatesLoading', false)
            commit('setEventTemplateErrorMessages', error.response.data.errors)
        })
    },
    editEventTemplate({commit, dispatch}, {id, event_template}) {
        commit('setEventTemplatesLoading', true)
        commit('setEventTemplateErrorMessages', {})
        commit('setEventTemplateErrors', null)
        axios.put('/api/admin/event_templates/' + id, event_template).then((res) => {
            // if (res.data.ok) {
                commit('setEventTemplatesLoading', false)
                dispatch('getEventTemplates')

            // } else {
            //     commit('setEventTemplateErrors', res.data.message)
            // }
            commit('setEditingEventTemplateDialog', false)
        }).catch(error => {
            commit('setEventTemplatesLoading', false)
            commit('setEventTemplateErrors', error.response.data.message)
            commit('setEventTemplateErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setEventTemplates(state, event_templates) {
        state.event_templates = event_templates
    },
    setEventTemplatesLoading(state, loading) {
        state.event_templates_loading = loading
    },
    setDeletingEventTemplateDialog(state, deleting_event_template_dialog) {
        state.deleting_event_template_dialog = deleting_event_template_dialog
    },
    setCreatingEventTemplateDialog(state, creating_event_template_dialog) {
        state.creating_event_template_dialog = creating_event_template_dialog
    },
    setEditingEventTemplateDialog(state, editing_event_template_dialog) {
        state.editing_event_template_dialog = editing_event_template_dialog
    },
    setEventTemplateErrors(state, event_template_errors) {
        state.event_template_errors = event_template_errors
    },
    setEventTemplate(state, event_template) {
        state.event_template = event_template
    },
    setEventTemplateErrorMessages(state, event_template_error_messages) {
        state.event_template_error_messages = event_template_error_messages
    },
    setPermissionItemEventTemplateDialog(state, permission_item_event_template_dialog) {
        state.permission_item_event_template_dialog = permission_item_event_template_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
