
const state = {
    locations: [],
    location: {
        names: {},
        descriptions: {},
    },
    locations_loading: false,
    deleting_location_dialog: false,
    creating_location_dialog: false,
    editing_location_dialog: false,
    location_permissions: [],
    location_errors: [],
    location_error_messages: {}
}
const getters = {
    locations: state => state.locations,
    location: state => state.location,
    locations_loading: state => state.locations_loading,
    deleting_location_dialog: state => state.deleting_location_dialog,
    creating_location_dialog: state => state.creating_location_dialog,
    editing_location_dialog: state => state.editing_location_dialog,
    permission_item_location_dialog: state => state.permission_item_location_dialog,
    location_errors: state => state.location_errors,
    location_error_messages: state => state.location_error_messages,
}
const actions = {

    getLocations({commit},) {
        commit('setLocationsLoading', true)
        axios.get('/api/admin/locations', ).then(response => {
            commit('setLocations', response.data)
            commit('setLocationsLoading', false)
        })
    },
    getAllLocations({commit}, params) {
        commit('setLocationsLoading', true)
        axios.get('/api/locations/all', {params}).then(response => {
            commit('setLocations', response.data)
            commit('setLocationsLoading', false)
        })
    },
    setLocationPermissions({commit}, params) {
        commit('setLocationsLoading', true)
        axios.post('/api/locations/' + params.id + "/permissions", params).then(response => {
            if (response.data.ok) {
                commit('setPermissionItemLocationDialog', false)
            } else {
                commit('setLocationErrors', [response.data.message])
            }
            commit('setLocationsLoading', false)
        }).catch(error => {
            commit('setLocationsLoading', false)
            commit('setLocationErrorMessages', error.response.data.errors)
        })
    },
    getLocation({commit}, id) {
        commit('setLocationsLoading', true)
        axios.get('/api/admin/locations/' + id).then(response => {
            commit('setLocation', response.data)
            commit('setLocationsLoading', false)
        })
    },

    storeLocation({commit, dispatch}, location) {
        commit('setLocationsLoading', true)
        commit('setLocationErrorMessages', {})
        commit('setLocationErrors', [])

        axios.post('/api/admin/locations', location).then((res) => {
            // if (res.data.ok) {
                commit('setCreatingLocationDialog', false)
                commit('setLocation', {})
                dispatch('getLocations')

            // } else {
            //     commit('setLocationErrors', res.data.message)
            // }
            commit('setLocationsLoading', false)
        }).catch(error => {
            commit('setLocationsLoading', false)
            commit('setLocationErrorMessages', error.response.data.errors)
        })
    },
    deleteLocation({commit, dispatch}, id) {
        commit('setLocationsLoading', true)
        commit('setLocationErrors', [])
        commit('setLocationErrorMessages', {})

        axios.delete('/api/admin/locations/' + id ).then((res) => {
            // if (res.data.ok) {
                commit('setDeletingLocationDialog', false)
                dispatch('getLocations')
            // } else {
                // commit('setLocationErrors', [res.data.message])
            // }
            commit('setLocationsLoading', false)
        }).catch(error => {
            console.log(error);
            commit('setLocationsLoading', false)
            commit('setLocationErrorMessages', error.response.data.errors)
        })
    },
    editLocation({commit, dispatch}, {id, location}) {
        commit('setLocationsLoading', true)
        commit('setLocationErrorMessages', {})
        commit('setLocationErrors', [])
        axios.put('/api/admin/locations/' + id, location).then((res) => {
            // if (res.data.ok) {
                commit('setLocationsLoading', false)
                dispatch('getLocations')

            // } else {
            //     commit('setLocationErrors', res.data.message)
            // }
            commit('setEditingLocationDialog', false)
        }).catch(error => {
            commit('setLocationsLoading', false)
            commit('setLocationErrorMessages', error.response.data.errors)
        })
    }

}

const mutations = {
    setLocations(state, locations) {
        state.locations = locations
    },
    setLocationsLoading(state, loading) {
        state.locations_loading = loading
    },
    setDeletingLocationDialog(state, deleting_location_dialog) {
        state.deleting_location_dialog = deleting_location_dialog
    },
    setCreatingLocationDialog(state, creating_location_dialog) {
        state.creating_location_dialog = creating_location_dialog
    },
    setEditingLocationDialog(state, editing_location_dialog) {
        state.editing_location_dialog = editing_location_dialog
    },
    setLocationErrors(state, location_errors) {
        state.location_errors = location_errors
    },
    setLocation(state, location) {
        state.location = location
    },
    setLocationErrorMessages(state, location_error_messages) {
        state.location_error_messages = location_error_messages
    },
    setPermissionItemLocationDialog(state, permission_item_location_dialog) {
        state.permission_item_location_dialog = permission_item_location_dialog
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
