<template>
  <v-app>
    <AppBarForUser></AppBarForUser>
    <!--        <SideBarForUser></SideBarForUser>-->
    <v-main>
      <v-container fluid class="pa-0">
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideBarForUser from "../components/navigation/SideBarForUser";
import AppBarForUser from "../components/navigation/AppBarForUser";
export default {
  name: "LayoutContentForUser",
  components: {
    SideBarForUser,
    AppBarForUser,
  },
};
</script>

<style>
.theme--light.v-data-table.table-custom {
  background-color: transparent !important;
  color: white !important;
}
</style>
