export default [
    {
        path: '/admin/recommended-aggregates-to-events',
        name: 'recommended_aggregates_to_events.index',
        component: () => import('../../pages/admin/RecommendedAggregatesToEvents/Index.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/recommended-aggregates-to-events/create',
        name: 'recommended_aggregates_to_events.create',
        component: () => import('../../pages/admin/RecommendedAggregatesToEvents/Create'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
    {
        path: '/admin/recommended-aggregates-to-events/:id/edit',
        name: 'recommended_aggregates_to_events.edit',
        component: () => import('../../pages/admin/RecommendedAggregatesToEvents/Edit.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            requiresAuth: true,
        },
    },
]
