export default [
  {
    path: "/admin/soil-type",
    name: "soil_type.index",
    component: () => import("../../pages/admin/SoilType/Index.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-type/create",
    name: "soil_type.create",
    component: () => import("../../pages/admin/SoilType/Create"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
  {
    path: "/admin/soil-type/:id/edit",
    name: "soil_type.edit",
    component: () => import("../../pages/admin/SoilType/Edit.vue"),
    meta: {
      layout: "content",
      resource: "Public",
      requiresAuth: true,
    },
  },
];
